import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import { useEffect } from "react";
import PaginationRounded from "../../components/Paginate";
import { AddAssignment, EditAssignment } from "../../components";
import DeleteMessage from "../../components/DeleteMessage/DeleteMessage";

const Assignments = () => {
  const [assignments, setAssignments] = useState([]);
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [showAssignment, setShowAssignment] = useState(false);
  const [changeData, setchangeData] = useState(false);
  const [query, setQuery] = useState("");
  const [filterAssignments, setFilterAssignments] = useState([]);

  const [openDateSave, setOpenDateSave] = useState("");
  const [deadlinSave, setDeadlinSave] = useState("");
  const [filteShow, setfilteShow] = useState(false);

  useEffect(() => {
    let filterLessones = assignments.filter((lesson) => {
      const titleMatches =
        !query || lesson.title.toLowerCase().includes(query.toLowerCase());
      // Check if lastUpdate is within the date range
      const lastUpdateOpenDate = new Date(lesson.openDate);
      const lastUpdateDeadlinDate = new Date(lesson.deadline);
      // Check if openDateSave and deadlinSave are valid dates
      const startDate = openDateSave ? new Date(openDateSave) : null;
      const endDate = deadlinSave ? new Date(deadlinSave) : null;
      // Check if lastUpdateDate is within the date range (if startDate and endDate are valid)
      const lastUpdateInRange =
        startDate && endDate
          ? lastUpdateOpenDate >= startDate && lastUpdateDeadlinDate <= endDate
          : true;
      setfilteShow(true);
      // Include the course in the filtered list if titleMatches and lastUpdateInRange
      return titleMatches && lastUpdateInRange;
    });

    setFilterAssignments(filterLessones);
  }, [query, openDateSave, deadlinSave]);

  useEffect(() => {
    const getAssignment = async () => {
      try {
        const res = await axios.get(`api/courses/${id}/assignments`);
        setAssignments(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAssignment();
  }, [changeData]);
  // useEffect(() => {
  //   let filterCourse = assignments.filter((assignment) =>
  //     assignment.title.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  //   );
  //   setFilterAssignments(filterCourse);
  // }, [query]);

  const totalElements = filteShow
    ? filterAssignments.length
    : assignments.length;
  const elementsPerPage = 5;
  const numberOfPages = Math.ceil(totalElements / elementsPerPage);
  const startIndex = (page - 1) * elementsPerPage;
  const endIndex = startIndex + elementsPerPage;
  const coursesForPage = filteShow
    ? filterAssignments.slice(startIndex, endIndex)
    : assignments.slice(startIndex, endIndex);

  return (
    <>
      {showAssignment && (
        <>
          <div className="overflow absolute top-0 left-0"></div>
          <AddAssignment
            setShowAssignment={setShowAssignment}
            setchangeData={setchangeData}
          />
        </>
      )}
      <div className=" flex flex-col justify-between lg:max-h-[500px] h-[85%]">
        <div className="h-full">
          <SearchBar
            title={"Add Assignment"}
            titleS={"Search by assignment name"}
            width={"w-[186px]"}
            onShow={setShowAssignment}
            onSearch={setQuery}
            openDateSave={openDateSave}
            setOpenDateSave={setOpenDateSave}
            deadlinSave={deadlinSave}
            setDeadlinSave={setDeadlinSave}
          />
          <h2 className="text-[20px] font-medium text-[#1F2937] mt-3">
            {totalElements} Assignments
          </h2>
          <div className="flex flex-col border-t justify-between h-[75%] lg:border-[#374151] border-[#1E69CB]  mt-2">
            {coursesForPage.length > 0 && (
              <>
                {coursesForPage.map((assignment) => (
                  <>
                    <CardLesson
                      key={assignment.id}
                      title={assignment.title}
                      openDate={assignment.openDate}
                      deadline={assignment.deadline}
                      setchangeData={setchangeData}
                      idAssignment={assignment.id}
                      description={assignment.description}
                    />
                  </>
                ))}
                <div>
                  {coursesForPage.map((assignment) => (
                    <>
                      <CardLessonPhone
                        key={assignment.id}
                        title={assignment.title}
                        openDate={assignment.openDate}
                        deadline={assignment.deadline}
                        setchangeData={setchangeData}
                        idAssignment={assignment.id}
                        description={assignment.description}
                      />
                    </>
                  ))}
                </div>

                <PaginationRounded
                  count={numberOfPages}
                  setPage={setPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Assignments;

const CardLesson = ({
  title,
  openDate,
  deadline,
  setchangeData,
  idAssignment,
  description,
}) => {
  const [details, setDetails] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [showEditAssignment, setShowEditAssignment] = useState(false);
  const HandleDelete = async (id) => {
    try {
      const res = await axios.delete(`api/assignments/${id}`);
      setDeleteMessage(false);
      setchangeData((e) => !e);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 absolute top-0 left-0  overview"></div>
          <DeleteMessage
            title={`Are you sure about to delete this Lesson (${title})`}
            bref={"If you delete this file you can’t receive this file again"}
            nameButton={"Delete"}
            onCancle={setDeleteMessage}
            onDelete={() => HandleDelete(idAssignment)}
          />
        </>
      )}
      {showEditAssignment && (
        <>
          <div className="w-full h-full z-10 fixed top-0 left-0 overview"></div>
          <EditAssignment
            setShowEditAssignment={setShowEditAssignment}
            Title={title}
            Deadline={deadline}
            OpenDate={openDate}
            Description={description}
            setchangeData={setchangeData}
            idAssignment={idAssignment}
          />
        </>
      )}
      <div
        className=" border-[#374151] lg:block hidden"
        key={idAssignment}>
        <div className="flex items-center justify-between border-b h-[65px] border-[#374151] card__lessons  cursor-pointer">
          <div className="flex items-center gap-2 h-full">
            <div className="h-[65px] w-[8px] bg-[#0455BA] mr-3 hidden  bg__hover"></div>
            <div>
              <label class="custom-checkbox flex items-center gap-3 text-[#2F2F2F]">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div>
              <h3 className="text-[22px] font-medium text-[#374151]">
                {title}
              </h3>
              <div className="flex items-center gap-2 text-[9px] font-semibold text-[#374151]">
                <p>Open Date : {openDate}</p>{" "}
                {deadline && <p>Deadline: {deadline}</p>}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <button
              id={`EditAssignment${idAssignment}`}
              className="w-[108px] h-[40px] rounded-[4px] border border-[#40A726] flex items-center justify-center gap-1 font-semibold text-lg text-[#40A726]"
              onClick={() => setShowEditAssignment(true)}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                  fill="#40A726"
                />

                <g mask="url(#mask0_1461_1482)"></g>
              </svg>
              Edit
            </button>

            <div
              className="icon__delete"
              onClick={() => setDeleteMessage(true)}>
              <button id={`deleteAssignment${idAssignment}`}>
                <svg
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.334 9.00016H29.7507C30.5298 9.00016 31.1673 9.63766 31.1673 10.4168C31.1673 11.196 30.5298 11.8335 29.7507 11.8335H28.334V27.4168C28.334 29.76 26.4272 31.6668 24.084 31.6668H9.91732C7.57415 31.6668 5.66732 29.76 5.66732 27.4168V11.8335H4.25065C3.47148 11.8335 2.83398 11.196 2.83398 10.4168C2.83398 9.63766 3.47148 9.00016 4.25065 9.00016H5.66732H11.334V6.6315C11.334 4.8125 12.9221 3.3335 14.8757 3.3335H19.1257C21.0792 3.3335 22.6673 4.8125 22.6673 6.6315V9.00016H28.334ZM14.8756 6.16677C14.4704 6.16677 14.1673 6.41186 14.1673 6.63144V9.00011H19.8339V6.63144C19.8339 6.41186 19.5308 6.16677 19.1256 6.16677H14.8756ZM21.2505 24.5834C22.0296 24.5834 22.6671 23.9459 22.6671 23.1668V17.5001C22.6671 16.7209 22.0296 16.0834 21.2505 16.0834C20.4713 16.0834 19.8338 16.7209 19.8338 17.5001V23.1668C19.8338 23.9459 20.4713 24.5834 21.2505 24.5834ZM14.1678 23.1668C14.1678 23.9459 13.5303 24.5834 12.7511 24.5834C11.9719 24.5834 11.3344 23.9459 11.3344 23.1668V17.5001C11.3344 16.7209 11.9719 16.0834 12.7511 16.0834C13.5303 16.0834 14.1678 16.7209 14.1678 17.5001V23.1668Z"
                    fill="#DD0303"
                  />

                  <g mask="url(#mask0_771_8630)"></g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const CardLessonPhone = ({
  title,
  openDate,
  deadline,
  setchangeData,
  idAssignment,
  description,
}) => {
  const [details, setDetails] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [showEditAssignment, setShowEditAssignment] = useState(false);
  const [navDetails, setNavDetails] = useState(false);

  const HandleDelete = async (id) => {
    try {
      const res = await axios.delete(`api/assignments/${id}`);
      setDeleteMessage(false);
      setchangeData((e) => !e);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 absolute top-0 left-0  overview"></div>
          <DeleteMessage
            title={`Are you sure about to delete this Lesson (${title})`}
            bref={"If you delete this file you can’t receive this file again"}
            nameButton={"Delete"}
            onCancle={setDeleteMessage}
            onDelete={() => HandleDelete(idAssignment)}
          />
        </>
      )}
      {showEditAssignment && (
        <>
          <div className="w-full h-full z-10 fixed top-0 left-0 overview"></div>
          <EditAssignment
            setShowEditAssignment={setShowEditAssignment}
            Title={title}
            Deadline={deadline}
            OpenDate={openDate}
            Description={description}
            setchangeData={setchangeData}
            idAssignment={idAssignment}
          />
        </>
      )}
      <div
        className=" border-[#374151]"
        key={idAssignment}>
        <div className="flex items-center justify-between lg:hidden bg-white  rounded-lg  px-3 mt-3 cursor-pointer relative">
          {/* <div className="flex items-center gap-2 h-full">
            <div className="h-[65px] w-[8px] bg-[#0455BA] mr-3 hidden  bg__hover"></div>

            <div>
              <h3 className="text-[22px] font-medium text-[#374151]">
                {title}
              </h3>
              <div className="flex items-center gap-2 text-[9px] font-semibold text-[#374151]">
                <p>Open Date : {openDate}</p>{" "}
                {deadline && <p>Deadline: {deadline}</p>}
              </div>
            </div>
          </div> */}
          <div className="flex items-center gap-2 justify-between h-[70px] ">
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 18H20M4 6H20H4ZM4 10H20H4ZM4 14H20H4Z"
                  stroke="#0455BA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <h3 className="text-base font-semibold text-[#0455BA]">
                {title}
              </h3>
              <div className="flex items-center gap-2 text-[9px] font-semibold text-[#374151]">
                <p>Open Date : {openDate}</p>{" "}
                {deadline && <p>Deadline: {deadline}</p>}
              </div>
            </div>
          </div>

          {/* <div className="flex items-center gap-4">
            <button
              className="w-[108px] h-[40px] rounded-[4px] border border-[#40A726] flex items-center justify-center gap-1 font-semibold text-lg text-[#40A726]"
              onClick={() => setShowEditAssignment(true)}
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                  fill="#40A726"
                />

                <g mask="url(#mask0_1461_1482)"></g>
              </svg>
              Edit
            </button>

            <div
              className="icon__delete"
              onClick={() => setDeleteMessage(true)}
            >
              <svg
                width="34"
                height="35"
                viewBox="0 0 34 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M28.334 9.00016H29.7507C30.5298 9.00016 31.1673 9.63766 31.1673 10.4168C31.1673 11.196 30.5298 11.8335 29.7507 11.8335H28.334V27.4168C28.334 29.76 26.4272 31.6668 24.084 31.6668H9.91732C7.57415 31.6668 5.66732 29.76 5.66732 27.4168V11.8335H4.25065C3.47148 11.8335 2.83398 11.196 2.83398 10.4168C2.83398 9.63766 3.47148 9.00016 4.25065 9.00016H5.66732H11.334V6.6315C11.334 4.8125 12.9221 3.3335 14.8757 3.3335H19.1257C21.0792 3.3335 22.6673 4.8125 22.6673 6.6315V9.00016H28.334ZM14.8756 6.16677C14.4704 6.16677 14.1673 6.41186 14.1673 6.63144V9.00011H19.8339V6.63144C19.8339 6.41186 19.5308 6.16677 19.1256 6.16677H14.8756ZM21.2505 24.5834C22.0296 24.5834 22.6671 23.9459 22.6671 23.1668V17.5001C22.6671 16.7209 22.0296 16.0834 21.2505 16.0834C20.4713 16.0834 19.8338 16.7209 19.8338 17.5001V23.1668C19.8338 23.9459 20.4713 24.5834 21.2505 24.5834ZM14.1678 23.1668C14.1678 23.9459 13.5303 24.5834 12.7511 24.5834C11.9719 24.5834 11.3344 23.9459 11.3344 23.1668V17.5001C11.3344 16.7209 11.9719 16.0834 12.7511 16.0834C13.5303 16.0834 14.1678 16.7209 14.1678 17.5001V23.1668Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_771_8630)"></g>
              </svg>
            </div>
          </div> */}
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setNavDetails((e) => !e)}>
            <circle
              cx="25"
              cy="17"
              r="2"
              fill="#0455BA"
            />
            <circle
              cx="25"
              cy="25"
              r="2"
              fill="#0455BA"
            />
            <circle
              cx="25"
              cy="33"
              r="2"
              fill="#0455BA"
            />
          </svg>

          {navDetails && (
            <div className="flex items-center justify-between gap-4 w-[159px] min-h-[70px] bg-[#E5E7EB] rounded-lg flex-col py-4 px-3 absolute top-14 right-5 z-10">
              <button
                className=" rounded-md flex items-center justify-between w-full  gap-2 text-[#1F2937] font-bold text-xs"
                onClick={() => {
                  setShowEditAssignment(true);
                  setNavDetails(false);
                }}>
                Edit
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                    fill="#40A726"
                  />

                  <g mask="url(#mask0_1461_1482)"></g>
                </svg>
              </button>

              <div
                className="rounded-md flex items-center justify-between w-full  gap-2 text-[#1F2937] font-bold text-xs"
                onClick={() => {
                  setDeleteMessage(true);
                  setNavDetails(false);
                }}>
                Delete
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.334 9.00016H29.7507C30.5298 9.00016 31.1673 9.63766 31.1673 10.4168C31.1673 11.196 30.5298 11.8335 29.7507 11.8335H28.334V27.4168C28.334 29.76 26.4272 31.6668 24.084 31.6668H9.91732C7.57415 31.6668 5.66732 29.76 5.66732 27.4168V11.8335H4.25065C3.47148 11.8335 2.83398 11.196 2.83398 10.4168C2.83398 9.63766 3.47148 9.00016 4.25065 9.00016H5.66732H11.334V6.6315C11.334 4.8125 12.9221 3.3335 14.8757 3.3335H19.1257C21.0792 3.3335 22.6673 4.8125 22.6673 6.6315V9.00016H28.334ZM14.8756 6.16677C14.4704 6.16677 14.1673 6.41186 14.1673 6.63144V9.00011H19.8339V6.63144C19.8339 6.41186 19.5308 6.16677 19.1256 6.16677H14.8756ZM21.2505 24.5834C22.0296 24.5834 22.6671 23.9459 22.6671 23.1668V17.5001C22.6671 16.7209 22.0296 16.0834 21.2505 16.0834C20.4713 16.0834 19.8338 16.7209 19.8338 17.5001V23.1668C19.8338 23.9459 20.4713 24.5834 21.2505 24.5834ZM14.1678 23.1668C14.1678 23.9459 13.5303 24.5834 12.7511 24.5834C11.9719 24.5834 11.3344 23.9459 11.3344 23.1668V17.5001C11.3344 16.7209 11.9719 16.0834 12.7511 16.0834C13.5303 16.0834 14.1678 16.7209 14.1678 17.5001V23.1668Z"
                    fill="#DD0303"
                  />

                  <g mask="url(#mask0_771_8630)"></g>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
