import React from "react";

const Statistic = ({
  title,
  icon,
  num,
  width,
  height,
  lastIcon,
  fontSize,
  fontWight,
  fontColor,
  phone,
}) => {
  return (
    <div
      className={`relative flex-1 lg:${width} ${height} bg-white rounded-xl flex items-center gap-4 2xl:gap-6  justify-center px-1 md:px-[50px] xl:px[70px] 2xl:px-[10px] shadow-md  ${
        phone && "py-[15px]"
      }`}>
      {icon}
      <div className={`text-[#374151]  font-bold ${fontSize} ${fontWight}`}>
        <p className={` ${fontColor ? fontColor : "text-[#374151]"}`}>
          {title}
        </p>
        <p>{num}</p>
      </div>
      <div className="absolute  right-0">{lastIcon}</div>
    </div>
  );
};

export default Statistic;
