import SpinnerMini from "../SpinnerMini";

const Button = ({ setShowAddCourse, handleSubmit, id, isLoading }) => {
  return (
    <div className="lg:mt-3 mt-8 flex items-center gap-5 flex-col lg:flex-row text-[24px] lg:text-base">
      <button
        disabled={isLoading}
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] bg-[#0455BA] font-semibold text-white flex justify-center items-center hover:filter hover:brightness-90 duration-300"
        onClick={handleSubmit}
        id={id + "Save"}>
        {isLoading ? <SpinnerMini /> : "Save"}
      </button>
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151] hover:filter hover:brightness-80 duration-300"
        onClick={() => setShowAddCourse(false)}
        id={id + "Cancel"}>
        Cancel
      </button>
    </div>
  );
};
export default Button;
