const SvgEnglish = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H24V24H0V0Z" fill="#012169" />
      <path
        d="M2.8125 0L11.9625 9.05L21.075 0H24V3.1L15 12.05L24 20.95V24H21L12 15.05L3.0375 24H0V21L8.9625 12.1L0 3.2V0H2.8125Z"
        fill="white"
      />
      <path
        d="M15.9 14.05L24 22V24L13.8375 14.05H15.9ZM9 15.05L9.225 16.8L2.025 24H0L9 15.05ZM24 0V0.15L14.6625 9.55L14.7375 7.35L22.125 0H24ZM0 0L8.9625 8.8H6.7125L0 2.1V0Z"
        fill="#C8102E"
      />
      <path d="M9.0375 0V24H15.0375V0H9.0375ZM0 8V16H24V8H0Z" fill="white" />
      <path
        d="M0 9.65V14.45H24V9.65H0ZM10.2375 0V24H13.8375V0H10.2375Z"
        fill="#C8102E"
      />
    </svg>
  );
};
export default SvgEnglish;
