import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { PaginationItem } from "@mui/material";

export default function PaginationRounded({ count, setPage }) {
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Stack
      spacing={2}
      className="md:flex hidden items-center justify-center mt-3 "
    >
      <Pagination
        count={count}
        shape="rounded"
        onChange={handleChange}
        renderItem={(item) => <PaginationItem {...item} />}
      />
    </Stack>
  );
}
