import { useEffect, useState } from "react";
import axios from "../api/axios";
import { usePassValidation } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";

function Verify() {
  return (
    <div className="w-full h-screen relative">
      <div className="w-1/2 bg-[#0455BA] h-full flex items-center flex-col justify-between px-[120px] pt-[200px] pb-6"></div>
      <div className="w-1/2 px-[120px] flex items-center flex-col justify-center"></div>
      <RestPass />
    </div>
  );
}

export default Verify;
const RestPass = ({ onCancel }) => {
  const { token } = useParams();
  const naviget = useNavigate();
  const [done, setDone] = useState(true);
  const [loading, setLodaing] = useState(false);
  const [expires, setExpires] = useState(true);
  useEffect(() => {
    const HandleResetPass = async () => {
      setLodaing(false);
      try {
        const res = await axios.post("/auth/verify", { token });
        setDone(true);
      } catch (err) {
        console.log(err);
        setDone(false);
      }
    };

    HandleResetPass();
    setLodaing(true);
  }, []);

  const Naviget = () => {
    naviget("/login");
  };
  return (
    <>
      {loading && (
        <Done
          onCancel={Naviget}
          isDone={done}
        />
      )}
    </>
  );
};

const Done = ({ onCancel }) => {
  return (
    <div className="absolute w-[1014px] h-[564px] border-2 left-1/2 z-10 reset__password rounded-xl shadow-xl">
      <div className=" border-[30px] border-[#0455BA] w-full h-full rounded-xl  bg-[#0455BA]">
        <div className="w-full h-full rounded-xl   bg-white px-10 py-12 flex flex-col justify-center">
          <div className="flex items-center justify-between flex-col gap-6  pt-8">
            <h2 className="text-[48px] font-bold text-[#7DBE6D] text-center">
              Your Account has been verified successfully!
            </h2>
            <p className="text-xl text-[#6B7280] w-[530px] text-center">
              {/* Your Account has been verified successfully */}
            </p>
            <div className="flex items-center gap-5">
              <button
                className="w-[539px] h-[61px] bg-[#0455BA] rounded-[10px] text-[22px] font-semibold text-white"
                onClick={onCancel}>
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Expired = ({ onCancel }) => {
  return (
    <div className="absolute w-[1014px] h-[564px] border-2 left-1/2 z-10 reset__password rounded-xl shadow-xl">
      <div className=" border-[30px] border-[#0455BA] w-full h-full rounded-xl  bg-[#0455BA]">
        <div className="w-full h-full rounded-xl   bg-white px-10 py-12 flex flex-col justify-center">
          <div className="flex items-center justify-between flex-col gap-6  pt-8">
            <h2 className="text-[40px] font-bold text-[#DD0303] text-center">
              Session has expired! Please, try again.
            </h2>
            <p className="text-xl text-[#6B7280] w-[530px] text-center">
              {/* You can now use your new password to log in to your account */}
            </p>
            <div className="flex items-center gap-5">
              <button
                className="w-[539px] h-[61px] bg-[#0455BA] rounded-[10px] text-[22px] font-semibold text-white"
                onClick={onCancel}>
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
