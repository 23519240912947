import React, { useState } from "react";
import { IoStar } from "react-icons/io5";
import image from "../assets/images.jpg";
import { TbWorld } from "react-icons/tb";
import { Link } from "react-router-dom";
import DeleteMessage from "./DeleteMessage/DeleteMessage";
import { useStateContext } from "../context/CreateContext";
import axios from "../api/axios";
import { courseUrl } from "../utils";

const CourseCardSearch = ({ course, setChangeData }) => {
  const [state, setstate] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);

  const HandleDelete = async (id) => {
    try {
      const res = await axios.delete(`api/courses/${id}`);
      setDeleteMessage(false);
      setChangeData((e) => !e);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 absolute top-0 left-0  overview"></div>
          <DeleteMessage
            title={`Are you sure about to delete this Course (${course.title})`}
            bref={"If you delete this file you can’t receive this file again"}
            nameButton={"Delete"}
            onCancle={setDeleteMessage}
            onDelete={() => HandleDelete(course.id)}
          />
        </>
      )}
      <div className="w-full max-h-[390px] flex rounded-md  relative cursor-pointer test bg-white  gap-3">
        <div className="  overflow-hidden rounded-md bg-white w-[60%] p-2">
          <img
            src={`${courseUrl}${course.image}`}
            alt=""
            className="w-full h-full overflow-hidden rounded-md"
          />
        </div>

        <div className="w-full">
          <div className="mx-auto flex-col flex justify-between h-[130px] py-1 w-full mt-3 px-2">
            <Text
              title={course.title}
              bref={course.subtitle}
            />
            <Rate
              rate={course.rateNo}
              price={course.price}
            />
          </div>
          <Link to={`${course.id}`}>
            <div
              className={`bg-[#1E69CB] rounded-br-[8px] rounded-[8px] flex items-center justify-center h-[34px] w-full px-4 gap-2 mt-3`}>
              <h3 className="text-[#F5F5F5] text-xs font-semibold cursor-pointer text-center">
                See More Info
              </h3>
            </div>
          </Link>
        </div>

        <div className="w-[15px] h-[15px] bg-white rounded-full flex items-center justify-center absolute top-4 left-[30%] shadow-md">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setstate((e) => !e)}>
            <g filter="url(#filter0_d_2863_11207)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.1046 8.798L23.8916 11.319C24.2706 12.537 23.6136 13.851 22.4266 14.248L22.2256 14.315C21.6486 14.509 21.1966 14.989 21.0156 15.601C20.8496 16.162 20.9456 16.779 21.2736 17.253L21.5346 17.628C22.2486 18.66 22.0206 20.108 21.0266 20.857L18.9656 22.413C18.4706 22.787 17.8636 22.938 17.2546 22.841C16.6406 22.742 16.1046 22.402 15.7456 21.884L15.6276 21.712C15.2776 21.208 14.7176 20.902 14.1306 20.935C13.5416 20.951 13.0346 21.23 12.7026 21.702L12.4716 22.03C12.1096 22.543 11.5726 22.878 10.9606 22.974C10.8446 22.992 10.7286 23 10.6136 23C10.1266 23 9.65563 22.842 9.25763 22.54L7.18563 20.966C6.19563 20.216 5.97663 18.773 6.69663 17.75C7.07063 17.22 7.14663 16.539 6.92763 15.893L6.87263 15.725C6.68863 15.143 6.27163 14.691 5.75363 14.514L5.58963 14.458C4.37263 14.041 3.72263 12.749 4.10963 11.517L4.92563 8.922C5.11063 8.335 5.50963 7.861 6.04963 7.588C6.57763 7.322 7.17463 7.281 7.73263 7.472C8.33163 7.676 8.99663 7.565 9.50963 7.175L9.63863 7.077C10.0946 6.729 10.3696 6.165 10.3716 5.567L10.3726 5.326C10.3776 4.042 11.4016 3 12.6556 3H12.6596L15.2066 3.003C15.8086 3.004 16.3756 3.242 16.8046 3.673C17.2476 4.118 17.4896 4.713 17.4876 5.348L17.4856 5.627C17.4836 6.193 17.7426 6.728 18.1796 7.056L18.2866 7.137C18.7456 7.482 19.3436 7.582 19.8796 7.401L20.2196 7.288C20.7956 7.096 21.4106 7.143 21.9516 7.42C22.5066 7.704 22.9166 8.193 23.1046 8.798ZM13.9996 16.5C12.0696 16.5 10.4996 14.93 10.4996 13C10.4996 11.07 12.0696 9.5 13.9996 9.5C15.9296 9.5 17.4996 11.07 17.4996 13C17.4996 14.93 15.9296 16.5 13.9996 16.5ZM13.9996 11.5C13.1726 11.5 12.4996 12.173 12.4996 13C12.4996 13.827 13.1726 14.5 13.9996 14.5C14.8266 14.5 15.4996 13.827 15.4996 13C15.4996 12.173 14.8266 11.5 13.9996 11.5Z"
                fill="#1E69CB"
              />

              <g mask="url(#mask0_2863_11207)"></g>
            </g>
          </svg>
          {state && (
            <div className="w-[160px] h-[90px] absolute top-10 bg-[#F3F4F6] shadow-md rounded-lg right-0 px-2 py-3 flex flex-col justify-between">
              <Link to={`${course.id}`}>
                <div className="text-xs text-[#1F2937] font-bold flex items-center justify-between hover:bg-white px-1 py-1 rounded-lg duration-100">
                  Edit
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setstate((e) => false)}>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.16804 14.9998C4.19304 14.9998 4.21804 14.999 4.24304 14.9965L7.71804 14.6815C8.09971 14.6465 8.45888 14.4781 8.72971 14.2073L16.2272 6.70981C16.8339 6.10148 16.808 5.08648 16.1705 4.44814L16.1697 4.44731L13.888 2.16564C13.2705 1.54981 12.2097 1.52064 11.6247 2.10814L4.12804 9.60481C3.85638 9.87564 3.68804 10.2356 3.65304 10.6165L3.33721 14.0915C3.31554 14.3373 3.40388 14.5806 3.57804 14.7556C3.73554 14.9131 3.94804 14.9998 4.16804 14.9998ZM12.7272 3.36231L14.9722 5.60731L13.3497 7.23231L11.1039 4.98648L12.7272 3.36231ZM4.16763 16.6667H15.8343C16.2926 16.6667 16.6676 17.0417 16.6676 17.5001C16.6676 17.9584 16.2926 18.3334 15.8343 18.3334H4.16763C3.70929 18.3334 3.33429 17.9584 3.33429 17.5001C3.33429 17.0417 3.70929 16.6667 4.16763 16.6667Z"
                      fill="#40A726"
                    />

                    <g mask="url(#mask0_1303_57404)"></g>
                  </svg>
                </div>
              </Link>
              <div
                className="text-xs text-[#1F2937] font-bold flex items-center justify-between hover:bg-white px-1 py-1 rounded-lg"
                onClick={() => setDeleteMessage(true)}>
                Delete
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.666 5.00008H17.4993C17.9577 5.00008 18.3327 5.37508 18.3327 5.83342C18.3327 6.29175 17.9577 6.66675 17.4993 6.66675H16.666V15.8334C16.666 17.2117 15.5443 18.3334 14.166 18.3334H5.83268C4.45435 18.3334 3.33268 17.2117 3.33268 15.8334V6.66675H2.49935C2.04102 6.66675 1.66602 6.29175 1.66602 5.83342C1.66602 5.37508 2.04102 5.00008 2.49935 5.00008H3.33268H6.66602V3.60675C6.66602 2.53675 7.60018 1.66675 8.74935 1.66675H11.2493C12.3985 1.66675 13.3327 2.53675 13.3327 3.60675V5.00008H16.666ZM8.74935 3.33341C8.51102 3.33341 8.33268 3.47758 8.33268 3.60675V5.00008H11.666V3.60675C11.666 3.47758 11.4877 3.33341 11.2493 3.33341H8.74935ZM12.4993 14.1667C12.9577 14.1667 13.3327 13.7917 13.3327 13.3334V10.0001C13.3327 9.54175 12.9577 9.16675 12.4993 9.16675C12.041 9.16675 11.666 9.54175 11.666 10.0001V13.3334C11.666 13.7917 12.041 14.1667 12.4993 14.1667ZM8.33268 13.3334C8.33268 13.7917 7.95768 14.1667 7.49935 14.1667C7.04102 14.1667 6.66602 13.7917 6.66602 13.3334V10.0001C6.66602 9.54175 7.04102 9.16675 7.49935 9.16675C7.95768 9.16675 8.33268 9.54175 8.33268 10.0001V13.3334Z"
                    fill="#DD0303"
                  />

                  <g mask="url(#mask0_2863_17935)"></g>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseCardSearch;

const Text = ({ title, bref, totalEnrolls }) => {
  return (
    <div className="text-[#374151] text-2xl">
      <h3 className="font-semibold text-start max-h-[45px] overflow-hidden text-ellipsis break-all">
        {title}
      </h3>
      <p className="text-[10px] mt-2 font-semibold text-[#4B5563]">{bref}</p>
    </div>
  );
};

const Rate = ({ rate, price }) => {
  return (
    <div className="flex items-center justify-between ">
      <div className="text-[#F6C208] flex items-center gap-1">
        <IoStar className="w-[17px] h-[16px]" />
        <p>{rate}</p>
      </div>
      <p className="text-lg text-[#40A726] font-medium">E${price}</p>
    </div>
  );
};

const Details = ({
  setstate,
  title,
  bref,
  lan,
  lastUpdate,
  totalEnrolls,
  id,
  setDeleteMessage,
}) => {
  return (
    <div
      className="absolute top-[-10%] left-[98%] z-10 bg-white w-[233px] h-[281px] shadow-md px-3 rounded-md py-1 details"
      onMouseEnter={() => setstate(true)}
      onMouseLeave={() => setstate(false)}>
      <div className="absolute top-[30%] left-[-5%] left ">
        <svg
          width="12"
          height="30"
          viewBox="0 0 12 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 30V0L0.999512 13.7506C0.415162 14.481 0.415164 15.519 0.999513 16.2494L12 30Z"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="absolute top-[30%] right right-[-5%] hidden">
        <svg
          width="12"
          height="30"
          viewBox="0 0 12 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 30V0L11.0005 13.7506C11.5848 14.481 11.5848 15.519 11.0005 16.2494L0 30Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="flex flex-col justify-between h-full py-2">
        <div className="text-[#374151]">
          <h3 className="font-semibold text-start">{title}</h3>
          <p className="text-[10px] mt-1">{bref}</p>
        </div>

        <div>
          <div className="flex items-center mt-1 gap-2">
            <div className="flex items-center text-[#F6C208]">
              <IoStar className="w-[17px] h-[16px]" />
              <IoStar className="w-[17px] h-[16px]" />
              <IoStar className="w-[17px] h-[16px]" />
              <IoStar className="w-[17px] h-[16px]" />
              <IoStar className="w-[17px] h-[16px]" />
            </div>
            <p className="text-[10px] text-[#374151] font-semibold">
              ( 490 rating )
            </p>
          </div>

          <div className="text-[10px] text-[#374151] font-semibold mt-2">
            {/* <p>1450, Students</p> */}
            <p>Income, {totalEnrolls}$</p>
          </div>
          <div className="flex items-center gap-1 mt-2">
            <TbWorld className="w-[10px] h-[10px]" />
            <p className="text-[10px] text-[#374151] font-semibold ">{lan}</p>
          </div>
          <p className="text-[10px] text-[#374151] font-semibold mt-2 ">
            {lastUpdate}
          </p>

          <div className="mt-1 flex items-center justify-between">
            <Link to={`${id}`}>
              <button className="w-[169px] h-[36px] rounded-md bg-[#1E69CB] flex items-center justify-center gap-1 text-[12px] font-semibold text-[#F5F5F5]">
                View Course
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.16667 6L11.1667 8M11.1667 8L9.16667 10M11.1667 8H5.83333M14.5 8C14.5 8.78793 14.3448 9.56815 14.0433 10.2961C13.7417 11.0241 13.2998 11.6855 12.7426 12.2426C12.1855 12.7998 11.5241 13.2417 10.7961 13.5433C10.0681 13.8448 9.28793 14 8.5 14C7.71207 14 6.93185 13.8448 6.2039 13.5433C5.47595 13.2417 4.81451 12.7998 4.25736 12.2426C3.70021 11.6855 3.25825 11.0241 2.95672 10.2961C2.65519 9.56815 2.5 8.78793 2.5 8C2.5 6.4087 3.13214 4.88258 4.25736 3.75736C5.38258 2.63214 6.9087 2 8.5 2C10.0913 2 11.6174 2.63214 12.7426 3.75736C13.8679 4.88258 14.5 6.4087 14.5 8Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Link>

            <button
              className="w-[34px] rounded-md h-[36px] bg-[#DD0303] flex items-center justify-center"
              onClick={() => setDeleteMessage(true)}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.666 3.49999H12.2493C12.5702 3.49999 12.8327 3.76249 12.8327 4.08332C12.8327 4.40416 12.5702 4.66666 12.2493 4.66666H11.666V11.0833C11.666 12.0482 10.8808 12.8333 9.91602 12.8333H4.08268C3.11785 12.8333 2.33268 12.0482 2.33268 11.0833V4.66666H1.74935C1.42852 4.66666 1.16602 4.40416 1.16602 4.08332C1.16602 3.76249 1.42852 3.49999 1.74935 3.49999H2.33268H4.66602V2.52466C4.66602 1.77566 5.31993 1.16666 6.12435 1.16666H7.87435C8.67877 1.16666 9.33268 1.77566 9.33268 2.52466V3.49999H11.666ZM6.12435 2.33332C5.95752 2.33332 5.83268 2.43424 5.83268 2.52466V3.49999H8.16602V2.52466C8.16602 2.43424 8.04118 2.33332 7.87435 2.33332H6.12435ZM8.74935 9.91666C9.07018 9.91666 9.33268 9.65416 9.33268 9.33332V6.99999C9.33268 6.67916 9.07018 6.41666 8.74935 6.41666C8.42852 6.41666 8.16602 6.67916 8.16602 6.99999V9.33332C8.16602 9.65416 8.42852 9.91666 8.74935 9.91666ZM5.83268 9.33332C5.83268 9.65416 5.57018 9.91666 5.24935 9.91666C4.92852 9.91666 4.66602 9.65416 4.66602 9.33332V6.99999C4.66602 6.67916 4.92852 6.41666 5.24935 6.41666C5.57018 6.41666 5.83268 6.67916 5.83268 6.99999V9.33332Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
