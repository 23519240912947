import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/CreateContext";
import axios from "../../api/axios";
import CouresInfo from "./CouresInfo";
import CouresReq from "./CouresReq";
import { useNavigate } from "react-router-dom";
import {
  useDescValidation,
  useListReqValidation,
  usePriceValidation,
  useSelectValidation,
  useTitleValidation,
} from "../../hooks";
import s3 from "../../utils/aws";

const AddCourse = () => {
  const [aboutCourse, setAboutCourse] = useState(true);
  const { setShowAddCourse, email } = useStateContext();
  const [uploadFile, setUploadFile] = useState(null);
  const [openDate, setSetFor] = useState("");
  const [imageValid, setImageValid] = useState(true);
  const [forValid, setForValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();
  const [subtitle, subtitleValid, setSubtitleValid, handleSubtitleChange] =
    useTitleValidation();
  const [
    requirement,
    requirementValid,
    setRequirementValid,
    handleRequireChange,
  ] = useListReqValidation();
  const [outCome, outComeValid, setOutComeValid, handleOutComeChange] =
    useListReqValidation();
  const [
    teacherMails,
    teacherMailsValid,
    setTeacherMailsValid,
    handleMailsChange,
  ] = useListReqValidation([email]);
  const [
    description,
    descriptionValid,
    setDescriptionValid,
    handleDescriptionChange,
  ] = useDescValidation();
  const [language, languageValid, setLanguageValid, handleLanguageChange] =
    useSelectValidation();
  const [category, categoryValid, setCategoryValid, handleCategoryChange] =
    useSelectValidation();
  const [
    paymentType,
    paymentTypeValid,
    setPaymentTypeValid,
    handlePaymentTypeChange,
  ] = useSelectValidation();
  const [error, setError] = useState(false);

  const [price, priceValid, setPriceValid, handlePriceChange] =
    usePriceValidation();

  const handleCourseInfo = () => {
    if (!title) {
      setTitleValid(false);
    }
    if (!subtitle) {
      setSubtitleValid(false);
    }
    if (!language) {
      setLanguageValid(false);
    }
    if (!category) {
      setCategoryValid(false);
    }
    if (!uploadFile) {
      setImageValid(false);
    }
    if (
      titleValid &&
      title &&
      subtitle &&
      language &&
      category &&
      uploadFile &&
      subtitleValid &&
      languageValid &&
      categoryValid &&
      imageValid
    ) {
      setAboutCourse(false);
    }
  };

  const handleSubmit = () => {
    // Check for errors
    if (requirement.length <= 0) {
      setRequirementValid(false);
    }
    if (outCome.length <= 0) {
      setOutComeValid(false);
    }

    if (!price) {
      setPriceValid(false);
    }
    if (!openDate) {
      setForValid(false);
    }
    if (!description) {
      setDescriptionValid(false);
    }

    // If all fields are valid, proceed with form submission logic
    if (
      requirementValid &&
      outComeValid &&
      forValid &&
      priceValid &&
      descriptionValid &&
      titleValid &&
      title &&
      subtitle &&
      language &&
      category &&
      uploadFile &&
      subtitleValid &&
      languageValid &&
      categoryValid &&
      imageValid
    ) {
      createCourse();
    }
  };

  useEffect(() => {
    if (openDate) {
      setForValid(true);
    }
  }, [openDate]);

  const createCourse = async () => {
    setIsLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("subtitle", subtitle);
    bodyFormData.append("language", language);
    bodyFormData.append("category", category);
    bodyFormData.append("requirements", requirement);
    bodyFormData.append("outcomes", outCome);
    teacherMails.length > 0 &&
      bodyFormData.append("teacherMails", teacherMails);
    bodyFormData.append("price", price);
    bodyFormData.append("openDate", openDate);
    bodyFormData.append("description", description);

    paymentType === "Subscription"
      ? bodyFormData.append("monthly", true)
      : bodyFormData.append("monthly", false);

    try {
      const res = await axios.post(`api/courses`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { id } = res.data;
      const imageName = res.data.image;
      await uploadToS3(imageName, uploadFile);
      setShowAddCourse(false);
      return navigate(`MyCourses/${id}`);
    } catch (err) {
      const {
        title,
        subtitle,
        language,
        category,
        requirements,
        outcomes,
        teacherMails,
        price,
        openDate,
        description,
        paymentType,
      } = err.response.data;
      setTitleValid(title ? false : true);
      setSubtitleValid(subtitle ? false : true);
      setLanguageValid(language ? false : true);
      setCategoryValid(category ? false : true);
      setRequirementValid(requirements ? false : true);
      setOutComeValid(outcomes ? false : true);
      setTeacherMailsValid(teacherMails ? false : true);
      setPriceValid(price ? false : true);
      setForValid(openDate ? false : true);
      setPaymentTypeValid(paymentType ? false : true);
      setDescriptionValid(description ? false : true);
    }
    setIsLoading(false);
  };

  const uploadToS3 = async (imageName, file) => {
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/images/courseImages`,
      Key: imageName,
      Body: file,
      ACL: "public-read",
    };
    try {
      return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            console.log("Error", err);
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      });
    } catch (err) {
      // Error handling for the S3 upload
      console.error("S3 Upload Error:", err);
      setError(true);
    }
  };
  return (
    <div className="add__course lg:w-[749px] w-full h-full lg:h-fit flex flex-col overflow-y-auto max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed ">
      <div className="lg:hidden items-center justify-between mb-10 flex">
        <h2 className="text-[28px] font-semibold text-[#1E69CB]">
          Add New Course
        </h2>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setShowAddCourse(false)}
          className="cursor-pointer">
          <path
            d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
            fill="#374151"
          />

          <g mask="url(#mask0_1240_20873)"></g>
        </svg>
      </div>

      <Header
        aboutCourse={aboutCourse}
        setAboutCourse={setAboutCourse}
      />
      {aboutCourse ? (
        <CouresInfo
          title={title}
          setTitle={handleTitleChange}
          subtitle={subtitle}
          setSubtitle={handleSubtitleChange}
          language={language}
          setLanguage={handleLanguageChange}
          category={category}
          setCategory={handleCategoryChange}
          setAboutCourse={setAboutCourse}
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          languageValid={languageValid}
          titleValid={titleValid}
          subtitleValid={subtitleValid}
          categoryValid={categoryValid}
          imageValid={imageValid}
          handleSubmit={handleCourseInfo}
          setImageValid={setImageValid}
        />
      ) : (
        <CouresReq
          requirement={requirement}
          setRequirement={handleRequireChange}
          outCome={outCome}
          setOutCome={handleOutComeChange}
          email={teacherMails}
          setEmail={handleMailsChange}
          price={price}
          setPrice={handlePriceChange}
          setFor={openDate}
          setSetFor={setSetFor}
          description={description}
          setDescription={handleDescriptionChange}
          createCourse={createCourse}
          handleSubmit={handleSubmit}
          requirementValid={requirementValid}
          outComeValid={outComeValid}
          emailValid={teacherMailsValid}
          priceValid={priceValid}
          forValid={forValid}
          descriptionValid={descriptionValid}
          setEmailValid={setTeacherMailsValid}
          isLoading={isLoading}
          paymentType={paymentType}
          setPaymentTypeValid={setPaymentTypeValid}
          paymentTypeValid={paymentTypeValid}
          setPaymentType={handlePaymentTypeChange}
        />
      )}
    </div>
  );
};

export default AddCourse;

const Header = ({ aboutCourse, setAboutCourse }) => {
  return (
    <div className="flex items-center gap-5 w-full justify-between">
      <div
        className=" w-full cursor-pointer"
        onClick={() => setAboutCourse(true)}>
        <p className="lg:text-[20px] font-semibold text-center text-[#374151] text-lg">
          Course Info
        </p>
        <div
          className={`h-[4px] w-full ${
            aboutCourse ? "bg-[#438EF2]" : "bg-[#D1D5DB]"
          }  rounded-[4px] mt-2`}></div>
      </div>
      <div
        className="w-full cursor-pointer"
        onClick={() => setAboutCourse(false)}>
        <p className="lg:text-[20px] font-semibold text-center text-[#374151] text-lg">
          Requirements & pricing
        </p>
        <div
          className={`h-[4px] w-full ${
            !aboutCourse ? "bg-[#0455BA]" : "bg-[#D1D5DB]"
          }  rounded-[4px] mt-2`}></div>
      </div>
    </div>
  );
};
