import React from "react";
import { imageUrl, sideBarList } from "../../utils";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { BiSolidLogOut } from "react-icons/bi";
import { useStateContext } from "../../context/CreateContext";
import { IoMdNotifications } from "react-icons/io";
import axios from "../../api/axios";
export const SideBar = () => {
  const { LogOut } = useStateContext();
  const navigate = useNavigate();
  const { img, user, idUser, navActive, setNavActive } = useStateContext();
  const logout = {
    title: "Log Out",
    icon: <BiSolidLogOut className="w-[25px] h-[28px]" />,
    to: "LogOut",
  };
  const notify = {
    title: "Notification",
    icon: <IoMdNotifications className="w-[25px] h-[28px]" />,
    to: "LogOut",
  };
  const test = () => {
    LogOut();
    navigate("/login", { replace: true });
  };
  const readNotiy = async () => {
    try {
      const res = await axios.get(`api/teachers/${idUser}/read-notify`);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div
      className={` flex-col ${
        navActive && "hidden"
      } duration-100 transition-all lg:inline-flex h-full min-h-screen 2xl:w-[15%] lg:w-[20%] w-[80%] items-center gap-[73px] lg:pt-[25px] pb-[30px] px-0 bg-wire-frame-gray100 lg:border-r-[2px] z-10 border-[#1f618d] bg-[#F3F4F6] fixed top-0`}>
      <p className="relative w-fit lg:mt-[-2.00px] text-[22px] font-semibold hidden lg:block">
        <span className="text-[#0455ba] ">E-Learning </span>
        <span className="text-gray-700 ">System</span>
      </p>

      <div className="flex flex-col w-full h-[438px] items-center lg:pt-[27px] pb-[20px] px-0 relative">
        <div className="w-[100%] h-[147px] bg-[#1A2483] flex items-center  justify-between text-2xl text-white px-5 lg:hidden">
          <div className="flex items-center gap-3">
            <Link
              to={"MyProfile"}
              id="MyProfile">
              <div className="inline-flex items-center justify-center gap-[10px] relative flex-[0_0_auto]">
                <img
                  src={imageUrl + img}
                  alt=""
                  className="w-[60px] h-[60px] rounded-full"
                />
              </div>
            </Link>
            <div>
              <p>{user}</p>
              <p>#{idUser}</p>
            </div>
          </div>
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={() => setNavActive(true)}>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.83398 22.9999C3.83398 12.4314 12.4322 3.83325 23.0007 3.83325C33.5692 3.83325 42.1673 12.4314 42.1673 22.9999C42.1673 33.5684 33.5692 42.1666 23.0007 42.1666C12.4322 42.1666 3.83398 33.5684 3.83398 22.9999ZM23.0007 38.3333C14.5462 38.3333 7.66732 31.4543 7.66732 22.9999C7.66732 14.5455 14.5462 7.66659 23.0007 7.66659C31.4551 7.66659 38.334 14.5455 38.334 22.9999C38.334 31.4543 31.4551 38.3333 23.0007 38.3333ZM30.6673 21.0833H19.8113L22.2071 18.5724C22.9374 17.8077 22.9086 16.5944 22.1439 15.8623C21.3772 15.1339 20.1659 15.1608 19.4337 15.9274L13.9463 21.6774C13.9017 21.7244 13.8722 21.7785 13.8424 21.8333C13.8235 21.8679 13.8045 21.9028 13.7815 21.9362C13.7635 21.962 13.7451 21.9873 13.7267 22.0125L13.7267 22.0126C13.6645 22.0979 13.6029 22.1825 13.563 22.2831C13.4921 22.4575 13.4691 22.6396 13.4537 22.8255C13.4512 22.8521 13.4436 22.8775 13.4361 22.9029C13.4267 22.9344 13.4173 22.966 13.4173 22.9999C13.4173 23.0018 13.4178 23.0033 13.4183 23.0047C13.4188 23.0061 13.4192 23.0076 13.4192 23.0095C13.4212 23.2587 13.4691 23.5059 13.5668 23.7398C13.6627 23.9717 13.8045 24.1806 13.9789 24.3569L19.7289 30.105C20.1027 30.4788 20.5933 30.6666 21.084 30.6666C21.5746 30.6666 22.0653 30.4788 22.4391 30.105C23.1885 29.3556 23.1885 28.1443 22.4391 27.3948L19.9608 24.9166H30.6673C31.7253 24.9166 32.584 24.0579 32.584 22.9999C32.584 21.9419 31.7253 21.0833 30.6673 21.0833Z"
              fill="white"
            />

            <g mask="url(#mask0_1355_20826)"></g>
          </svg>
        </div>
        {sideBarList.length > 0 &&
          sideBarList.map((item) => (
            <NavList
              key={item.title}
              title={item.title}
              icon={item.icon}
              to={item.to}
              setNavActive={setNavActive}
            />
          ))}

        <div
          className={
            "flex items-center gap-2 text-[#1F618D] w-full h-[70px] hover:bg-[#abcadf8c] hover:pl-2 transition-all cursor-pointer"
          }
          onClick={test}
          id={logout.title}>
          <div className="flex items-center gap-4 px-5 ">
            <div>{logout.icon}</div>
            <p className="font-semibold">{logout.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBar;

const NavList = ({ title, icon, to, setNavActive }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? "flex items-center gap-2 bg-[#1F618D]  text-white w-full h-[70px]"
          : "flex items-center gap-2 text-[#1F618D] w-full h-[70px] hover:bg-[#abcadf8c] hover:pl-2 transition-all"
      }
      to={to}
      onClick={() => setNavActive(true)}
      id={title}>
      <div className="flex items-center gap-4 px-5 ">
        <div>{icon}</div>

        <p className="font-semibold">{title}</p>
      </div>
    </NavLink>
  );
};
