import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Input from "./Input";
import Select from "./Select";
import { countryList, listLanguage } from "../../utils";
import axios from "../../api/axios";
import PhoneInput from "react-phone-input-2";
import { useStateContext } from "../../context/CreateContext";

const About = ({
  About,
  country,
  language,
  setLanguage,
  languageValid,
  setLanguageValid,
  PhoneNumber,
  about,
  setAbout,
  aboutValid,
  setAboutValid,
  setCountry,
  countryValid,
  setCountryValid,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(PhoneNumber);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [edit, setEdit] = useState(true);
  const { idUser } = useStateContext();

  const handleSubmit = () => {
    // Reset validation
    // Check for errors

    if (!about) {
      setAboutValid(false);
    }

    if (!country) {
      setCountryValid(false);
    }
    if (!language) {
      setLanguageValid(false);
    }
    if (!phoneNumber) {
      setPhoneNumberValid(false);
    }
    if (
      about !== "" &&
      about &&
      country &&
      language &&
      phoneNumber &&
      aboutValid &&
      countryValid &&
      languageValid
    ) {
      // Your form submission logic here
      updateUserAbout();
    }
  };

  const updateUserAbout = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("about", about);
    bodyFormData.append("country", country);
    bodyFormData.append("language", language);
    bodyFormData.append("phoneNo", phoneNumber);

    try {
      const res = await axios.put(
        `/api/teachers/${idUser}/about`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEdit(true);
    } catch (err) {
      const {} = err.response.data;

      console.log(err);
    }
  };

  return (
    <div className="lg:px-4 lg:py-4">
      <div className="w-full mt-3 bg-[#E5E7EB] lg:px-0 lg:py-0 py-4 px-4  rounded-[10px] lg:bg-[#F5F5F5]">
        <Input
          title={"About"}
          height={"h-[max-content] py-4 "}
          value={about}
          onChange={setAbout}
          valid={aboutValid}
          setValid={setAboutValid}
          edit={edit}
        />
        <SelectCountrey
          title={"Country"}
          listSelect={countryList}
          value={country}
          onChange={setCountry}
          valid={countryValid}
          edit={edit}
        />
        <Select
          title={"Langauge"}
          listSelect={listLanguage}
          value={language}
          onChange={setLanguage}
          valid={languageValid}
          edit={edit}
        />

        <div className={`mt-5 ${edit ? "about__user__edit" : "about__user"}`}>
          <p className="font-semibold text-[#374151] mb-1">Phone Number</p>
          <PhoneInput
            country={"eg"}
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            disabled={edit ? true : false}
          />
          {!phoneNumberValid && !edit && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid Phone Number`}</p>
            </div>
          )}
        </div>
      </div>
      {!edit ? (
        <div className="flex items-center gap-4">
          <button
            id="AboutSave"
            className="w-1/2 h-[42px] bg-[#0455BA] rounded-[4px] text-white text-lg mt-6"
            onClick={handleSubmit}>
            save
          </button>
          <button
            id="AboutCancel"
            className="w-1/2 h-[42px] border text-black rounded-[4px] border-[#9CA3AF] text-lg mt-6"
            onClick={() => setEdit(true)}>
            cancel
          </button>
        </div>
      ) : (
        <button
          id="AboutUpdate"
          className="lg:w-[141px] w-full lg:h-[41px] h-[50px] bg-[#0455BA] rounded-[4px] text-white text-2xl lg:text-lg mt-6"
          onClick={() => setEdit(false)}>
          Update
        </button>
      )}
    </div>
  );
};

export default About;

const SelectCountrey = ({
  title,
  value,
  valid,
  onChange,
  listSelect,
  edit,
}) => {
  const [showSelect, setShowSelect] = useState(false);

  return (
    <div className="mt-5 relative">
      <p className="font-semibold text-[#374151]">{title}</p>
      <div
        className={`flex items-center justify-between h-[50px] border border-[#9CA3AF] rounded-[4px] px-2 ${
          edit ? "lg:bg-[#f5f5f5] bg-[#E5E7EB]" : "bg-white"
        } mt-1 relative ${!edit && "cursor-pointer"} `}
        onClick={() => !edit && setShowSelect((e) => !e)}>
        {value ? (
          <p
            className={`text-sm ${
              edit ? "text-[#6B7280]" : "text-[#374151]"
            }  font-semibold`}>
            {value}
          </p>
        ) : (
          <p className="text-[#6B7280]">Choose your {title} </p>
        )}

        {!edit && (
          <>
            {showSelect ? (
              <IoIosArrowUp
                className="w-[20px] h-[20px] cursor-pointer"
                onClick={() => setShowSelect(true)}
              />
            ) : (
              <IoIosArrowDown
                className="w-[20px] h-[20px] cursor-pointer"
                onClick={() => setShowSelect(false)}
              />
            )}
            {showSelect && (
              <div className="flex items-start justify-between flex-col max-h-[150px] overflow-y-auto w-full border border-[#9CA3AF]  bg-white mt-1 absolute  z-20 left-0 top-[93%]">
                {listSelect.map((value) => (
                  <div
                    className="flex items-center  gap-2 hover:bg-blue-300 w-full py-3 px-3 duration-100 transition-all cursor-pointer"
                    onClick={() => {
                      onChange(value);
                      setShowSelect(true);
                    }}>
                    <p>{value}</p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {!valid && !edit && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
        </div>
      )}
    </div>
  );
};
