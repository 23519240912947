import React, { useState } from "react";
import {
  useEmailValidation,
  useListReqValidation,
  useNameInput,
} from "../hooks";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../context/CreateContext";
import { emailRegex } from "../utils/regEx";
import axios from "../api/axios";

const Login = () => {
  const navigate = useNavigate();
  const [restPassword, setRestPassword] = useState(false);
  const { Login, role } = useStateContext();

  const [email, validEmail, setvalidEmail, setEmail] = useEmailValidation();
  const [password, setPassword] = useState("");
  const [valid, setvalid] = useState(true);
  const [validMessage, setvalidMessage] = useState("");
  const [validPassowrd, setValidPassowrd] = useState(true);
  const [remember, setRemember] = useState(false);
  const HandleLogin = async () => {
    if (email && password) {
      try {
        const req = await Login({ email, password }, remember);
        const [successfully, role] = req ? req : null;
        if (successfully && role[0] === "ROLE_TEACHER") {
          navigate("/", { replace: true });
        }
        if (role[0] === "ROLE_NOT_VERIFIED_TEACHER") {
          setvalidEmail(false);
          setValidPassowrd(false);
          setvalid(false);
          setvalidMessage("Please verify your email and try again");
        }
        if (
          role[0] === "ROLE_NOT_VERIFIED_STUDENT" ||
          role[0] === "ROLE_STUDENT"
        ) {
          setvalidEmail(false);
          setValidPassowrd(false);
          setvalid(false);
          setvalidMessage(
            "Please enter a valid teacher account email and try again"
          );
        }
      } catch (err) {
        setvalidEmail(false);
        setValidPassowrd(false);
        setvalid(false);
        setvalidMessage("Falid Pass or email");
      }
    }

    if (!email && !password) {
      setvalidMessage("Please Enter Your Email and Password");
      setvalidEmail(false);
      setValidPassowrd(false);
      setvalid(false);
    }

    if (email) {
      if (!emailRegex.test(email)) {
        setvalidMessage("Please Enter a valid email address");
        setvalidEmail(false);
        setValidPassowrd(true);
        setvalid(false);
      }
    }

    if (!email && password) {
      setvalidMessage("Please Enter a valid email address");
      setvalidEmail(false);
      setValidPassowrd(true);
      setvalid(false);
    }
    if (email && !password) {
      setvalidMessage("Please Enter a password");
      setvalidEmail(true);
      setValidPassowrd(false);
      setvalid(false);
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePass = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await HandleLogin();
    }
  };

  return (
    <div className="w-full h-screen flex items-center">
      {restPassword && <RestPassword onCancel={() => setRestPassword(false)} />}

      <div className="lg:w-1/2 w-full  lg:bg-[#0455BA] h-full flex items-center flex-col justify-center lg:px-[120px] px-[50px]  pb-6">
        {!restPassword && (
          <>
            <div className="w-full flex flex-col items-center justify-center gap-5">
              <h1 className="text-[96px] font-semibold w-fulltext-center text-white mb-10 hidden lg:block">
                Log In
              </h1>
              <div className="text-center lg:hidden">
                <h2 className="text-[36px] lg:text-[50px]  font-bold lg:text-white text-[#374151] ">
                  Welcome To
                </h2>
                <h1 className="text-[36px] lg:text-[50px]  font-semibold lg:text-white text-[#0455BA]">
                  E- Learning <span className="text-[#1F2937]">System</span>
                </h1>
              </div>

              {!valid && (
                <div className="w-full h-[55px] rounded-lg bg-[#F8D7DA] flex items-center px-3 justify-between">
                  <p className="text-[#973851] text-lg  font-semibold">
                    {validMessage}
                  </p>

                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setvalid(true)}
                    className="cursor-pointer">
                    <path
                      d="M8 23.75L6.25 22L13.25 15L6.25 8L8 6.25L15 13.25L22 6.25L23.75 8L16.75 15L23.75 22L22 23.75L15 16.75L8 23.75Z"
                      fill="#973851"
                    />
                  </svg>
                </div>
              )}
              <div className="bg-[#E5E7EB] lg:bg-[#0455BA] px-4 pt-4 pb-6 lg:px-0 lg:py-0 rounded-[10px] w-full">
                <InputLogin
                  title={"E-mail"}
                  placeholder={"Enter Your E-mail"}
                  onChange={handleEmail}
                  value={email}
                  valid={validEmail}
                  type={"text"}
                  onKeyDown={handleKeyPress}
                />

                <InputLogin
                  title={"password"}
                  placeholder={"Enter Your Password"}
                  value={password}
                  onChange={handlePass}
                  valid={validPassowrd}
                  type={"password"}
                  onKeyDown={handleKeyPress}
                />
                <ForgetPass
                  setRestPassword={setRestPassword}
                  setRemember={setRemember}
                />
              </div>
              <p className="text-sm font-semibold block lg:hidden">
                Don’t have account ?{" "}
                <Link
                  to={"/signUp"}
                  className="w-full">
                  <span className="text-[#0455BA]">Create Account</span>
                </Link>
              </p>
              <LoginButton
                onLogin={HandleLogin}
                handleKeyPress={handleKeyPress}
              />
            </div>
            {/* <div className="flex items-center justify-between w-full">
              <button className="h-[35px] w-[115px] rounded-md border border-white text-white text-[12px] font-semibold">
                About Us
              </button>
              <button className="h-[35px] w-[115px] rounded-md border border-white text-white text-[12px] font-semibold">
                Terms of Service
              </button>
            </div> */}
          </>
        )}
      </div>
      <div className="w-1/2 hidden  px-[120px] lg:flex items-center flex-col justify-center">
        {!restPassword && (
          <>
            <h2 className="text-[48px]   font-bold text-[#374151]">
              Welcome To
            </h2>
            <h1 className="text-[50px] font-semibold text-[#0455BA]">
              E- Learning <span className="text-[#1F2937]">System</span>
            </h1>
            <SingUpButton />
          </>
        )}
      </div>
    </div>
  );
};

export default Login;

const InputLogin = ({
  title,
  placeholder,
  value,
  onChange,
  valid,
  type,
  onKeyDown,
}) => {
  const [showPass, setshowPass] = useState(true);
  return (
    <div className="w-full mt-3">
      <p className={`lg:text-xl font-semibold lg:text-white mb-1 `}>{title}</p>
      <div className="flex items-center bg-white rounded-md h-[55px] px-4 ">
        <input
          type={showPass ? type : "text"}
          className={`w-full  placeholder:text-[#374151] outline-none bg-white h-full`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          id={title}
        />
        {title === "password" && (
          <>
            {showPass ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.407 17.993L20.707 19.293C21.098 19.684 21.098 20.316 20.707 20.707C20.512 20.902 20.256 21 20 21C19.744 21 19.488 20.902 19.293 20.707L16.586 18L16 17.414L14.655 16.069L14.586 16L12.0837 13.4977C12.056 13.4992 12.0281 13.5 12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.9719 10.5008 11.944 10.5023 11.9163L7.283 8.69701L5.532 6.94601L3.293 4.70701C2.902 4.31601 2.902 3.68401 3.293 3.29301C3.684 2.90201 4.316 2.90201 4.707 3.29301L6.42 5.00601L7.885 6.47101L11.9163 10.5023C11.944 10.5008 11.9719 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.0281 13.4992 12.056 13.4977 12.0837L17.414 16L19.407 17.993ZM8.5928 5.76421L11.3898 8.56121C11.5888 8.52621 11.7908 8.50021 11.9998 8.50021C13.9298 8.50021 15.4998 10.0702 15.4998 12.0002C15.4998 12.2092 15.4738 12.4112 15.4388 12.6102L18.1208 15.2932L18.9548 16.1272C20.4768 14.7392 21.4528 13.2202 21.8678 12.4972C22.0438 12.1892 22.0438 11.8102 21.8678 11.5022C21.2298 10.3902 17.7058 4.81621 11.7298 5.00321C10.5458 5.03421 9.4738 5.28921 8.5018 5.67421L8.5928 5.76421ZM13.948 16.7771L15.293 18.1211L15.498 18.3261C14.526 18.7111 13.455 18.9661 12.271 18.9961C12.189 18.9991 12.106 19.0001 12.025 19.0001C6.162 19.0001 2.762 13.5951 2.132 12.4971C1.956 12.1891 1.956 11.8101 2.132 11.5021C2.547 10.7801 3.523 9.26111 5.045 7.87311L6.576 9.40511L8.562 11.3901C8.526 11.5891 8.5 11.7911 8.5 12.0001C8.5 13.9301 10.07 15.5001 12 15.5001C12.209 15.5001 12.411 15.4741 12.61 15.4381L13.879 16.7071L13.948 16.7771Z"
                  fill="#6B7280"
                  onClick={() => setshowPass(false)}
                />

                <g mask="url(#mask0_1_4369)"></g>
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setshowPass(true)}>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.729 5.00264C17.706 4.81564 21.229 10.3896 21.868 11.5016C22.044 11.8106 22.044 12.1886 21.868 12.4976C21.014 13.9856 17.798 18.8556 12.271 18.9966C12.188 18.9986 12.106 18.9996 12.025 18.9996C6.162 18.9996 2.762 13.5946 2.132 12.4976C1.956 12.1886 1.956 11.8106 2.132 11.5016C2.986 10.0136 6.202 5.14364 11.729 5.00264ZM12 15.4996C10.07 15.4996 8.5 13.9296 8.5 11.9996C8.5 10.0696 10.07 8.49964 12 8.49964C13.93 8.49964 15.5 10.0696 15.5 11.9996C15.5 13.9296 13.93 15.4996 12 15.4996ZM12 10.4996C11.173 10.4996 10.5 11.1726 10.5 11.9996C10.5 12.8266 11.173 13.4996 12 13.4996C12.827 13.4996 13.5 12.8266 13.5 11.9996C13.5 11.1726 12.827 10.4996 12 10.4996Z"
                  fill="#6B7280"
                />
                <g mask="url(#mask0_837_12098)"></g>
              </svg>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ForgetPass = ({ setRestPassword, setRemember }) => {
  return (
    <div className="flex items-center justify-between w-full mt-2">
      <label class="custom-checkbox__login flex items-center gap-3  lg:text-[#fff] text-sm lg:text-lg font-semibold">
        <input
          type="checkbox"
          onChange={(e) => setRemember(e.target.checked)}
        />
        <span className="checkmark__login bg-[#fff] lg:bg-[#0455ba] lg:border-[3px] border-[2px] border-[#0D1C2E] lg:border-[#fff] lg:w-[20px] w-[18px] lg:h-[20px] h-[18px]"></span>
        Remember me
      </label>

      <div>
        <p
          className=" cursor-pointer text-[#0455BA] lg:text-[#fff] text-sm lg:text-lg font-semibold underline hover:text-[#1004ba] transition-all duration-100"
          onClick={() => setRestPassword(true)}>
          Forgot Password?
        </p>
      </div>
    </div>
  );
};

const LoginButton = ({ onLogin, handleKeyPress }) => {
  return (
    <button
      className="w-full h-[61px] rounded-md border bg-[#0455BA]  lg:border-white text-white text-[22px] font-semibold lg:hover:bg-white transition-all duration-100 lg:hover:text-black"
      onClick={onLogin}
      id="Log In">
      Log In
    </button>
  );
};
const SingUpButton = ({ onLogin }) => {
  return (
    <Link
      to={"/signUp"}
      className="w-full">
      <button
        className="w-full h-[61px] rounded-md border-2 border-[#374151] text-[#374151] text-[22px] font-semibold mt-10 hover:text-white hover:bg-[#0455BA] transition-all duration-100 hover:border-white"
        onClick={onLogin}
        id="Sign Up">
        Sign Up
      </button>
    </Link>
  );
};

const RestPassword = ({ onCancel }) => {
  const [email, validEmail, setValidEmail, setEmail] = useEmailValidation();
  const [valid, setvalid] = useState(true);
  const [validMessage, setvalidMessage] = useState("");
  const [done, setDone] = useState(false);

  const HandleLogin = async () => {
    if (email) {
      try {
        const res = await axios.get("/auth/forgot_password", {
          params: { email },
        });

        setDone(true);
      } catch (err) {
        setvalidMessage("The entered email is not existed in the system");
        setValidEmail(false);
        setvalid(false);
      }
    } else {
      setvalidMessage("Please Enter Your Email");
      setValidEmail(false);
      setvalid(false);
    }
    if (email) {
      if (!emailRegex.test(email)) {
        setvalidMessage("Please Enter a valid email address");
        setValidEmail(false);
        setvalid(false);
      }
    }
  };

  return (
    <>
      {done ? (
        <Done onCancel={onCancel} />
      ) : (
        <div className="absolute w-full h-full lg:w-[1014px] lg:h-[564px] border-2 left-1/2 z-10 reset__password lg:rounded-xl shadow-xl ">
          <div className=" lg:border-[30px] lg:border-[#0455BA] w-full h-full lg:rounded-xl  lg:bg-[#0455BA]">
            <div className="w-full h-full rounded-xl   bg-white px-10 py-12 flex flex-col justify-between">
              <div>
                <h2 className="lg:text-[48px] text-[36px] font-bold text-[#0455BA] lg:text-[#374151] lg:border-b-2 border-[#9CA3AF] pb-5 text-center">
                  Reset your password
                </h2>
                <div className="px-4 pt-4 pb-6 lg:px-0 lg:py-0 rounded-[10px] w-full">
                  <p className="text-xl lg:text-[#6B7280] text-[#374151] mb-6 mt-6">
                    Please enter your email address to search for your account
                  </p>
                  <input
                    type="text"
                    className="w-full lg:h-[69px] h-[50px] rounded-[10px] text-[#374151] border border-[#9CA3AF] placeholder:text-[#374151] px-5 outline-none"
                    placeholder="example@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {!valid && (
                    <p className="text-[#F44336] mt-2">{validMessage}</p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between lg:border-t-2 border-[#9CA3AF] pt-8 flex-col lg:flex-row">
                <p
                  className="text-2xl font-semibold  border-b-2 border-[#374151] text-[#374151] cursor-pointer"
                  onClick={onCancel}>
                  Log in with your password
                </p>
                <div className="flex items-center gap-5 flex-col-reverse lg:flex-row w-full mt-4">
                  <button
                    className="lg:w-[200px] w-full lg:h-[70px] h-[50px] border border-[#374151] rounded-[10px] text-[22px] font-semibold "
                    onClick={onCancel}>
                    Cancel
                  </button>
                  <button
                    className="lg:w-[200px] w-full lg:h-[70px] h-[50px] bg-[#0455BA] rounded-[10px] text-[22px] font-semibold text-white"
                    onClick={HandleLogin}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Done = ({ onCancel }) => {
  return (
    <div className="absolute w-full h-full lg:w-[1014px] lg:h-[564px] border-2 left-1/2 z-10 reset__password lg:rounded-xl shadow-xl">
      <div className=" border-[30px] border-[#0455BA] w-full h-full rounded-xl  bg-[#0455BA]">
        <div className="w-full h-full rounded-xl bg-white px-10 py-12 flex flex-col justify-center">
          <svg
            className="self-center"
            width="300"
            height="300"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
              fill="#7DBE6D"
            />
          </svg>
          <div className="flex items-center justify-between flex-col gap-5 pt-8">
            <h2 className="text-[38px] font-bold text-[#6B7280] text-center">
              Password reset link has been sent to your email
            </h2>
            <p className="text-xl text-[#6B7280]">
              Please, Check your email address
            </p>
            <div className="flex items-center gap-5">
              <button
                className="w-[239px] h-[61px] bg-[#0455BA] rounded-[10px] text-[22px] font-semibold text-white"
                onClick={onCancel}>
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
