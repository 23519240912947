import React from "react";
import { useStateContext } from "../../context/CreateContext";

const DeleteMessage = ({ title, nameButton, bref, onDelete, onCancle }) => {
  const { setDeleteMessage } = useStateContext();

  return (
    <div className="max-w-[538px] lg:max-h-[191px] bg-white shadow-md delete rounded-md py-4 px-5 flex flex-col justify-between items-end">
      <div className="border-b border-[#E5E7EB] pb-4">
        <h3 className="text-[20px] text-[#374151] font-semibold">{title}</h3>
        <p className="text-[#374151 mt-2">{bref}</p>
      </div>

      <div className="font-semibold flex items-center gap-2 mt-3 lg:flex-row lg:justify-end flex-col-reverse w-full">
        <button
          className="lg:w-[100px] w-full h-[40px] hover:bg-slate-100 rounded-md duration-100 border "
          onClick={() => onCancle(false)}
          id="DeleteMessage Cancel"
        >
          Cancel
        </button>
        <button
          className="lg:w-[100px] w-full h-[40px] text-white bg-[#DD0303] rounded-md"
          onClick={onDelete}
          id={"DeleteMessage" + nameButton}
        >
          {nameButton}
        </button>
      </div>
    </div>
  );
};

export default DeleteMessage;
