import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["1", "2", "3"];

export default function HorizontalLinearAlternativeLabelStepper({
  activeStep,
  setActiveStep,
}) {
  return (
    <Box sx={{ width: "100%" }} onC>
      <Stepper activeStep={activeStep}>
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
