import { useState } from "react";
import { priceReg } from "../utils/regEx";
import { useEffect } from "react";

const usePriceValidation = () => {
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);

  const validateTitle = (newTitle) => {
    const isValidTitle = newTitle && priceReg.test(newTitle);
    setPriceValid(isValidTitle);
  };

  const handlePriceChange = (e) => {
    const newTitle = e;
    setPrice(newTitle);
    if (!price) {
      validateTitle(newTitle);
    }
  };
  useEffect(() => {
    if (!price) {
      setPriceValid(true);
    }
    if (price && priceReg.test(price)) {
      setPriceValid(true);
    }
  }, [price]);
  return [price, priceValid, setPriceValid, handlePriceChange];
};
export default usePriceValidation;
