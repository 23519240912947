import { useState } from "react";
import { usePassValidation } from "../../hooks";
import { useEffect } from "react";
import axios from "../../api/axios";
import Input from "./Input";
import InputPass from "./InputPass";
import { useStateContext } from "../../context/CreateContext";

const Personal = ({
  lastName,
  firstName,
  email,
  setFirstName,
  setfristNameValid,
  firstNameValid,
  lastNameValid,
  setLastNameValid,
  setLastName,
}) => {
  const { idUser } = useStateContext();
  const [emailValid, setEmailValid] = useState(true);
  const [edit, setEdit] = useState(true);
  const [password, passwordValid, setPasswordValid, handlePasswordChange] =
    usePassValidation();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const handleSubmit = () => {
    // Reset validation

    // Check for errors
    if (!firstName) {
      setfristNameValid(false);
    }
    if (!lastName) {
      setLastNameValid(false);

      // If all fields are valid, proceed with form submission logic
    }

    // if (!password) {
    //   setPasswordValid(false);
    //   setConfirmPasswordValid(false);
    // }
    if (firstName && lastName && passwordValid && confirmPasswordValid) {
      // Your form submission logic here
      updateUserPersonal();
    }
  };
  useEffect(() => {
    if (confirmPassword === password) {
      setConfirmPasswordValid(true);
    } else {
      setConfirmPasswordValid(false);
    }
    if (!confirmPassword) {
      setConfirmPasswordValid(true);
    }
  }, [confirmPassword]);

  const updateUserPersonal = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("firstName", firstName);
    bodyFormData.append("lastName", lastName);
    password && bodyFormData.append("password", password);

    try {
      const res = await axios.put(
        `/api/teachers/${idUser}/personal`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEdit(true);
    } catch (err) {
      const {} = err.response.data;

      console.log(err);
    }
  };

  return (
    <div className="lg:px-4 lg:py-4">
      <div className="w-full mt-5 bg-[#E5E7EB] lg:py-0 py-4 px-4 lg:px-0  rounded-[10px] lg:bg-[#F5F5F5]">
        <div className="flex items-center justify-between w-full gap-5 flex-col lg:flex-row">
          <Input
            title={"First Name"}
            value={firstName}
            onChange={setFirstName}
            valid={firstNameValid}
            setValid={setfristNameValid}
            edit={edit}
          />
          <Input
            title={"Last Name"}
            value={lastName}
            onChange={setLastName}
            valid={lastNameValid}
            edit={edit}
          />
        </div>
        <Input
          title={"Email"}
          value={email}
          // onChange={setEmail}
          valid={emailValid}
          setValid={setEmailValid}
          edit={true}
        />
        <InputPass
          title={"Password"}
          onChange={handlePasswordChange}
          value={password}
          valid={passwordValid}
          edit={edit}
        />
        <InputPass
          title={"Confirm Password"}
          onChange={setConfirmPassword}
          value={confirmPassword}
          valid={confirmPasswordValid}
          edit={edit}
        />
      </div>{" "}
      {!edit ? (
        <div className="flex items-center gap-4">
          <button
            className="w-1/2 h-[42px] bg-[#0455BA] rounded-[4px] text-white text-lg mt-6"
            onClick={handleSubmit}
            id="PersonalSave">
            save
          </button>
          <button
            className="w-1/2 h-[42px] border text-black rounded-[4px] border-[#9CA3AF] text-lg mt-6"
            onClick={() => setEdit(true)}
            id="PersonalCancel">
            cancel
          </button>
        </div>
      ) : (
        <button
          className="lg:w-[140px] w-full lg:h-[42px] h-[50px] bg-[#0455BA] rounded-[4px] text-white text-2xl lg:text-lg mt-6"
          onClick={() => setEdit(false)}
          id="UpdateCancel">
          Update
        </button>
      )}
    </div>
  );
};
export default Personal;
