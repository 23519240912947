import { useState } from "react";
import { imageUrl } from "../../utils";
import { useStateContext } from "../../context/CreateContext";
import s3 from "../../utils/aws";

const CardPerson = ({ firstName, lastName, idUser, image, setChangeData }) => {
  const [uploadPhoto, setUploadPhoto] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);

  return (
    <>
      <div className="lg:w-[30%] h-fit bg-[#E5E7EB] lg:bg-[#1F618D] border-white lg:border-2 px-4 rounded-[10px] flex flex-col items-center py-5 shadow-lg justify-center mt-5 mb-5 relative">
        {uploadPhoto && (
          <>
            <div
              className="w-full h-full z-10 fixed bottom-0 left-0  overview"
              onClick={() => setUploadPhoto(false)}></div>
            <UpdatedPhoto
              error={error}
              setError={setError}
              setUploadPhoto={setUploadPhoto}
              setChangeData={setChangeData}
              file={file}
              setFile={setFile}
              image={image}
            />
          </>
        )}
        <div className="flex flex-col justify-center items-center">
          <div
            className={`relative w-[200px] h-[200px] rounded-[100px]  bg-cover bg-[50%_50%]`}>
            <img
              src={`${imageUrl + image}?${new Date().getTime()}`}
              alt="UserPhoto"
              className="w-full h-full rounded-full object-cover"
            />
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute bottom-2 right-5 cursor-pointer"
              onClick={() => setUploadPhoto(true)}>
              <rect
                width="40"
                height="40"
                rx="20"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.6008 13.0002C10.8582 13.0002 10.146 13.2952 9.62088 13.8203C9.09578 14.3454 8.80078 15.0576 8.80078 15.8002V27.0002C8.80078 27.7428 9.09578 28.455 9.62088 28.9801C10.146 29.5052 10.8582 29.8002 11.6008 29.8002H28.4008C29.1434 29.8002 29.8556 29.5052 30.3807 28.9801C30.9058 28.455 31.2008 27.7428 31.2008 27.0002V15.8002C31.2008 15.0576 30.9058 14.3454 30.3807 13.8203C29.8556 13.2952 29.1434 13.0002 28.4008 13.0002H26.1804C25.8091 13.0001 25.4531 12.8526 25.1906 12.59L23.6212 11.0206C23.0962 10.4955 22.3841 10.2004 21.6416 10.2002H18.36C17.6174 10.2004 16.9054 10.4955 16.3804 11.0206L14.811 12.59C14.5485 12.8526 14.1925 13.0001 13.8212 13.0002H11.6008ZM20.0008 25.6002C20.5523 25.6002 21.0985 25.4916 21.6081 25.2805C22.1176 25.0694 22.5806 24.76 22.9706 24.37C23.3606 23.98 23.67 23.517 23.8811 23.0075C24.0921 22.4979 24.2008 21.9517 24.2008 21.4002C24.2008 20.8486 24.0921 20.3025 23.8811 19.7929C23.67 19.2834 23.3606 18.8204 22.9706 18.4303C22.5806 18.0403 22.1176 17.731 21.6081 17.5199C21.0985 17.3088 20.5523 17.2002 20.0008 17.2002C18.8869 17.2002 17.8186 17.6427 17.0309 18.4303C16.2433 19.218 15.8008 20.2863 15.8008 21.4002C15.8008 22.5141 16.2433 23.5824 17.0309 24.37C17.8186 25.1577 18.8869 25.6002 20.0008 25.6002Z"
                fill="#374151"
              />
            </svg>
          </div>
          <p className="text-center text-2xl font-semibold text-[#1E69CB]  lg:text-white mt-2">
            {firstName} {lastName}
          </p>
          <p className="text-center text-2xl font-semibold text-[#1E69CB] lg:text-white">
            ID:{idUser}
          </p>
        </div>
      </div>
    </>
  );
};
export default CardPerson;

const UpdatedPhoto = ({
  id,
  setUploadPhoto,
  setChangeData,
  file,
  setFile,
  error,
  setError,
  image,
}) => {
  const [valid, setValid] = useState(true);
  const { idUser } = useStateContext();
  const HandleUpload = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check file type
      const fileType = file.type;
      if (
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg"
      ) {
        // Check file size (in bytes)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size <= maxSize) {
          // File is valid
          setFile(file);
          setValid(true);
          changeImage(file);
        } else {
          setValid(false);
        }
      } else {
        setValid(false);
      }
    }
  };

  const changeImage = async (file) => {
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/images/userImages`,
      Key: image,
      Body: file,
      ACL: "public-read",
    };
    try {
      s3.upload(params, (err, data) => {
        setChangeData((e) => !e);
        setUploadPhoto(false);
      });
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };
  return (
    <div className="w-[352px] h-[171px] rounded-[10px] py-5 px-5 absolute bg-white z-20 top-[0%] flex-col right-[-15px] flex justify-between items-center">
      <div>
        <h2 className="text-3xl text-[#1F2937] font-bold border-b pb-1">
          Update profile Photo
        </h2>
        <p
          className={`${
            valid ? "text-[#6B7280]" : "text-[#DD0303]"
          }  text-center pt-1`}>
          JPG or PNG no larger than 2 MB
        </p>
        {error && (
          <p className="text-center text-red-500">Error uploading Your Photo</p>
        )}
      </div>
      <div
        className="flex items-center justify-center"
        id="Upload Photo">
        <input
          type="file"
          id="upload"
          hidden
          onChange={(e) => HandleUpload(e)}
        />
        <label
          for="upload"
          className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1 cursor-pointer">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
              fill="white"
            />

            <g mask="url(#mask0_2294_15588)"></g>
          </svg>
          Upload Photo
        </label>
      </div>
      {/* <button className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
            fill="white"
          />

          <g mask="url(#mask0_2294_15588)"></g>
        </svg>
        Upload Photo
      </button> */}
    </div>
  );
};
