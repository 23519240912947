import React, { useEffect, useState } from "react";
import Input from "./Input";
import { useStateContext } from "../../context/CreateContext";
import DeleteMessage from "../DeleteMessage/DeleteMessage";
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import { useDescValidation, useTitleValidation } from "../../hooks";

const EditLessons = ({
  Title,
  Description,
  LessonNo,
  IdLessone,
  setShowEditLesson,
  setchangeData,
}) => {
  useEffect(() => {
    handleTitleChange(Title);
  }, []);
  const [edit, setEdit] = useState(true);
  const { id } = useParams();
  const [deleteMessage, setdeleteMessage] = useState(false);
  const [valid, setvalid] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesValid, setFilesValid] = useState(true);
  const [lessonNo, setLessonNo] = useState(LessonNo);
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();
  const handleSubmit = () => {
    // Reset validation

    // Check for errors
    if (!title) {
      setTitleValid(false);
    }
    // if (files.length <= 0) {
    //   setFilesValid(false);
    //   // If all fields are valid, proceed with form submission logic
    // }
    if (title && titleValid) {
      // Your form submission logic here
      EditLessons();
    }
  };

  const EditLessons = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("courseId", id);
    bodyFormData.append("lessonNo", lessonNo);

    try {
      const res = await axios.put(`api/lessons/${IdLessone}`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setShowEditLesson(false);
      setchangeData((e) => !e);
    } catch (err) {
      const {} = err.response.data;
      console.log(err);
    }
  };

  return (
    <>
      {deleteMessage && (
        <>
          <div className="overflow absolute top-0 left-0"></div>
          <DeleteMessage
            title={"If you out now you will lose data you was entered "}
            nameButton={"Yes"}
            onDelete={() => setShowEditLesson(false)}
            onCancle={setdeleteMessage}
          />
        </>
      )}
      <div className="rounded-[4px]  add__course  w-full lg:w-[729px] lg:min-h-[343px] h-full  lg:h-fit flex flex-col max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed  ">
        <div className="lg:hidden items-center justify-between mb-10 flex">
          <h2 className="text-[28px] font-semibold text-[#1E69CB]">
            Edit File
          </h2>{" "}
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowEditLesson(false)}
            className="cursor-pointer">
            <path
              d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
              fill="#374151"
            />

            <g mask="url(#mask0_1240_20873)"></g>
          </svg>
        </div>
        {edit && (
          <button
            className="w-full h-[44px] rounded-[4px] border border-[#40A726] flex lg:hidden items-center justify-center gap-1 text-lg text-[#40A726] mb-2"
            onClick={() => setEdit(false)}
            id={Title.split(" ").join("-")}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                fill="#40A726"
              />

              <g mask="url(#mask0_1461_1482)"></g>
            </svg>
            Edit
          </button>
        )}
        {edit && (
          <button
            className="lg:w-[98px] h-[44px] hidden rounded-[4px] border border-[#40A726] lg:flex items-center justify-center gap-1 text-lg text-[#40A726] absolute right-3 top-8"
            onClick={() => setEdit(false)}
            id={Title.split(" ").join("-") + "Edit"}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                fill="#40A726"
              />

              <g mask="url(#mask0_1461_1482)"></g>
            </svg>
            Edit
          </button>
        )}

        <div className=" flex flex-col gap-5 mt-5 ">
          <div>
            <Input
              edit={edit}
              title={"Title"}
              setValid={setTitleValid}
              valid={titleValid}
              value={title}
              onChange={handleTitleChange}
              padding="px-4 pt-4 pb-6 lg:px-0 lg:py-0"
            />
          </div>
          {/* <div>
            <Input
              edit={edit}
              title={"Content"}
              value={description}
              onChange={handleDescriptionChange}
              valid={descriptionValid}
              setValid={setDescriptionValid}
              height={"h-[101px]"}
              padding="px-4 pt-4 pb-6 lg:px-0 lg:py-0"
            />
          </div> */}
          <div className="bg-[#E5E7EB] lg:bg-[#F5F5F5] px-4  pb-6 lg:px-0 lg:py-0 rounded-[10px]">
            <LessonNumber
              edit={edit}
              lessonNo={lessonNo}
              setLessonNo={setLessonNo}
              title={title}
            />
          </div>
        </div>

        {!edit ? (
          <Button
            setShowAddCourse={setEdit}
            HandleSave={handleSubmit}
            onCancle={setdeleteMessage}
          />
        ) : (
          <button
            id="EditCancel"
            className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151] mt-5"
            onClick={() => setShowEditLesson(false)}>
            Cancel
          </button>
        )}
      </div>
    </>
  );
};
export default EditLessons;

const Button = ({ setShowAddCourse, HandleSave, onCancle }) => {
  return (
    <div className="lg:mt-3 mt-8 flex items-center gap-5 flex-col lg:flex-row text-[24px] lg:text-base">
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] bg-[#0455BA] font-semibold text-white"
        onClick={HandleSave}
        id="EditLessonsSave">
        Save
      </button>
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151]"
        onClick={() => onCancle(true)}
        id="EditLessonsCancel">
        Cancel
      </button>
    </div>
  );
};

const LessonNumber = ({ edit, lessonNo, setLessonNo }) => {
  return (
    <div className="mt-5">
      <p className="font-semibold text-[#374151]  text-[20px]">Lesson Number</p>

      {!edit ? (
        <>
          <div className="w-full h-[50px] border border-[#9CA3AF] rounded-[4px] mt-1 flex items-center justify-between">
            <div
              className="w-[50px] h-[50px] bg-[#0455BA] flex items-center justify-center rounded-tl-[4px] rounded-bl-[4px] cursor-pointer"
              onClick={() => setLessonNo((e) => (e > 1 ? e - 1 : e))}
              id="decLessone">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5 15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5L9 16.5C8.60218 16.5 8.22064 16.342 7.93934 16.0607C7.65804 15.7794 7.5 15.3978 7.5 15C7.5 14.6022 7.65804 14.2206 7.93934 13.9393C8.22065 13.658 8.60218 13.5 9 13.5L21 13.5C21.3978 13.5 21.7794 13.658 22.0607 13.9393C22.342 14.2206 22.5 14.6022 22.5 15Z"
                  fill="#F3F4F6"
                />
              </svg>
            </div>
            <div className="bg-white w-full h-full flex items-center justify-center">
              <p className="text-[22px] font-semibold text-[#374151]">
                {lessonNo}
              </p>
            </div>

            <div
              className="w-[50px] h-[50px] bg-[#0455BA] flex items-center justify-center rounded-tr-[4px] rounded-br-[4px] cursor-pointer"
              onClick={() => setLessonNo((e) => e + 1)}
              id="incLessone">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 15C7.5 14.6022 7.65804 14.2206 7.93934 13.9393C8.22064 13.658 8.60217 13.5 9 13.5L13.5 13.5L13.5 9C13.5 8.60218 13.658 8.22064 13.9393 7.93934C14.2206 7.65803 14.6022 7.5 15 7.5C15.3978 7.5 15.7794 7.65803 16.0607 7.93934C16.342 8.22064 16.5 8.60218 16.5 9L16.5 13.5L21 13.5C21.3978 13.5 21.7794 13.658 22.0607 13.9393C22.342 14.2206 22.5 14.6022 22.5 15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5L16.5 16.5L16.5 21C16.5 21.3978 16.342 21.7794 16.0607 22.0607C15.7794 22.342 15.3978 22.5 15 22.5C14.6022 22.5 14.2206 22.342 13.9393 22.0607C13.658 21.7794 13.5 21.3978 13.5 21L13.5 16.5L9 16.5C8.60217 16.5 8.22064 16.342 7.93934 16.0607C7.65804 15.7794 7.5 15.3978 7.5 15Z"
                  fill="#F3F4F6"
                />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <p className="text-[#374151]">{lessonNo}</p>
      )}
    </div>
  );
};
