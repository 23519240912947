import { Navigate, Outlet } from "react-router-dom";
import { AddCourse, Navbar, SideBar, AddFeedback } from "./components";
import { useEffect } from "react";
import { useStateContext } from "./context/CreateContext";
import axios from "./api/axios";
import Withdraw from "./components/Withdraw";

function App() {
  const { ShowAddCourse, ShowAddFeedback, token, showWithdraw } =
    useStateContext();
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [token]);
  return (
    <>
      {ShowAddCourse && (
        <>
          <div className="overflow"></div>
          <AddCourse />
        </>
      )}
      {showWithdraw && (
        <>
          <div className="overflow"></div>
          <Withdraw />
        </>
      )}

      {ShowAddFeedback && (
        <>
          <div className="overflow"></div>
          <AddFeedback />
        </>
      )}
      <div className=" w-full relative">
        {token ? (
          <>
            <div className="2xl:w-[15%] lg:w-[20%]">
              <SideBar />
            </div>
            <div className="2xl:w-[85%] lg:w-[85%] lg:px-[4rem] px-[2rem] pt-[2px] max-w-[120rem]  lg:mx-[18%] 2xl:mx-[15%] max-h-screen h-screen ">
              <Navbar />
              <Outlet />
            </div>
          </>
        ) : (
          <Navigate to={"/login"} />
        )}
      </div>
    </>
  );
}

export default App;
