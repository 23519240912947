import Calendar from "./Calendar";

function Cakebar({ eventsData }) {
  const events = Object.entries(eventsData).map(([title, dateString]) => ({
    title,
    date: parseDate(dateString),
  }));

  function parseDate(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  return <Calendar events={events} />;
}

export default Cakebar;
