import React from "react";
import { Statistic } from "../components";
import { BiSolidBookAlt } from "react-icons/bi";
import PieCom from "../components/Charts/Pie";
import AreaCon from "../components/Charts/AreaCon";
import axios from "../api/axios";
import { useState } from "react";
import { useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import { useStateContext } from "../context/CreateContext";
import Cakebar from "../components/Cakebar";
import { FaFileLines } from "react-icons/fa6";
import { BiMoneyWithdraw } from "react-icons/bi";
import { TbPigMoney } from "react-icons/tb";
import { MdOutlineAttachMoney } from "react-icons/md";

const Overview = () => {
  const { idUser, setShowWithdraw, setBalance } = useStateContext();
  const [totalCourses, setTotalCourses] = useState("");
  const [totalStudents, setTotalStudents] = useState("");
  const [totalIncome, setTotalIncome] = useState("");
  const [studentPercent, setStudentPercent] = useState({});
  const [incomePercent, setIncomePercent] = useState({});
  const [incomeProgress, setIncomeProgress] = useState([0]);
  const [studentProgress, setStudentProgress] = useState([0]);
  const [loading, setloading] = useState(true);
  const [events, setEvents] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    const getstatistics = async () => {
      try {
        const res = await axios.get(`/api/statistics/dashboard`, {
          params: { targetYear: 2024 },
        });
        const {
          totalCourses,
          balance,
          totalStudents,
          totalIncome,
          studentPercent,
          incomePercent,
          incomeProgress,
          studentProgress,
          events,
        } = res.data;
        setTotalBalance(balance);
        setBalance(balance);
        setTotalCourses(totalCourses);
        setTotalStudents(totalStudents);
        setTotalIncome(totalIncome);
        setStudentPercent(studentPercent);
        setIncomePercent(incomePercent);
        setIncomeProgress(incomeProgress);
        setStudentProgress(studentProgress);
        setEvents(events);

        setloading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getstatistics();
  }, [idUser, setBalance]);

  const handleData = (backendData) => {
    // Convert data to the desired format
    const incomeData = [["ID", "Name", "Total"]];
    let id = 1;

    for (const [name, total] of Object.entries(backendData)) {
      incomeData.push([id++, name, total.toString()]);
    }

    return incomeData;
  };

  const generatePDF = async () => {
    const pdf = new jsPDF();
    let currentY = 15; // Initial y-coordinate

    try {
      // ... (previous code)

      // Skip adding the chart image for now

      currentY += 10; // Add some space between sections
    } catch (err) {
      console.log(err);
    }

    pdf.text("Overview", 20, currentY);

    const overData = [
      ["ID", "Name", "Total"],
      [1, "totalCourses", totalCourses],
      [2, "totalIncome", totalIncome],
      [3, "totalStudents", totalStudents],
      [4, "totalBalance", totalBalance],
    ];

    // Define the table headers and rows
    const overviewTable = pdf.autoTable({
      startY: currentY + 10, // Set the starting y-coordinate for the table
      head: [overData[0]], // Table headers
      body: overData.slice(1), // Table rows
      theme: "grid", // Optional: add a grid theme
    });

    currentY = overviewTable.lastAutoTable.finalY + 10; // Update the currentY

    const incomData = handleData(incomePercent);
    pdf.text("incomePercent", 20, currentY);

    // Define the table headers and rows
    const incomeTable = pdf.autoTable({
      startY: currentY + 10, // Set the starting y-coordinate for the table
      head: [incomData[0]], // Table headers
      body: incomData.slice(1), // Table rows
      theme: "grid", // Optional: add a grid theme
    });

    currentY = incomeTable.lastAutoTable.finalY + 10; // Update the currentY

    const studentData = handleData(studentPercent);
    pdf.text("studentPercent", 20, currentY);

    const studentTable = pdf.autoTable({
      startY: currentY + 10, // Set the starting y-coordinate for the table
      head: [studentData[0]], // Table headers
      body: studentData.slice(1), // Table rows
      theme: "grid", // Optional: add a grid theme
    });

    currentY = studentTable.lastAutoTable.finalY + 10; // Update the currentY

    const analyticsData = [
      ["Month", "Student", "Income"],
      ["JAN", "80", "20"],
      ["FEB", "80", "80"],
      ["MAR", "80", "80"],
      ["APR", "80", "80"],
      ["MAY", "80", "80"],
      ["JUN", "80", "80"],
      ["JUL", "80", "80"],
      ["AUG", "80", "80"],
      ["SEP", "80", "80"],
      ["OCT", "80", "80"],
      ["NOV", "80", "80"],
      ["DEC", "80", "80"],
    ];

    const newValues1 = studentProgress;
    const newValues2 = incomeProgress;

    // Update values in the analyticsData array
    for (let i = 1; i < analyticsData.length; i++) {
      analyticsData[i][1] = newValues1[i - 1].toString();
      analyticsData[i][2] = newValues2[i - 1].toString();
    }

    pdf.text("Analytics", 20, currentY);

    // Define the table headers and rows
    pdf.autoTable({
      startY: currentY + 10, // Set the starting y-coordinate for the table
      head: [analyticsData[0]], // Table headers
      body: analyticsData.slice(1), // Table rows
      theme: "grid", // Optional: add a grid theme
    });

    currentY = pdf.autoTable.previous.finalY + 10; // Update the currentY

    // Now, add the chart image to the PDF
    try {
      const capture = document.querySelector("#LineChart");
      const chartImage = await html2canvas(capture);

      // Calculate the scaling factor to maintain the aspect ratio
      const scaleFactor = pdf.internal.pageSize.width / chartImage.width;
      const scaledWidth = pdf.internal.pageSize.width;
      const scaledHeight = chartImage.height * scaleFactor;

      // Add the chart image to the PDF, centered
      pdf.addImage(
        chartImage.toDataURL("image/png"),
        "PNG",
        (pdf.internal.pageSize.width - scaledWidth) / 2,
        currentY,
        scaledWidth,
        scaledHeight
      );
    } catch (err) {
      console.log(err);
    }

    // Save the PDF
    pdf.save("report.pdf");
  };

  return (
    <div>
      <div className="lg:flex items-center justify-between hidden">
        <h2 className="text-[57px] font-bold text-[#374151]">Overview</h2>
        <div className="flex items-center gap-5">
          <button
            className={`bg-[#374151] rounded-md flex items-center h-[30px] text-sm text-white px-4 gap-2 font-semibold  hover:brightness-125 transition-all duration-300`}
            onClick={() => setShowWithdraw(true)}>
            {/* <FaFileLines /> */}
            <BiMoneyWithdraw className="w-5 h-5" />
            Withdraw
          </button>
          <button
            className={`bg-[#0455BA] rounded-md flex items-center h-[30px] text-sm text-white px-4 gap-2 font-semibold  hover:brightness-125 transition-all duration-300`}
            onClick={generatePDF}>
            <FaFileLines className="w-5 h-5" />
            Generate report
          </button>
        </div>
      </div>

      <ContainerStatistics
        totalIncome={totalIncome}
        totalStudents={totalStudents}
        totalCourses={totalCourses}
        totalBalance={totalBalance}
      />
      <ContainerStatisticsPhone
        totalIncome={totalIncome}
        totalStudents={totalStudents}
        totalCourses={totalCourses}
        studentPercent={studentPercent}
        incomePercent={incomePercent}
        totalBalance={totalBalance}
      />

      <div className="lg:flex items-center gap-10 mt-3 test hidden">
        <PieContainer
          title={"Students"}
          dataPercent={studentPercent || 0}
          totalIncome={totalIncome || 0}
          totalStudents={totalStudents || 0}
        />
        <PieContainer
          title={"Income"}
          dataPercent={incomePercent}
          totalStudents={totalStudents}
          totalIncome={totalIncome}
        />
        {/* <BasicDateCalendar width={"w-1/3"} /> */}
        <Cakebar eventsData={events} />
      </div>
      {/* <div className="lg:hidden items-center gap-10 mt-3 test w-full">
        <Cakebar eventsData={events} />
      </div> */}
      <div className="w-full lg:h-[300px] h-[200px] shadow-md rounded-xl bg-white flex items-start justify-center relative mt-3 lg:mb-2">
        <AreaCon
          incomeProgress={incomeProgress}
          studentProgress={studentProgress}
        />
      </div>
    </div>
  );
};

export default Overview;

const PieContainer = ({ title, dataPercent, totalStudents, totalIncome }) => {
  return (
    <div
      className="w-1/3 h-[325px] rounded-xl bg-white flex items-start justify-center relative"
      id={title}>
      <div className="absolute top-[-9%] right-0 left-0 w-full ">
        <PieCom
          pieData={dataPercent}
          innerRadius={70}
          outerRadius={95}
          width={300}
          height={300}
        />
      </div>
      <div className="absolute top-[30%]">
        <h3 className="text-[28px] font-medium">{title}</h3>
      </div>
      <PieIcon
        titleText={title}
        iconPercent={dataPercent}
        totalIncome={totalIncome}
        totalStudents={totalStudents}
      />
    </div>
  );
};

const PieIcon = ({ iconPercent, totalStudents, totalIncome, titleText }) => {
  const arrayOfObjectsStudent = Object.keys(iconPercent).map((key) => ({
    title: key,
    value: ((iconPercent[key] / totalStudents) * 100).toFixed(0),
  }));

  const arrayOfObjectsIncome = Object.keys(iconPercent).map((key) => ({
    title: key,
    value: ((iconPercent[key] / totalIncome) * 100).toFixed(0),
  }));

  return (
    <div className="absolute bottom-0 w-full h-[25%]  px-5 grid grid-cols-2 grid-rows-2 justify-center">
      {titleText === "Students" &&
        arrayOfObjectsStudent.map((data) => (
          <div
            key={data.title}
            className="flex items-start gap-1">
            <div className="lg:w-[12px] lg:h-[12px] w-[10px] h-[10px] bg-[#0455BA] rounded-full lg:mt-1"></div>
            <div className="flex gap-1">
              <p className="text-[8px] lg:text-[12px] text-[#374151] font-semibold">
                {data.value}% {data.title}
              </p>
            </div>
          </div>
        ))}
      {titleText === "Income" &&
        arrayOfObjectsIncome.map((data) => (
          <div
            key={data.title}
            className="flex items-start gap-1">
            <div className="lg:w-[12px] lg:h-[12px] w-[10px] h-[10px] bg-[#0455BA] rounded-full lg:mt-1"></div>
            <div className="flex gap-1">
              <p className="text-[8px] lg:text-[12px] text-[#374151] font-semibold">
                {data.value === "NaN" || data.value === "Infinity"
                  ? 0
                  : data.value}
                % {data.title}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

const ContainerStatistics = ({
  totalCourses,
  totalStudents,
  totalIncome,
  totalBalance,
}) => {
  return (
    <div className="lg:flex flex-wrap items-center justify-between hidden gap-[20px]">
      <Statistic
        width={"w-1/3"}
        height={"h-[172px]"}
        title={"Courses"}
        num={totalCourses}
        icon={<BiSolidBookAlt className="w-[60px] h-[70px] text-[#2e608c]" />}
        lastIcon={
          <svg
            width="12"
            height="102"
            viewBox="0 0 12 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 11.2782C0 9.2876 1.18082 7.4866 3.00637 6.69288L16.0064 1.04071C19.3086 -0.395043 23 2.02522 23 5.62606V96.3739C23 99.9748 19.3086 102.395 16.0064 100.959L3.00637 95.3071C1.18082 94.5134 0 92.7124 0 90.7218V11.2782Z"
              fill="#1F618D"
            />
          </svg>
        }
        fontSize={"text-[28px]"}
        fontWight={"font-bold"}
      />
      <Statistic
        width={"w-1/3"}
        height={"h-[172px]"}
        title={"Students"}
        num={totalStudents}
        icon={
          <svg
            width="77"
            height="65"
            viewBox="0 0 77 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M51 13.5C51 16.8815 49.6567 20.1245 47.2656 22.5156C44.8745 24.9067 41.6315 26.25 38.25 26.25C34.8685 26.25 31.6255 24.9067 29.2344 22.5156C26.8433 20.1245 25.5 16.8815 25.5 13.5C25.5 10.1185 26.8433 6.87548 29.2344 4.48439C31.6255 2.0933 34.8685 0.75 38.25 0.75C41.6315 0.75 44.8745 2.0933 47.2656 4.48439C49.6567 6.87548 51 10.1185 51 13.5ZM72.25 22C72.25 24.2543 71.3545 26.4163 69.7604 28.0104C68.1663 29.6045 66.0043 30.5 63.75 30.5C61.4957 30.5 59.3336 29.6045 57.7396 28.0104C56.1455 26.4163 55.25 24.2543 55.25 22C55.25 19.7457 56.1455 17.5836 57.7396 15.9896C59.3336 14.3955 61.4957 13.5 63.75 13.5C66.0043 13.5 68.1663 14.3955 69.7604 15.9896C71.3545 17.5836 72.25 19.7457 72.25 22ZM55.25 51.75C55.25 47.2413 53.4589 42.9173 50.2708 39.7292C47.0827 36.5411 42.7587 34.75 38.25 34.75C33.7413 34.75 29.4173 36.5411 26.2292 39.7292C23.0411 42.9173 21.25 47.2413 21.25 51.75V64.5H55.25V51.75ZM21.25 22C21.25 24.2543 20.3545 26.4163 18.7604 28.0104C17.1663 29.6045 15.0043 30.5 12.75 30.5C10.4957 30.5 8.33365 29.6045 6.73959 28.0104C5.14553 26.4163 4.25 24.2543 4.25 22C4.25 19.7457 5.14553 17.5836 6.73959 15.9896C8.33365 14.3955 10.4957 13.5 12.75 13.5C15.0043 13.5 17.1663 14.3955 18.7604 15.9896C20.3545 17.5836 21.25 19.7457 21.25 22ZM63.75 64.5V51.75C63.7561 47.4289 62.659 43.1779 60.5625 39.3995C62.4467 38.9173 64.4162 38.8718 66.3207 39.2666C68.2251 39.6613 70.0143 40.4858 71.5517 41.6771C73.089 42.8685 74.334 44.3952 75.1915 46.1409C76.0491 47.8866 76.4966 49.8051 76.5 51.75V64.5H63.75ZM15.9375 39.3995C13.8412 43.1779 12.744 47.429 12.75 51.75V64.5H1.12931e-06V51.75C-0.000817989 49.8037 0.443969 47.883 1.30027 46.1351C2.15658 44.3873 3.40167 42.8587 4.94013 41.6665C6.47859 40.4743 8.26957 39.6502 10.1758 39.2572C12.0821 38.8642 14.053 38.9129 15.9375 39.3995Z"
              fill="#1F618D"
            />
          </svg>
        }
        lastIcon={
          <svg
            width="12"
            height="102"
            viewBox="0 0 12 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 11.2782C0 9.2876 1.18082 7.4866 3.00637 6.69288L16.0064 1.04071C19.3086 -0.395043 23 2.02522 23 5.62606V96.3739C23 99.9748 19.3086 102.395 16.0064 100.959L3.00637 95.3071C1.18082 94.5134 0 92.7124 0 90.7218V11.2782Z"
              fill="#1F618D"
            />
          </svg>
        }
        fontSize={"text-[28px]"}
        fontWight={"font-bold"}
      />
      <Statistic
        width={"w-1/3"}
        height={"h-[172px]"}
        title={"Income"}
        num={totalIncome + "$"}
        icon={
          <MdOutlineAttachMoney className="w-[60px] h-[70px] text-[#2e608c]" />
        }
        lastIcon={
          <svg
            width="12"
            height="102"
            viewBox="0 0 12 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 11.2782C0 9.2876 1.18082 7.4866 3.00637 6.69288L16.0064 1.04071C19.3086 -0.395043 23 2.02522 23 5.62606V96.3739C23 99.9748 19.3086 102.395 16.0064 100.959L3.00637 95.3071C1.18082 94.5134 0 92.7124 0 90.7218V11.2782Z"
              fill="#1F618D"
            />
          </svg>
        }
        fontSize={"text-[28px]"}
        fontWight={"font-bold"}
      />
      <Statistic
        width={"w-1/3"}
        height={"h-[172px]"}
        title={"Balance"}
        num={totalBalance + "$"}
        icon={<TbPigMoney className="w-[60px] h-[70px] text-[#2e608c]" />}
        lastIcon={
          <svg
            width="12"
            height="102"
            viewBox="0 0 12 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 11.2782C0 9.2876 1.18082 7.4866 3.00637 6.69288L16.0064 1.04071C19.3086 -0.395043 23 2.02522 23 5.62606V96.3739C23 99.9748 19.3086 102.395 16.0064 100.959L3.00637 95.3071C1.18082 94.5134 0 92.7124 0 90.7218V11.2782Z"
              fill="#1F618D"
            />
          </svg>
        }
        fontSize={"text-[28px]"}
        fontWight={"font-bold"}
      />
    </div>
  );
};
const ContainerStatisticsPhone = ({
  totalCourses,
  totalStudents,
  totalIncome,
  studentPercent,
  incomePercent,
  totalBalance,
}) => {
  return (
    <div className="flex items-start justify-between mt-4 h-full lg:hidden gap-3">
      <div className="w-1/2 flex flex-col gap-6 justify-between h-full">
        <Statistic
          phone={true}
          width={""}
          height={"h-[120px]"}
          title={"Courses"}
          num={totalCourses}
          icon={<BiSolidBookAlt className="w-[36px] h-[36px] text-[#2e608c]" />}
          fontSize={"text-[18px]"}
          fontWight={"font-semibold"}
        />
        <Statistic
          phone={true}
          width={""}
          height={"h-[120px]"}
          title={"Students"}
          num={totalStudents}
          icon={
            <svg
              width="36"
              height="36"
              viewBox="0 0 77 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M51 13.5C51 16.8815 49.6567 20.1245 47.2656 22.5156C44.8745 24.9067 41.6315 26.25 38.25 26.25C34.8685 26.25 31.6255 24.9067 29.2344 22.5156C26.8433 20.1245 25.5 16.8815 25.5 13.5C25.5 10.1185 26.8433 6.87548 29.2344 4.48439C31.6255 2.0933 34.8685 0.75 38.25 0.75C41.6315 0.75 44.8745 2.0933 47.2656 4.48439C49.6567 6.87548 51 10.1185 51 13.5ZM72.25 22C72.25 24.2543 71.3545 26.4163 69.7604 28.0104C68.1663 29.6045 66.0043 30.5 63.75 30.5C61.4957 30.5 59.3336 29.6045 57.7396 28.0104C56.1455 26.4163 55.25 24.2543 55.25 22C55.25 19.7457 56.1455 17.5836 57.7396 15.9896C59.3336 14.3955 61.4957 13.5 63.75 13.5C66.0043 13.5 68.1663 14.3955 69.7604 15.9896C71.3545 17.5836 72.25 19.7457 72.25 22ZM55.25 51.75C55.25 47.2413 53.4589 42.9173 50.2708 39.7292C47.0827 36.5411 42.7587 34.75 38.25 34.75C33.7413 34.75 29.4173 36.5411 26.2292 39.7292C23.0411 42.9173 21.25 47.2413 21.25 51.75V64.5H55.25V51.75ZM21.25 22C21.25 24.2543 20.3545 26.4163 18.7604 28.0104C17.1663 29.6045 15.0043 30.5 12.75 30.5C10.4957 30.5 8.33365 29.6045 6.73959 28.0104C5.14553 26.4163 4.25 24.2543 4.25 22C4.25 19.7457 5.14553 17.5836 6.73959 15.9896C8.33365 14.3955 10.4957 13.5 12.75 13.5C15.0043 13.5 17.1663 14.3955 18.7604 15.9896C20.3545 17.5836 21.25 19.7457 21.25 22ZM63.75 64.5V51.75C63.7561 47.4289 62.659 43.1779 60.5625 39.3995C62.4467 38.9173 64.4162 38.8718 66.3207 39.2666C68.2251 39.6613 70.0143 40.4858 71.5517 41.6771C73.089 42.8685 74.334 44.3952 75.1915 46.1409C76.0491 47.8866 76.4966 49.8051 76.5 51.75V64.5H63.75ZM15.9375 39.3995C13.8412 43.1779 12.744 47.429 12.75 51.75V64.5H1.12931e-06V51.75C-0.000817989 49.8037 0.443969 47.883 1.30027 46.1351C2.15658 44.3873 3.40167 42.8587 4.94013 41.6665C6.47859 40.4743 8.26957 39.6502 10.1758 39.2572C12.0821 38.8642 14.053 38.9129 15.9375 39.3995Z"
                fill="#1F618D"
              />
            </svg>
          }
          fontSize={"text-[18px]"}
          fontWight={"font-semibold"}
        />
        <Statistic
          phone={true}
          width={""}
          height={"h-[120px]"}
          title={"Income"}
          num={totalIncome + "$"}
          icon={
            <MdOutlineAttachMoney className="w-[36px] h-[36px] text-[#2e608c]" />
          }
          fontSize={"text-[18px]"}
          fontWight={"font-semibold"}
        />
        <Statistic
          phone={true}
          width={""}
          height={"h-[120px]"}
          title={"Balance"}
          num={totalBalance + "$"}
          icon={<TbPigMoney className="w-[36px] h-[36px] text-[#2e608c]" />}
          fontSize={"text-[18px]"}
          fontWight={"font-semibold"}
        />
      </div>
      <div className="w-1/2 h-full flex flex-col gap-3">
        <PieContainerPhone
          title={"Students"}
          dataPercent={studentPercent}
        />
        <PieContainerPhone
          title={"Income"}
          dataPercent={incomePercent}
        />
      </div>
    </div>
  );
};
const PieContainerPhone = ({ title, dataPercent }) => {
  return (
    <div
      className="w-full h-[200px] shadow-md rounded-xl bg-white flex items-start justify-center relative "
      id={title}>
      <div className=" absolute top-[-13%] right-0 left-0">
        <PieCom
          pieData={dataPercent}
          innerRadius={60}
          outerRadius={40}
          width={200}
          height={200}
        />
      </div>
      <div className=" absolute top-[30%]">
        <h3 className="text-[16px] font-medium">{title}</h3>
      </div>
      <PieIcon iconPercent={dataPercent} />
    </div>
  );
};
