/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  isSameDay,
} from "date-fns";
import styled from "styled-components";

const CalendarContainer = styled.div`
  width: calc(100% / 3);
  max-width: 40rem; // Smaller width
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
  background-color: #fff;
  height: 325px;
  border-radius: 8px;
  text-align: start;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px; // Reduced padding
  font-family: "Poppins", sans-serif;
`;

const Button = styled.button`
  padding: 3px 6px; // Smaller buttons
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  border: none;
  border-radius: 3px;
`;

const Day = styled.div`
  display: inline-block;
  width: calc(100% / 7);
  font-family: "Poppins", sans-serif;
  padding: 15px 10px;
  color: ${({ hasEvents }) =>
    hasEvents ? "white " : "#000"}; // Set color based on events
  cursor: ${({ hasEvents }) =>
    hasEvents ? "pointer" : ""}; // Set color based on events
  position: relative;
  min-height: 1rem; // Ensure enough space for the number and event indicator
  font-size: 15px;

  @media (max-width: 400px) {
    width: 14%;
  }
`;

const DateDisplay = styled.span`
  display: block;
  position: relative;
  z-index: 2; // Ensure the date is above the event indicator
  font-family: "Poppins", sans-serif;
`;

const EventIndicator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15%;
  left: 2%;
  right: 0;
  bottom: 0;
  border-radius: 50rem;
  background-color: lightblue; // Use a semi-transparent color
  color: white;
  z-index: 1;
  width: 35px;
  height: 35px;
  background-color: #438ef2;
  font-family: "Poppins", sans-serif;
`;

const EventDetails = styled.div`
  display: none;
  position: absolute;
  font-family: "Poppins", sans-serif;
  /* background-color: white; */
  /* border: 1px solid #ddd; */
  padding: 5px;
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  z-index: 3;
  background-color: #2d78db;
  color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  p:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  ${Day}:hover & {
    height: max-content;
    display: block;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }
`;
const EventDetailsP = styled.p``;

const Calendar = ({ events }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);

  const eachDay = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const renderDays = () => {
    return eachDay.map((day) => {
      const dayEvents = events.filter((event) =>
        isSameDay(new Date(event.date), day)
      );

      return (
        <Day
          key={day.toString()}
          hasEvents={dayEvents.length > 0}>
          <DateDisplay>{format(day, "dd")}</DateDisplay>
          {dayEvents.length > 0 && <EventIndicator />}
          <EventDetails>
            {dayEvents.map((event, index) => (
              <p key={index}>{event.title}</p>
            ))}
          </EventDetails>
        </Day>
      );
    });
  };

  return (
    <CalendarContainer>
      <CalendarHeader>
        <div>{format(currentMonth, "MMMM yyyy")}</div>
        <div>
          <Button onClick={prevMonth}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.0005 5.66667C14.2265 5.66667 14.4535 5.74267 14.6405 5.89867C15.0645 6.25167 15.1215 6.88268 14.7685 7.30667L10.2925 12.6777L14.6075 18.0397C14.9535 18.4697 14.8855 19.0997 14.4555 19.4457C14.0245 19.7917 13.3955 19.7237 13.0485 19.2937L8.22051 13.2937C7.92251 12.9227 7.92651 12.3927 8.23151 12.0267L13.2315 6.02668C13.4295 5.78967 13.7135 5.66667 14.0005 5.66667Z"
                fill="#374151"
              />

              <g mask="url(#mask0_3021_4447)"></g>
            </svg>
          </Button>
          <Button onClick={nextMonth}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.9995 19.6668C9.7735 19.6668 9.5465 19.5908 9.3595 19.4348C8.9355 19.0818 8.8785 18.4508 9.2315 18.0268L13.7075 12.6558L9.3925 7.29382C9.0465 6.86382 9.1145 6.23382 9.5445 5.88782C9.9755 5.54182 10.6045 5.60982 10.9515 6.03982L15.7795 12.0398C16.0775 12.4108 16.0735 12.9408 15.7685 13.3068L10.7685 19.3068C10.5705 19.5438 10.2865 19.6668 9.9995 19.6668Z"
                fill="#374151"
              />

              <g mask="url(#mask0_3021_80)"></g>
            </svg>
          </Button>
        </div>
      </CalendarHeader>
      <div>{renderDays()}</div>
    </CalendarContainer>
  );
};

export default Calendar;
