import { useState } from "react";
import Input from "./Input";
import axios from "../../api/axios";
import Select from "./Select";
import { listJob } from "../../utils";
import { useStateContext } from "../../context/CreateContext";

const Experience = ({
  JopTitle,
  JopLevel,
  setJopTitle,
  setJopTitletValid,
  jopTitleValid,
  experienceValid,
  setExperienceValid,
  setExperience,
  experience,
}) => {
  const [level, setLevel] = useState(JopLevel);
  const [levelValid, setLevelValid] = useState(true);
  const [edit, setEdit] = useState(true);
  const { idUser } = useStateContext();

  const handleSubmit = () => {
    // Check for errors
    if (!JopTitle) {
      setJopTitletValid(false);
    }
    if (!level) {
      setLevelValid(false);
    }
    if (!experience) {
      setExperienceValid(false);
    }

    if (
      JopTitle &&
      level &&
      experience &&
      levelValid &&
      jopTitleValid &&
      experienceValid
    ) {
      // Your form submission logic here
      updateUserExp();
    }
  };
  const updateUserExp = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("jobTitle", JopTitle);
    bodyFormData.append("jobLevel", JopLevel);
    bodyFormData.append("expYears", experience);

    try {
      const res = await axios.put(`/api/teachers/${idUser}/exp`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setEdit(true);
    } catch (err) {
      const {} = err.response.data;

      console.log(err);
    }
  };
  return (
    <div className="lg:px-4 lg:py-4">
      <div className="w-full mt-3 flex flex-col max-h-[430px] bg-[#E5E7EB] lg:px-0 lg:py-0 py-4 px-4  rounded-[10px] lg:bg-[#F5F5F5]">
        <Input
          title={"Job Title"}
          value={JopTitle}
          onChange={setJopTitle}
          valid={jopTitleValid}
          setValid={setJopTitletValid}
          edit={edit}
        />
        <Select
          title={"Job Level"}
          value={level}
          onChange={setLevel}
          valid={levelValid}
          setValid={setLevelValid}
          edit={edit}
          listSelect={listJob}
        />
        <Input
          title={"Experience Year"}
          value={experience}
          onChange={setExperience}
          valid={experienceValid}
          setValid={setExperienceValid}
          edit={edit}
        />
      </div>
      {!edit ? (
        <div className="flex items-center gap-4">
          <button
            className="w-1/2 h-[42px] bg-[#0455BA] rounded-[4px] text-white text-lg mt-6"
            onClick={handleSubmit}
            id="ExperienceSave">
            save
          </button>
          <button
            className="w-1/2 h-[42px] border text-black rounded-[4px] border-[#9CA3AF] text-lg mt-6"
            onClick={() => setEdit(true)}
            id="ExperienceSancle">
            cancle
          </button>
        </div>
      ) : (
        <button
          className="lg:w-[141px] w-full lg:h[41px] h-[50px] bg-[#0455BA] rounded-[4px] text-white text-2xl lg:text-lg mt-3"
          onClick={() => setEdit(false)}
          id="ExperienceUpdate">
          Update
        </button>
      )}
    </div>
  );
};
export default Experience;
