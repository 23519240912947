import React, { useState } from "react";
import {
  useEmailValidation,
  useNameInput,
  usePassValidation,
  useSelectValidation,
  useTitleValidation,
} from "../hooks";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { countryList, listJob, listLanguage } from "../utils";
import HorizontalLinearAlternativeLabelStepper from "../components/Strpper";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/axios";
const SignUp = () => {
  const naviget = useNavigate();
  const [restPassword, setRestPassword] = useState(false);
  const [fristName, fristNameValid, setfristNameValid, handleFristNameChange] =
    useNameInput();
  const [lastName, lastNameValid, setlastNameValid, handleLastNameChange] =
    useNameInput();
  const [email, emailValid, setEmailValid, handleChangeEmail] =
    useEmailValidation();
  const [activeStep, setActiveStep] = useState(0);
  const [jobTitle, jobTitleValid, setJobTitleValid, handleChangeJopTitle] =
    useTitleValidation();
  const [language, languageValid, setLanguageValid, handleLanguageChange] =
    useSelectValidation();
  const [jobLevel, jobLevelValid, setJobLevelValid, handleJobLevelChange] =
    useSelectValidation();
  const [country, countryValid, setCountryValid, handleCountryChange] =
    useSelectValidation();
  const [password, passwordValid, setPasswordValid, handlePasswordChange] =
    usePassValidation();
  const [about, aboutValid, setAboutValid, handleAboutChange] =
    useTitleValidation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [experience, setExperience] = useState("2");
  const [image, setImage] = useState(null);
  const [passMatch, setPassMatch] = useState("");
  const [passMatchValid, setPassMatchValid] = useState(true);
  const [done, setDone] = useState(false);
  const [emailExsist, setEmailExsist] = useState(false);
  useEffect(() => {
    if (passMatch === password) {
      setPassMatchValid(true);
    } else {
      setPassMatchValid(false);
    }
  }, [passMatch, password]);

  const createAcount = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("firstName", fristName);
    bodyFormData.append("lastName", lastName);
    bodyFormData.append("password", password);
    bodyFormData.append("email", email);
    bodyFormData.append("country", country);
    bodyFormData.append("language", language);
    bodyFormData.append("phoneNo", phoneNumber);
    bodyFormData.append("jobTitle", jobTitle);
    bodyFormData.append("jobLevel", jobLevel);
    bodyFormData.append("about", "test");
    bodyFormData.append("expYears", experience);
    image && bodyFormData.append("image", image, image?.name);
    try {
      const res = await axios.post(`auth/teacher-signup`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setDone(true);
    } catch (err) {
      console.log(err);
    }
  };

  const emailVerification = async (email) => {
    try {
      const res = await axios.get("auth/verify", {
        params: { email },
      });

      setDone(true);
    } catch (err) {
      console.log(err);
    }
  };
  const Naviget = () => {
    naviget("/login");
  };

  useEffect(() => {
    // Create an AbortController instance
    const abortController = new AbortController();

    const checkEmail = async () => {
      try {
        const res = await axios.get("auth/check-email", {
          params: { email },
          signal: abortController.signal, // Pass the signal to the request
        });

        setEmailExsist(res.data);
      } catch (err) {
        // Handle only if it's not an abort error
        if (err.name !== "AbortError") {
          console.log(err);
        }
      }
    };

    checkEmail();

    // Cleanup function to abort the request on component unmount
    return () => {
      abortController.abort();
    };
  }, [email]);

  return (
    <div className="w-full h-screen flex items-center ">
      {done ? (
        <EmialConfirm
          onCancel={Naviget}
          email={email}
        />
      ) : (
        <>
          <div className="w-1/2 hidden bg-[#0455BA] h-full lg:flex items-center flex-col justify-center px-[120px]">
            <h2 className="text-[50px] font-bold text-[#FFF]">
              Welcome Back To
            </h2>
            <h1 className="text-[50px] font-semibold text-[#FFF]">
              E- Learning <span className="text-[#1F2937]">System</span>
            </h1>
            <LoginButton />
          </div>
          <div className="lg:w-1/2 w-full lg:px-[120px] px-[40px] h-full py-10">
            <div className="w-full lg:mb-5">
              <HorizontalLinearAlternativeLabelStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </div>
            {activeStep === 0 && (
              <CreateAccountFormName
                fristName={fristName}
                fristNameValid={fristNameValid}
                setFirstName={handleFristNameChange}
                lastName={lastName}
                lastNameValid={lastNameValid}
                setLastName={handleLastNameChange}
                email={email}
                emailValid={emailValid}
                setEmail={handleChangeEmail}
                password={password}
                setPassword={handlePasswordChange}
                passwordValid={passwordValid}
                setPassMatch={setPassMatch}
                passMatch={passMatch}
                passMatchValid={passMatchValid}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setfristNameValid={setfristNameValid}
                setlastNameValid={setlastNameValid}
                setEmailValid={setEmailValid}
                setPasswordValid={setPasswordValid}
                setPassMatchValid={setPassMatchValid}
                emailExsist={emailExsist}
              />
            )}
            {activeStep === 1 && (
              <CreateAccountForm
                jobTitle={jobTitle}
                jobTitleValid={jobTitleValid}
                handleChangeJopTitle={handleChangeJopTitle}
                language={language}
                setLanguage={handleLanguageChange}
                languageValid={languageValid}
                setLanguageValid={setLanguageValid}
                jobLevel={jobLevel}
                setJopLevel={handleJobLevelChange}
                jobLevelValid={jobLevelValid}
                setJobLevelValid={setJobLevelValid}
                country={country}
                setCountry={handleCountryChange}
                countryValid={countryValid}
                setCountryValid={setCountryValid}
                setActiveStep={setActiveStep}
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
                setJobTitleValid={setJobTitleValid}
              />
            )}

            {activeStep === 2 && (
              <AddPhoto
                createAcount={createAcount}
                setActiveStep={setActiveStep}
                image={image}
                setImage={setImage}
              />
            )}

            <p className="text-sm font-semibold text-center mt-5 lg:hidden">
              Already have an account?{" "}
              <Link
                to={"/login"}
                className="w-full">
                <span className="text-[#0455BA]">Log In</span>
              </Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;

const LoginButton = () => {
  return (
    <Link
      to={"/login"}
      className="w-full">
      <button className="w-full h-[61px] mt-10 rounded-md border border-white text-white text-[22px] font-semibold hover:bg-white transition-all duration-100 hover:text-black">
        Log In
      </button>
    </Link>
  );
};

const CreateAccountFormName = ({
  fristName,
  fristNameValid,
  setFirstName,
  lastName,
  lastNameValid,
  setLastName,
  email,
  emailValid,
  setEmail,
  password,
  setPassword,
  passwordValid,
  passMatch,
  setPassMatch,
  passMatchValid,
  activeStep,
  setActiveStep,
  setfristNameValid,
  setlastNameValid,
  setEmailValid,
  setPasswordValid,
  setPassMatchValid,
  emailExsist,
}) => {
  const [valid, setvalid] = useState(true);

  const handleContinue = () => {
    if (!email) {
      setEmailValid(false);
      setvalid(false);
    }
    if (!fristName) {
      setfristNameValid(false);
      setvalid(false);
    }
    if (!lastName) {
      setlastNameValid(false);
      setvalid(false);
    }
    if (!password) {
      setPasswordValid(false);
      setPassMatchValid(false);
      setvalid(false);
    }
    if (
      email &&
      fristName &&
      lastName &&
      password &&
      emailValid &&
      fristNameValid &&
      lastNameValid &&
      passwordValid &&
      !emailExsist &&
      passMatchValid &&
      passMatch
    ) {
      setvalid(true);
      setActiveStep(1);
    }
  };
  useEffect(() => {
    if (
      email &&
      fristName &&
      lastName &&
      password &&
      emailValid &&
      fristNameValid &&
      lastNameValid &&
      passwordValid &&
      !emailExsist &&
      passMatchValid
    ) {
      setvalid(true);
    }
  });
  return (
    <div>
      <div className="w-full lg:max-h-[700px] pt-8  ">
        <h2 className="text-[48px] font-bold text-[#0455BA] text-center">
          Create Account
        </h2>
        <div className="bg-[#E5E7EB] px-4 py-4 lg:px-0 lg:py-0 rounded-[10px] mt-5 lg:bg-[#F5F5F5]">
          <Input
            title={"First Name"}
            value={fristName}
            valid={fristNameValid}
            onChange={setFirstName}
          />
          <Input
            title={"Last Name"}
            value={lastName}
            valid={lastNameValid}
            onChange={setLastName}
          />
          <Input
            title={"E-mail"}
            value={email}
            valid={emailValid}
            onChange={setEmail}
          />
          {emailExsist && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">
                This email is already in use
              </p>
            </div>
          )}
          <Input
            title={"Password"}
            value={password}
            onChange={setPassword}
            valid={passwordValid}
            type={"password"}
          />
          <Input
            title={"Confirm Password"}
            value={passMatch}
            onChange={setPassMatch}
            valid={passMatchValid}
            type={"password"}
          />
        </div>
      </div>
      <ButtonStepper
        activeStep={activeStep}
        handleContinue={handleContinue}
        valid={valid}
        setp={activeStep}
      />
    </div>
  );
};
const CreateAccountForm = ({
  handleChangeJopTitle,
  jobTitle,
  jobTitleValid,
  language,
  setLanguage,
  languageValid,
  setLanguageValid,
  jobLevel,
  setJopLevel,
  jobLevelValid,
  setJobLevelValid,
  country,
  setCountry,
  countryValid,
  setActiveStep,
  setCountryValid,
  setPhoneNumber,
  phoneNumber,
  setJobTitleValid,
}) => {
  const [valid, setvalid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [phoneExsist, setPhoneExsist] = useState(false);
  const handleContinue = () => {
    if (!jobTitle) {
      setJobTitleValid(false);
    }
    if (!language) {
      setLanguageValid(false);
    }
    if (!jobLevel) {
      setJobLevelValid(false);
    }
    if (!country) {
      setCountryValid(false);
    }
    if (phoneNumber.length < 3) {
      setPhoneValid(false);
    }

    if (
      country &&
      jobTitle &&
      jobLevel &&
      language &&
      phoneValid &&
      countryValid &&
      jobLevelValid &&
      jobTitleValid &&
      phoneNumber
    ) {
      setvalid(true);
      setActiveStep(2);
    } else {
      setvalid(false);
    }
  };

  useEffect(() => {
    if (phoneNumber.length > 3) {
      setPhoneValid(true);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      country &&
      jobTitle &&
      jobLevel &&
      language &&
      phoneValid &&
      countryValid &&
      jobLevelValid &&
      jobTitleValid &&
      phoneNumber
    ) {
      setvalid(true);
    }
  }, []);

  useEffect(() => {
    // Create an AbortController instance
    const abortController = new AbortController();

    const checkEmail = async () => {
      try {
        const res = await axios.get("auth/check-phone", {
          params: { phone: phoneNumber },
          signal: abortController.signal, // Pass the signal to the request
        });

        setPhoneExsist(res.data);
      } catch (err) {
        // Handle only if it's not an abort error
        if (err.name !== "AbortError") {
          console.log(err);
        }
      }
    };

    checkEmail();

    // Cleanup function to abort the request on component unmount
    return () => {
      abortController.abort();
    };
  }, [phoneNumber]);
  return (
    <>
      <div className="w-full max-h-[700px] pt-8 flex-col gap-3">
        <h2 className="text-[48px] font-bold text-[#0455BA] text-center">
          Create Account
        </h2>
        <div className="bg-[#E5E7EB] px-4 py-4 lg:px-0 lg:py-0 rounded-[10px] mt-5 lg:bg-[#F5F5F5]">
          <div className="lg:mt-5">
            <p className="font-semibold text-[#374151] mb-1">Phone Number</p>
            <PhoneInput
              country={"eg"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
            />
            {!phoneValid && (
              <div className="flex items-center mt-1 gap-2">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                    fill="#DD0303"
                  />

                  <g mask="url(#mask0_972_11061)"></g>
                </svg>
                <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid Phone Number`}</p>
              </div>
            )}
            {phoneExsist && (
              <div className="flex items-center mt-1 gap-2">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                    fill="#DD0303"
                  />

                  <g mask="url(#mask0_972_11061)"></g>
                </svg>
                <p className="text-[12px] text-[#DD0303] font-semibold">{`This phone is already used`}</p>
              </div>
            )}
          </div>

          <SelectCountrey
            title={"Country"}
            listSelect={countryList}
            value={country}
            onChange={setCountry}
            valid={countryValid}
          />
          <Select
            title={"Langauge"}
            listSelect={listLanguage}
            value={language}
            onChange={setLanguage}
            valid={languageValid}
          />
          <Input
            title={"Job Title"}
            onChange={handleChangeJopTitle}
            valid={jobTitleValid}
            value={jobTitle}
          />
          <Select
            title={"Job Level"}
            listSelect={listJob}
            value={jobLevel}
            valid={jobLevelValid}
            onChange={setJopLevel}
          />
        </div>
      </div>
      <ButtonStepper
        handleContinue={handleContinue}
        valid={valid}
        onBack={setActiveStep}
      />
    </>
  );
};

const CreateCategory = ({ setActiveStep }) => {
  return (
    <div>
      <h2 className="text-[48px]   font-bold text-[#0455BA] text-center w-full mb-5">
        Pick Your Category
      </h2>
      <Category />
      <Category />
      <Category />
      <Category />
      <Category />
      <Category />
      <ButtonStepper
        handleContinue={() => setActiveStep(3)}
        onBack={setActiveStep}
      />
    </div>
  );
};

const Category = () => {
  return (
    <div className="bg-white h-[55px] rounded-[10px] flex items-center px-3 gap-2 mb-5">
      <label class="custom-checkbox flex items-center gap-3 text-[#fff] text-lg font-semibold">
        <input type="checkbox" />
        <span class="checkmark"></span>
      </label>
      <p className="text-lg text-[#374151]">The First Interested</p>
    </div>
  );
};

const AddPhoto = ({ createAcount, setActiveStep, image, setImage }) => {
  const [uploadPhoto, setUploadPhoto] = useState(false);
  const [valid, setValid] = useState(false);
  const [accept, setAccept] = useState(false);
  useEffect(() => {
    if (image && accept) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [image, accept]);

  return (
    <>
      <div className="flex flex-col items-center gap-3  lg:h-[700px] pt-8">
        {image ? (
          <img
            src={URL.createObjectURL(image)}
            alt=""
            className="lg:w-[312px] lg:h-[312px] w-[255px] h-[255px] rounded-full"
          />
        ) : (
          <svg
            viewBox="0 0 312 312"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="lg:w-[312px] lg:h-[312px] w-[255px] h-[255px]">
            <rect
              width="312"
              height="312"
              rx="156"
              fill="#0455BA"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M200.334 100.583C200.334 125.033 180.45 144.917 156 144.917C131.55 144.917 111.667 125.033 111.667 100.583C111.667 76.1335 131.55 56.25 156 56.25C180.45 56.25 200.334 76.1335 200.334 100.583ZM233.584 244.667C233.584 250.785 228.629 255.75 222.5 255.75H89.5003C83.3712 255.75 78.417 250.785 78.417 244.667C78.417 201.885 113.23 167.083 156 167.083C198.771 167.083 233.584 201.885 233.584 244.667Z"
              fill="white"
            />

            <g mask="url(#mask0_931_32766)"></g>
          </svg>
        )}

        <div className="flex flex-col items-center relative">
          {uploadPhoto && (
            <>
              <div
                className="w-full h-full z-10 fixed bottom-0 left-0  overview"
                onClick={() => setUploadPhoto(false)}></div>
              <UpdatedPhoto
                setUploadPhoto={setUploadPhoto}
                // changeData={setChangeData}
                setFile={setImage}
                file={image}
              />
            </>
          )}
          <h2 className="lg:text-[49px] text-[40px] font-medium text-[#0455BA] border-b border-[#0455BA] text-center pb-2">
            Add profile Photo
          </h2>
          <h3 className="lg:text-[24px] text-lg font-semibold text-[#0455BA] text-center pt-2">
            JPG or PNG no larger than 5 MB
          </h3>
          <button
            className="lg:w-[158px] w-full h-[39px] rounded-[4px] bg-[#0455BA] text-white font-semibold mt-4"
            onClick={() => setUploadPhoto(true)}>
            Add Photo
          </button>
          <div className="flex gap-2 items-center mt-5">
            <label class="custom-checkbox flex items-center gap-3 text-[#fff] text-lg font-semibold">
              <input
                type="checkbox"
                onChange={(e) => setAccept(e.target.checked)}
              />
              <span class="checkmark"></span>
            </label>
            <p className="text-lg text-[#374151]">
              you are accepting our{" "}
              <span className="text-[#0455BA] font-semibold">
                Terms & Policies
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
      <ButtonStepper
        handleContinue={createAcount}
        onBack={setActiveStep}
        valid={valid}
      />
    </>
  );
};

const Input = ({ title, placeHolder, onChange, value, valid, type }) => {
  const [showPass, setshowPass] = useState(true);

  return (
    <div className="mt-3">
      <p className="font-semibold text-[#374151]">{title}</p>
      <div
        className={`h-[50px] ${
          valid ? "border border-[#9CA3AF] " : "border-2 border-[#DD0303] "
        }  rounded-[4px] mt-1 flex items-center bg-white px-2`}>
        <input
          type={showPass ? type : "text"}
          className="w-full h-full rounded-[4px] pl-1 placeholder:text-[#6B7280] outline-none"
          placeholder={placeHolder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          id={title}
        />

        {type === "password" && (
          <>
            {showPass ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.407 17.993L20.707 19.293C21.098 19.684 21.098 20.316 20.707 20.707C20.512 20.902 20.256 21 20 21C19.744 21 19.488 20.902 19.293 20.707L16.586 18L16 17.414L14.655 16.069L14.586 16L12.0837 13.4977C12.056 13.4992 12.0281 13.5 12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.9719 10.5008 11.944 10.5023 11.9163L7.283 8.69701L5.532 6.94601L3.293 4.70701C2.902 4.31601 2.902 3.68401 3.293 3.29301C3.684 2.90201 4.316 2.90201 4.707 3.29301L6.42 5.00601L7.885 6.47101L11.9163 10.5023C11.944 10.5008 11.9719 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.0281 13.4992 12.056 13.4977 12.0837L17.414 16L19.407 17.993ZM8.5928 5.76421L11.3898 8.56121C11.5888 8.52621 11.7908 8.50021 11.9998 8.50021C13.9298 8.50021 15.4998 10.0702 15.4998 12.0002C15.4998 12.2092 15.4738 12.4112 15.4388 12.6102L18.1208 15.2932L18.9548 16.1272C20.4768 14.7392 21.4528 13.2202 21.8678 12.4972C22.0438 12.1892 22.0438 11.8102 21.8678 11.5022C21.2298 10.3902 17.7058 4.81621 11.7298 5.00321C10.5458 5.03421 9.4738 5.28921 8.5018 5.67421L8.5928 5.76421ZM13.948 16.7771L15.293 18.1211L15.498 18.3261C14.526 18.7111 13.455 18.9661 12.271 18.9961C12.189 18.9991 12.106 19.0001 12.025 19.0001C6.162 19.0001 2.762 13.5951 2.132 12.4971C1.956 12.1891 1.956 11.8101 2.132 11.5021C2.547 10.7801 3.523 9.26111 5.045 7.87311L6.576 9.40511L8.562 11.3901C8.526 11.5891 8.5 11.7911 8.5 12.0001C8.5 13.9301 10.07 15.5001 12 15.5001C12.209 15.5001 12.411 15.4741 12.61 15.4381L13.879 16.7071L13.948 16.7771Z"
                  fill="#6B7280"
                  onClick={() => setshowPass(false)}
                />

                <g mask="url(#mask0_1_4369)"></g>
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setshowPass(true)}
                className="cursor-pointer">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.729 5.00264C17.706 4.81564 21.229 10.3896 21.868 11.5016C22.044 11.8106 22.044 12.1886 21.868 12.4976C21.014 13.9856 17.798 18.8556 12.271 18.9966C12.188 18.9986 12.106 18.9996 12.025 18.9996C6.162 18.9996 2.762 13.5946 2.132 12.4976C1.956 12.1886 1.956 11.8106 2.132 11.5016C2.986 10.0136 6.202 5.14364 11.729 5.00264ZM12 15.4996C10.07 15.4996 8.5 13.9296 8.5 11.9996C8.5 10.0696 10.07 8.49964 12 8.49964C13.93 8.49964 15.5 10.0696 15.5 11.9996C15.5 13.9296 13.93 15.4996 12 15.4996ZM12 10.4996C11.173 10.4996 10.5 11.1726 10.5 11.9996C10.5 12.8266 11.173 13.4996 12 13.4996C12.827 13.4996 13.5 12.8266 13.5 11.9996C13.5 11.1726 12.827 10.4996 12 10.4996Z"
                  fill="#6B7280"
                />
                <g mask="url(#mask0_837_12098)"></g>
              </svg>
            )}
          </>
        )}
      </div>
      {title === "Confirm Password" ? (
        <>
          {!valid && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">
                {value
                  ? "Mismatching Passwords"
                  : `Please, Enter a valid ${title}`}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {!valid && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Select = ({ title, value, valid, onChange, listSelect }) => {
  const [showSelect, setShowSelect] = useState(false);

  return (
    <div className="mt-2 relative">
      <p className="font-semibold text-[#374151]">{title}</p>
      <div
        className={`flex items-center justify-between h-[50px] border pl-3 cursor-pointer ${
          valid ? "border-[#9CA3AF]" : "border-2 border-[#DD0303] "
        }  rounded-[4px] px-2 bg-white mt-1 relative`}
        onClick={() => setShowSelect((e) => !e)}>
        {value ? (
          <p className="text-sm text-[#374151] font-semibold">{value}</p>
        ) : (
          <p className="text-[#6B7280]">Choose your {title} </p>
        )}
        {showSelect ? (
          <IoIosArrowUp
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => setShowSelect(true)}
          />
        ) : (
          <IoIosArrowDown
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => setShowSelect(false)}
          />
        )}
        {showSelect && (
          <div className="flex items-start justify-between flex-col max-h-[150px] overflow-y-auto w-full border border-[#9CA3AF]  bg-white mt-1 absolute  z-20 left-0 top-[93%]">
            {listSelect.map((lan) => (
              <div
                className="flex items-center  gap-2 hover:bg-blue-300 w-full py-3 px-3 duration-100 transition-all cursor-pointer"
                onClick={() => {
                  onChange(lan.value);
                  setShowSelect(true);
                }}>
                {lan?.icon}
                <p>{lan.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
        </div>
      )}
    </div>
  );
};
const SelectCountrey = ({ title, value, valid, onChange, listSelect }) => {
  const [showSelect, setShowSelect] = useState(false);

  return (
    <div className="mt-5 relative">
      <p className="font-semibold text-[#374151]">{title}</p>
      <div
        className={`flex items-center justify-between h-[50px] border ${
          valid ? "border-[#9CA3AF]" : "border-2 border-[#DD0303] "
        } rounded-[4px] px-2 bg-white mt-1 relative cursor-pointer`}
        onClick={() => setShowSelect((e) => !e)}>
        {value ? (
          <p className="text-sm text-[#374151] font-semibold">{value}</p>
        ) : (
          <p className="text-[#6B7280]">Choose your {title} </p>
        )}
        {showSelect ? (
          <IoIosArrowUp
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => setShowSelect(true)}
          />
        ) : (
          <IoIosArrowDown
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => setShowSelect(false)}
          />
        )}
        {showSelect && (
          <div className="flex items-start justify-between flex-col max-h-[150px] overflow-y-auto w-full border border-[#9CA3AF]  bg-white mt-1 absolute  z-20 left-0 top-[93%]">
            {listSelect.map((value) => (
              <div
                className="flex items-center  gap-2 hover:bg-blue-300 w-full py-3 px-3 duration-100 transition-all cursor-pointer"
                onClick={() => {
                  onChange(value);
                  setShowSelect(true);
                }}>
                <p>{value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
        </div>
      )}
    </div>
  );
};

const ButtonStepper = ({ onStep, valid, handleContinue, setp, onBack }) => {
  const decStep = () => {
    onBack?.((e) => (e > 0 ? e - 1 : 0));
  };
  const incStep = () => {
    onStep((e) => (e < 3 ? e + 1 : 3));
  };
  return (
    <div className="flex items-center w-full justify-between gap-10 mt-5 flex-col-reverse lg:flex-row">
      <button
        className={`w-full h-[61px] border-2 ${
          setp === 0
            ? "border-[#9CA3AF] text-[#9CA3AF]"
            : "border-[#0455BA] text-[#0455BA]"
        }  text-[22px] font-semibold rounded-md flex items-center justify-center gap-3`}
        onClick={decStep}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.25 15.5L8.25 12.5M8.25 12.5L11.25 9.5M8.25 12.5H16.25M3.25 12.5C3.25 11.3181 3.48279 10.1478 3.93508 9.05585C4.38738 7.96392 5.05031 6.97177 5.88604 6.13604C6.72177 5.30031 7.71392 4.63738 8.80585 4.18508C9.89778 3.73279 11.0681 3.5 12.25 3.5C13.4319 3.5 14.6022 3.73279 15.6942 4.18508C16.7861 4.63738 17.7782 5.30031 18.614 6.13604C19.4497 6.97177 20.1126 7.96392 20.5649 9.05585C21.0172 10.1478 21.25 11.3181 21.25 12.5C21.25 14.8869 20.3018 17.1761 18.614 18.864C16.9261 20.5518 14.6369 21.5 12.25 21.5C9.86305 21.5 7.57387 20.5518 5.88604 18.864C4.19821 17.1761 3.25 14.8869 3.25 12.5Z"
            stroke={setp === 0 ? "#9CA3AF" : "#0455BA"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Back
      </button>

      <button
        className={`w-full h-[61px] flex items-center justify-center gap-3  text-[22px] font-semibold rounded-md ${
          valid
            ? "bg-[#0455BA] text-[#fff]"
            : "  border-2 border-[#9CA3AF] text-[#9CA3AF]"
        } `}
        onClick={handleContinue}>
        Continue
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.25 12.5C2.25 6.986 6.736 2.5 12.25 2.5C17.764 2.5 22.25 6.986 22.25 12.5C22.25 18.014 17.764 22.5 12.25 22.5C6.736 22.5 2.25 18.014 2.25 12.5ZM12.25 20.5C7.839 20.5 4.25 16.911 4.25 12.5C4.25 8.089 7.839 4.5 12.25 4.5C16.661 4.5 20.25 8.089 20.25 12.5C20.25 16.911 16.661 20.5 12.25 20.5ZM17.249 12.5054C17.249 12.5034 17.25 12.5024 17.25 12.5004C17.25 12.4822 17.2451 12.4658 17.2403 12.4495C17.2363 12.4361 17.2323 12.4228 17.231 12.4084C17.223 12.3124 17.211 12.2164 17.174 12.1264C17.1536 12.0749 17.1222 12.0314 17.0904 11.9873L17.0904 11.9873C17.0802 11.9731 17.0699 11.959 17.06 11.9444C17.0477 11.9269 17.0376 11.9083 17.0275 11.8897C17.0122 11.8616 16.9969 11.8335 16.974 11.8094L14.111 8.8094C13.729 8.4094 13.097 8.3954 12.697 8.7764C12.298 9.1574 12.283 9.7904 12.664 10.1904L13.914 11.5004H8.25C7.698 11.5004 7.25 11.9474 7.25 12.5004C7.25 13.0524 7.698 13.5004 8.25 13.5004H13.836L12.543 14.7934C12.152 15.1834 12.152 15.8164 12.543 16.2074C12.738 16.4024 12.994 16.5004 13.25 16.5004C13.506 16.5004 13.762 16.4024 13.957 16.2074L16.957 13.2074C17.048 13.1164 17.122 13.0064 17.172 12.8854C17.223 12.7644 17.248 12.6354 17.249 12.5054Z"
            fill={valid ? "white" : "#9CA3AF"}
          />

          <g mask="url(#mask0_931_31753)"></g>
        </svg>
      </button>
    </div>
  );
};
const UpdatedPhoto = ({ id, setUploadPhoto, changeData, setFile, file }) => {
  const [valid, setValid] = useState(true);

  const HandleUpload = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check file type
      const fileType = file.type;
      if (
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg"
      ) {
        // Check file size (in bytes)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size <= maxSize) {
          // File is valid
          setFile(file);
          setValid(true);
          setFile(file);
          setUploadPhoto(false);
        } else {
          setValid(false);
        }
      } else {
        setValid(false);
      }
    }
  };

  const changeImage = async (file) => {};
  return (
    <div className="w-[352px] h-[171px] rounded-[10px] py-5 px-5 absolute bg-white z-20 top-[3%] flex-col right-5 flex justify-between items-center">
      <div>
        <h2 className="text-3xl text-[#1F2937] font-bold border-b pb-1">
          Update profile Photo
        </h2>
        <p
          className={`${
            valid ? "text-[#6B7280]" : "text-[#DD0303]"
          }  text-center pt-1`}>
          JPG or PNG no larger than 2 MB
        </p>
      </div>
      <div className="flex items-center justify-center">
        <input
          type="file"
          id="upload"
          hidden
          onChange={(e) => HandleUpload(e)}
        />
        <label
          for="upload"
          className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1 cursor-pointer">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
              fill="white"
            />

            <g mask="url(#mask0_2294_15588)"></g>
          </svg>
          Upload Photo
        </label>
      </div>
      {/* <button className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
            fill="white"
          />

          <g mask="url(#mask0_2294_15588)"></g>
        </svg>
        Upload Photo
      </button> */}
    </div>
  );
};

const EmialConfirm = ({ onCancel, email }) => {
  return (
    <div className="lg:w-[934px] w-[90%] lg:h-[464px] absolute py-5  left-1/2 z-10 reset__password rounded-xl shadow-xl flex flex-col items-center justify-center px-8 gap-5">
      <h2 className="lg:text-[48px] text-[40px] font-bold text-[#4B5563] text-center">
        Email Confirmation
      </h2>
      <p className="lg:text-2xl text-lg text-[#6B7280] text-center">
        we have sent email to <span className="text-[#468238]">{email}</span> to
        confirm the validity of our email address. After receiving the email you
        will go automaticto the dashboard
      </p>
      <div className="flex items-center gap-5  w-full justify-center">
        <button
          className="lg:w-[539px] w-full h-[61px] bg-[#0455BA] rounded-[10px] text-[22px] font-semibold text-white"
          onClick={onCancel}>
          Log In
        </button>
      </div>
    </div>
  );
};
