import React, { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import { useStateContext } from "../context/CreateContext";
import { SearchBar } from "../components";

const Transactions = () => {
  const { idUser } = useStateContext();
  const [transactions, setTransactions] = useState([]);
  const [filterdTransactions, setFilterTransactions] = useState([]);
  const [query, setQuery] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [openDateSave, setOpenDateSave] = useState("");
  const [deadlinSave, setDeadlinSave] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const getTransactrions = async () => {
      try {
        let queryString = `type=${type}&status=${status}&page=${currentPage}&size=8`;
        if (dateFilter) {
          const selectedDate = getDateRange(dateFilter);
          queryString += `&date=${selectedDate.toISOString().split("T")[0]}`;
        }

        const res = await axios.get(
          `api/teachers/transactions?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        setTransactions(res.data.content);
        setFilterTransactions(res.data.content);
        setTotalPages(res.data.totalPages);
      } catch (err) {
        console.log(err);
      }
    };

    getTransactrions();
  }, [type, status, dateFilter, currentPage]);

  useEffect(() => {
    let filterdTransaction = transactions.filter((transaction) => {
      const titleMatches = !query || transaction.transId === Number(query);
      const lastUpdateDate = new Date(transaction.dateTime);
      const startDate = openDateSave ? new Date(openDateSave) : null;
      const endDate = deadlinSave ? new Date(deadlinSave) : null;
      const lastUpdateInRange =
        startDate && endDate
          ? lastUpdateDate > startDate && lastUpdateDate < endDate
          : true;

      return titleMatches && lastUpdateInRange;
    });

    setFilterTransactions(filterdTransaction);
  }, [query, openDateSave, deadlinSave, transactions]);

  const handleTypeSelect = (selectedType) => setType(selectedType);
  const handleStatusSelect = (selectedStatus) => setStatus(selectedStatus);
  const handleDateFilterSelect = (selectedDateFilter) =>
    setDateFilter(selectedDateFilter);
  const clearFilters = () => {
    setType("");
    setStatus("");
    setDateFilter("");
    setQuery("");
  };
  const getDateRange = (filter) => {
    const today = new Date();
    switch (filter) {
      case "Last Week":
        return new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7
        );
      case "Month":
        return new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );
      case "6 Months":
        return new Date(
          today.getFullYear(),
          today.getMonth() - 6,
          today.getDate()
        );
      case "Year":
        return new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 justify-between">
        <SearchBar
          titleS={"Search by transaction ID"}
          width={"w-[150px]"}
          // onShow={setShowAddLesson}
          onSearch={setQuery}
          openDateSave={openDateSave}
          setOpenDateSave={setOpenDateSave}
          deadlinSave={deadlinSave}
          setDeadlinSave={setDeadlinSave}
          // setSearchBarMobile={setShowAddLesson}
          noFilter={true}
          mobile={true}
        />
        <div className="flex gap-1 mb-4 md:gap-4">
          <FilterDropdown
            label="Type"
            options={["WITHDRAW", "DEPOSIT"]}
            onSelect={handleTypeSelect}
          />
          <FilterDropdown
            label="Status"
            options={[
              "PENDING",
              "APPROVED",
              "DECLINED",
              "CANCELED",
              "COMPLETED",
            ]}
            onSelect={handleStatusSelect}
          />
          <FilterDropdown
            label="Date"
            options={["Last Week", "Month", "6 Months", "Year"]}
            onSelect={handleDateFilterSelect}
          />
          <button
            className="px-2 md:px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 max-w-content"
            onClick={clearFilters}>
            Clear Filter
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-6 mt-5 overflow-y-scroll h-[75vh]">
        <div className="flex items-center justify-between gap-2 px-2 border-[#2f5894] w-full min-h-[70px] bg-white rounded-lg shadow-md sticky top-0">
          <p className="text-md lg:text-lg flex-1 lg:flex-auto">ID</p>
          <p className="text-md lg:text-lg flex-1">Type</p>
          <p className="text-md lg:text-lg flex-1">Status</p>
          <p className="text-md lg:text-lg flex-1">Amount</p>
          <p className="text-md lg:text-lg flex-1">Date Time</p>
        </div>
        {filterdTransactions.length > 0 &&
          filterdTransactions.map((transaction) => (
            <div
              key={transaction.id}
              className="flex items-center justify-between gap-2 px-2 border-[#D1D5DB] w-full  max-h-[70px] min-h-[50px] bg-white rounded-lg shadow-md sm:gap-0">
              <p className="text-md lg:text-lg flex-1 lg:flex-auto">
                {transaction.transId}#
              </p>
              <p className="text-md lg:text-lg flex-1">
                {String(transaction.transType).charAt(0).toUpperCase() +
                  String(transaction.transType).slice(1).toLowerCase()}
              </p>
              <p className={`text-md lg:text-lg flex-1 `}>
                {String(transaction.status).charAt(0).toUpperCase() +
                  String(transaction.status).slice(1).toLowerCase()}
              </p>
              <p className="text-md lg:text-lg flex-1">{transaction.amount}$</p>
              <p className="text-md lg:text-lg flex-1">
                {new Date(transaction.dateTime).toDateString()}
              </p>
            </div>
          ))}
        {filterdTransactions.length === 0 && (
          <p className="text-center text-lg">No transactions found.</p>
        )}
      </div>
      <PaginationControls
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
      />
    </>
  );
};

export default Transactions;

const FilterDropdown = ({ label, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });

  return (
    <div className="relative inline-block text-left z-50">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}>
          {label}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          ref={ref}
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1">
          <div
            className="py-1"
            role="none">
            {options.map((option, index) => (
              <button
                key={index}
                className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                tabIndex="-1"
                onClick={() => {
                  onSelect(option);
                  setIsOpen(false);
                }}>
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const PaginationControls = ({ totalPages, currentPage, setCurrentPage }) => (
  <div className="flex justify-center items-center my-4">
    <button
      onClick={() => {
        if (currentPage > 0) setCurrentPage(Math.max(0, currentPage - 1));
      }}
      disabled={currentPage === 0}
      className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300">
      Previous
    </button>
    <span>
      Page {currentPage + 1} of {totalPages}
    </span>
    <button
      onClick={() => {
        if (currentPage < totalPages)
          setCurrentPage(Math.min(totalPages, currentPage + 1));
      }}
      disabled={currentPage + 1 === totalPages}
      className="px-6 py-2 ml-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300">
      Next
    </button>
  </div>
);
