import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { handleDate } from "../utils";
import dayjs from "dayjs";
const FilterByDate = ({
  showFilter,
  setshowFilter,
  onChangeShow,
  state,
  setState,
  openDateSave,
  setOpenDateSave,
  deadlinSave,
  setDeadlinSave,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const [showDate, setshowDate] = useState(false);
  const [ShowCalender, setShowCalender] = useState(false);
  const [showOpenDate, setShowOpenDate] = useState(false);
  const [showDeadline, setShowDeadline] = useState(false);
  const [showCalender, setshowCalender] = useState(false);
  const [openDate, setOpenDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [scheduleValid, setScheduleValid] = useState(true);
  const [dateToValid, setDateToValid] = useState(true);
  const [dateFromValid, setDateFromValid] = useState(true);
  const [select, setSelect] = useState("All Time");

  const handleDateSave = () => {
    onChangeShow(false);
  };

  const selectStyle =
    "text-[12px] text-[#374151] w-full font-semibold hover:bg-[#E5E7EB] hover:text-[#0455BA] py-2 px-2 cursor-pointer";

  const timeRanges = [
    {
      title: "Today",
      start: dayjs(),
      end: dayjs(),
    },
    {
      title: "Yesterday",
      start: dayjs().subtract(1, "day"),
      end: dayjs().subtract(1, "day"),
    },
    {
      title: "Last 3 Days",
      start: dayjs().subtract(3, "day"),
      end: dayjs(),
    },
    {
      title: "Last 7 Days",
      start: dayjs().subtract(7, "day"),
      end: dayjs(),
    },
    {
      title: "Last Month",
      start: dayjs().subtract(1, "month"),
      end: dayjs(),
    },
    {
      title: "All Time",
      start: null, // Set the appropriate start date or leave it as null
      end: dayjs(),
    },
  ];

  const HandleChangeDate = (start, end) => {
    const formattedDateStart = start ? handleDate(start) : start;
    const formattedDateEnd = handleDate(end);

    setOpenDateSave(formattedDateStart);
    setDeadlinSave(formattedDateEnd);
  };
  return (
    <>
      {showFilter && (
        <>
          <div className=" fixed lg:absolute left-0 w-full top-0 h-full lg:top-[0] z-20 lg:w-[350px]  lg:overflow-y-visible overflow-y-auto lg:bg-white lg:min-h-[220px] lg:h-fit px-5 lg:px-0 py-5 lg:py-0 bg-[#F5F5F5]  shadow-lg rounded-md flex flex-col justify-between">
            <div>
              <div className="w-full h-[39px] bg-[#1E69CB] lg:flex items-center justify-center lg:rounded-tl-md lg:rounded-tr-md hidden">
                <p className="text-[#F5F5F5] font-semibold">FilterByDate </p>
              </div>
              <div className="lg:hidden items-center justify-between mb-10 flex">
                <h2 className="text-[28px] font-semibold text-[#1E69CB]">
                  FilterByDate
                </h2>{" "}
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => onChangeShow(false)}
                  className="cursor-pointer">
                  <path
                    d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
                    fill="#374151"
                  />

                  <g mask="url(#mask0_1240_20873)"></g>
                </svg>
              </div>
              <div className="px-4 mt-3">
                <div className="">
                  <div
                    className="flex items-center justify-between h-[40px] lg:border border-b border-[#9CA3AF] lg:rounded-[4px] px-2 cursor-pointer"
                    onClick={() => setShowSelect((e) => !e)}>
                    <p className="text-sm text-[#374151] font-semibold">
                      {select}
                    </p>
                    {showSelect ? (
                      <IoIosArrowUp
                        className="w-[20px] h-[20px] cursor-pointer"
                        onClick={() => setShowSelect(true)}
                      />
                    ) : (
                      <IoIosArrowDown
                        className="w-[20px] h-[20px] cursor-pointer"
                        onClick={() => setShowSelect(false)}
                      />
                    )}
                  </div>
                  {showSelect && (
                    <div className="flex items-start justify-between h-[240px] border border-[#9CA3AF] rounded-[4px] s mt-5 flex-col bg-white">
                      {timeRanges.map((time) => (
                        <p
                          className={selectStyle}
                          onClick={() => {
                            setSelect(time.title);
                            HandleChangeDate(time.start, time.end);
                            setShowSelect(false);
                          }}>
                          {time.title}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <InputDate
                  title={"From"}
                  setShowCalender={setShowOpenDate}
                  onChange={setOpenDate}
                  date={openDate}
                  setOpenDateSave={setOpenDateSave}
                  dateSave={openDateSave}
                  valid={dateFromValid}
                  setValid={setDateFromValid}
                />
                <InputDate
                  title={"To"}
                  setShowCalender={setShowDeadline}
                  onChange={setDeadline}
                  date={deadline}
                  setOpenDateSave={setDeadlinSave}
                  dateSave={deadlinSave}
                  valid={dateToValid}
                  setValid={setDateToValid}
                />
                <div className=" hidden lg:block">
                  <Date
                    showOpenDate={setShowOpenDate}
                    showDeadline={setShowDeadline}
                    setShowCalender={setshowCalender}
                    openDate={openDateSave}
                    deadlinSave={deadlinSave}
                    setDeadlinSave={setDeadlinSave}
                  />
                  {showOpenDate && (
                    <Calender
                      setShowCalender={setShowOpenDate}
                      onChange={setOpenDate}
                      date={openDate}
                      setOpenDateSave={setOpenDateSave}
                    />
                  )}
                  {openDate && showDeadline && (
                    <Calender
                      setShowCalender={setShowDeadline}
                      onChange={setDeadline}
                      date={deadline}
                      setOpenDateSave={setDeadlinSave}
                      min={openDate ? openDate : new Date()}
                    />
                  )}
                </div>
              </div>
            </div>
            <Button
              onCancle={onChangeShow}
              onSave={handleDateSave}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FilterByDate;

const InputDate = ({
  title,
  onChange,
  value,
  valid,
  setValid,
  height,

  setOpenDateSave,
  date,
  dateSave,
}) => {
  useEffect(() => {
    if (date) {
      setValid(true);
    }
  }, [date]);
  const [showCalender, setshowCalender] = useState(false);

  return (
    <div className="mt-5 lg:hidden block">
      <>
        <p className="font-semibold text-[#374151] text-lg">{title}</p>
        <div
          className={`${height ? height : "h-[50px]"} ${
            valid ? "border border-[#9CA3AF] " : "border-2 border-[#DD0303] "
          }  rounded-[4px] mt-1 relative flex items-center justify-between px-4 bg-white`}>
          <p className="text-[#6B7280] text-xl font-semibold">
            {dateSave ? dateSave : "YYYY-MM-DD"}
          </p>
          {/* <DateRange
              showCalender={showCalender}
              setshowCalender={setshowCalender}
              openDate={openDate}
              setDeadline={setDeadline}
              deadline={deadline}
              setOpenDate={setOpenDate}
              openDateSave={openDateSave}
              setOpenDateSave={setOpenDateSave}
              deadlinSave={deadlinSave}
              setDeadlinSave={setDeadlinSave}
            /> */}
          {/* <Calender /> */}
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={() => setshowCalender(true)}>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.8333 4.66683H21C22.9297 4.66683 24.5 6.23716 24.5 8.16683V22.1668C24.5 24.0965 22.9297 25.6668 21 25.6668H7C5.07033 25.6668 3.5 24.0965 3.5 22.1668V8.16683C3.5 6.23716 5.07033 4.66683 7 4.66683H8.16667V3.50016C8.16667 2.8585 8.69167 2.3335 9.33333 2.3335C9.975 2.3335 10.5 2.8585 10.5 3.50016V4.66683H17.5V3.50016C17.5 2.8585 18.025 2.3335 18.6667 2.3335C19.3083 2.3335 19.8333 2.8585 19.8333 3.50016V4.66683ZM5.83333 12.8335H22.1667V8.16683C22.1667 7.524 21.6428 7.00016 21 7.00016H19.8333V8.16683C19.8333 8.8085 19.3083 9.3335 18.6667 9.3335C18.025 9.3335 17.5 8.8085 17.5 8.16683V7.00016H10.5V8.16683C10.5 8.8085 9.975 9.3335 9.33333 9.3335C8.69167 9.3335 8.16667 8.8085 8.16667 8.16683V7.00016H7C6.35717 7.00016 5.83333 7.524 5.83333 8.16683V12.8335ZM14 19.8335H18.6667C19.3083 19.8335 19.8333 19.3085 19.8333 18.6668C19.8333 18.0252 19.3083 17.5002 18.6667 17.5002H14C13.3583 17.5002 12.8333 18.0252 12.8333 18.6668C12.8333 19.3085 13.3583 19.8335 14 19.8335ZM9.33333 19.8335C8.69167 19.8335 8.16667 19.3085 8.16667 18.6668C8.16667 18.0252 8.69167 17.5002 9.33333 17.5002C9.975 17.5002 10.5 18.0252 10.5 18.6668C10.5 19.3085 9.975 19.8335 9.33333 19.8335Z"
              fill="#1E69CB"
            />

            <g mask="url(#mask0_1225_18340)"></g>
          </svg>
        </div>
        {showCalender && (
          <Calender
            setShowCalender={setshowCalender}
            onChange={onChange}
            date={date}
            setOpenDateSave={setOpenDateSave}
          />
        )}
      </>

      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title} Date`}</p>
        </div>
      )}
    </div>
  );
};

const DateRange = ({
  showFilter,
  setshowFilter,
  showCalender,
  setshowCalender,
  openDate,
  setOpenDate,
  deadline,
  setDeadline,
  openDateSave,
  setOpenDateSave,
  deadlinSave,
  setDeadlinSave,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const [showDate, setshowDate] = useState(false);
  const [showOpenDate, setShowOpenDate] = useState(false);
  const [showDeadline, setShowDeadline] = useState(false);

  const selectStyle =
    "text-[12px] text-[#374151] w-full font-semibold hover:bg-[#E5E7EB] hover:text-[#0455BA] py-2 px-2 cursor-pointer";
  return (
    <>
      {!showCalender && (
        <div className="absolute bottom-[20%] z-40 w-[50%] min-h-[200px] bg-white shadow-lg rounded-md flex flex-col justify-between right-0">
          <div className="w-full h-[39px] bg-[#1E69CB] flex items-center justify-center rounded-tl-md rounded-tr-md">
            <p className="text-[#F5F5F5] font-semibold">Schedule </p>
          </div>
          <div className="px-4 ">
            <Date
              showOpenDate={setShowOpenDate}
              showDeadline={setShowDeadline}
              setShowCalender={setshowCalender}
              openDate={openDateSave}
              deadlinSave={deadlinSave}
              setDeadlinSave={setDeadlinSave}
            />
            {showOpenDate && (
              <Calender
                setShowCalender={setShowOpenDate}
                onChange={setOpenDate}
                date={openDate}
                setOpenDateSave={setOpenDateSave}
              />
            )}
            {showDeadline && (
              <Calender
                setShowCalender={setShowDeadline}
                onChange={setDeadline}
                date={deadline}
                setOpenDateSave={setDeadlinSave}
              />
            )}
          </div>

          <Button onCancle={setshowCalender} />
        </div>
      )}
    </>
  );
};

const Date = ({
  setShowCalender,
  openDate,
  deadline,
  showDeadline,
  showOpenDate,
  deadlinSave,
}) => {
  return (
    <div className="flex items-center justify-between  gap-5 mt-6 mb-2">
      <div className="w-1/2">
        <div
          className="flex items-center justify-between h-[40px] border border-[#9CA3AF] rounded-[4px] px-2 cursor-pointer"
          onClick={() => {
            showDeadline(false);
            showOpenDate(true);
          }}>
          <p className="text-sm text-[#374151] font-semibold">From</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.20002 2.39999C6.88176 2.39999 6.57654 2.52642 6.3515 2.75147C6.12645 2.97651 6.00002 3.28173 6.00002 3.59999V4.79999H4.80002C4.1635 4.79999 3.55306 5.05285 3.10297 5.50294C2.65288 5.95302 2.40002 6.56347 2.40002 7.19999V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.897C3.55306 21.3471 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3471 20.8971 20.897C21.3472 20.447 21.6 19.8365 21.6 19.2V7.19999C21.6 6.56347 21.3472 5.95302 20.8971 5.50294C20.447 5.05285 19.8365 4.79999 19.2 4.79999H18V3.59999C18 3.28173 17.8736 2.97651 17.6486 2.75147C17.4235 2.52642 17.1183 2.39999 16.8 2.39999C16.4818 2.39999 16.1765 2.52642 15.9515 2.75147C15.7265 2.97651 15.6 3.28173 15.6 3.59999V4.79999H8.40002V3.59999C8.40002 3.28173 8.2736 2.97651 8.04855 2.75147C7.82351 2.52642 7.51828 2.39999 7.20002 2.39999ZM7.20002 8.39999C6.88176 8.39999 6.57654 8.52642 6.3515 8.75147C6.12645 8.97651 6.00002 9.28173 6.00002 9.59999C6.00002 9.91825 6.12645 10.2235 6.3515 10.4485C6.57654 10.6736 6.88176 10.8 7.20002 10.8H16.8C17.1183 10.8 17.4235 10.6736 17.6486 10.4485C17.8736 10.2235 18 9.91825 18 9.59999C18 9.28173 17.8736 8.97651 17.6486 8.75147C17.4235 8.52642 17.1183 8.39999 16.8 8.39999H7.20002Z"
              fill="black"
            />
          </svg>
        </div>
        <p className="text-[10px] font-semibold text-[#374151] mt-2 ml-2">
          {openDate ? openDate : "MM-DD-YYYY"}
        </p>
      </div>
      <div className="w-1/2">
        <div
          className="flex items-center justify-between h-[40px] border border-[#9CA3AF] rounded-[4px] px-2 cursor-pointer"
          onClick={() => {
            showDeadline(true);
            showOpenDate(false);
          }}>
          <p className="text-sm text-[#374151] font-semibold">To</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.20002 2.39999C6.88176 2.39999 6.57654 2.52642 6.3515 2.75147C6.12645 2.97651 6.00002 3.28173 6.00002 3.59999V4.79999H4.80002C4.1635 4.79999 3.55306 5.05285 3.10297 5.50294C2.65288 5.95302 2.40002 6.56347 2.40002 7.19999V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.897C3.55306 21.3471 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3471 20.8971 20.897C21.3472 20.447 21.6 19.8365 21.6 19.2V7.19999C21.6 6.56347 21.3472 5.95302 20.8971 5.50294C20.447 5.05285 19.8365 4.79999 19.2 4.79999H18V3.59999C18 3.28173 17.8736 2.97651 17.6486 2.75147C17.4235 2.52642 17.1183 2.39999 16.8 2.39999C16.4818 2.39999 16.1765 2.52642 15.9515 2.75147C15.7265 2.97651 15.6 3.28173 15.6 3.59999V4.79999H8.40002V3.59999C8.40002 3.28173 8.2736 2.97651 8.04855 2.75147C7.82351 2.52642 7.51828 2.39999 7.20002 2.39999ZM7.20002 8.39999C6.88176 8.39999 6.57654 8.52642 6.3515 8.75147C6.12645 8.97651 6.00002 9.28173 6.00002 9.59999C6.00002 9.91825 6.12645 10.2235 6.3515 10.4485C6.57654 10.6736 6.88176 10.8 7.20002 10.8H16.8C17.1183 10.8 17.4235 10.6736 17.6486 10.4485C17.8736 10.2235 18 9.91825 18 9.59999C18 9.28173 17.8736 8.97651 17.6486 8.75147C17.4235 8.52642 17.1183 8.39999 16.8 8.39999H7.20002Z"
              fill="black"
            />
          </svg>
        </div>
        <p className="text-[10px] font-semibold text-[#374151] mt-2 mr-2 text-end">
          {deadlinSave ? deadlinSave : "MM-DD-YYYY"}
        </p>
      </div>
    </div>
  );
};
const Calender = ({
  setShowCalender,
  date,
  onChange,
  setOpenDateSave,
  min,
}) => {
  const HandleChangeDate = (e) => {
    const formattedDate = handleDate(e);
    onChange(formattedDate);
    setOpenDateSave(formattedDate);
    setShowCalender(false);
  };

  // const HandleSave = () => {
  //   setOpenDateSave(date);
  //   setShowCalender(false);
  // };
  const minDate = min ? dayjs(min) : min;
  const [state, setState] = useState("");
  return (
    <div className="flex items-end justify-center border border-[#9CA3AF] rounded-[4px] mb-2 mt-1 flex-col overflow-hidden  pb-1 pt-1 font-semibold text-sm mt-4">
      <div className="flex items-center h-[300px] w-full justify-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar"]}>
            <DemoItem>
              <DateCalendar
                views={["year", "month", "day", "hours", "minutes"]}
                format="YYYY-MM-DD"
                onChange={HandleChangeDate}
                sx={{ width: "100%" }}
                minDate={minDate}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>

      {/* <div className="flex items-center gap-3 mr-6 mb-2 ">
        <button onClick={() => setShowCalender(false)}>Cancel</button>
        <button onClick={HandleSave}>ok</button>
      </div> */}
    </div>
  );
};

const Button = ({ onSave, onCancle }) => {
  return (
    <div className="flex items-center gap-3 justify-end flex-col-reverse lg:flex-row pr-5 lg:border-t sborder-[#37415175] pt-2 pb-2">
      <button
        onClick={() => {
          onCancle(false);
        }}
        className="text-2xl lg:text-[12px] lg:h-[30px] h-[40px] font-semibold w-full lg:w-fit border rounded-md lg:border-none border-[#9CA3AF]">
        Cancel
      </button>
      <button
        className="lg:w-[70px] w-full lg:h-[30px] h-[40px] bg-[#0455BA] text-white rounded-md text-2xl lg:text-[12px] font-semibold"
        onClick={onSave}>
        ok
      </button>
    </div>
  );
};
