const Input = ({
  title,
  placeHolder,
  onChange,
  value,
  valid,
  setValid,
  height,
  edit,
  padding,
}) => {
  // useEffect(() => {
  //   if (value) {
  //     setValid(true);
  //   }
  // }, [value]);

  return (
    <div
      className={`mt-5 bg-[#E5E7EB] lg:bg-[#F5F5F5]  lg:px-0 lg:py-0 rounded-[10px] ${padding}`}>
      {!edit ? (
        <>
          <p className="font-semibold text-[#374151]">{title}</p>
          <div
            className={`${height ? height : "h-[50px]"} ${
              valid ? "border border-[#9CA3AF] " : "border-2 border-[#DD0303]"
            }  rounded-[4px] mt-1`}>
            <input
              type="text"
              // type="text"
              className="w-full h-full rounded-[4px] px-5 placeholder:text-[#6B7280] outline-none"
              placeholder={placeHolder}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              id={title}
            />
          </div>
        </>
      ) : (
        <div>
          <h3 className="text-xl font-semibold text-[#374151]">{title}</h3>
          <p className="text-[#6B7280]">{value}</p>
        </div>
      )}

      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
        </div>
      )}
    </div>
  );
};

export default Input;
