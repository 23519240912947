import { useState } from "react";
import { useEffect } from "react";

const useListReqValidation = (intial) => {
  const [list, setList] = useState(intial ? intial : []);
  const [listValid, setListValid] = useState(true);

  const handleListChange = (e) => {
    const newList = e;
    setList(newList);
  };
  useEffect(() => {
    if (list.length > 0) {
      setListValid(true);
    }
  }, [list]);
  return [list, listValid, setListValid, handleListChange];
};
export default useListReqValidation;
