import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import { useEffect } from "react";

const xLabels = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export default function AreaCon({ incomeProgress, studentProgress }) {
  // const [incomeData, setIncomeData] = useState([]);
  // const [studentData, setIstudentData] = useState([]);
  // useEffect(() => {
  //   setIncomeData(incomeProgress);
  //   setIstudentData(studentProgress);
  // }, []);
  // const incomeData = incomeProgress.length > 0 ? incomeProgress : [0];
  // const studentData = studentProgress.length > 0 ? studentProgress : [0];

  return (
    <div className="w-full h-full md:px-4 py-4">
      <>
        <div className="flex items-center md:justify-between justify-center">
          <h3 className="text-[20px] font-semibold text-[#374151] md:block hidden">
            Analytics
          </h3>
          <div className="flex items-center gap-5 justify-center md:gap-20">
            <div className="flex items-center gap-2">
              <div className="w-[14px] h-[14px] bg-[#0455BA] rounded-full"></div>{" "}
              <p className="text-[12px] text-[#0455BA] font-semibold">
                Student
              </p>
              <p className="text-[12px] text-[#0455BA] font-semibold"></p>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[14px] h-[14px] bg-[#40A726] rounded-full"></div>{" "}
              <p className="text-[12px] text-[#40A726] font-semibold">Incom</p>{" "}
              <p className="text-[12px] text-[#40A726] font-semibold"></p>
            </div>
          </div>

          <div className="items-center gap-2 md:block hidden">
            <p className="text-xl">2024</p>
            {/* <IoIosArrowDown className="w-[20px] h-[20px]" /> */}
          </div>
        </div>
        <div
          className="w-full h-full"
          id="LineChart">
          <LineChart
            series={[
              {
                data: incomeProgress,
                label: "uv",
                area: true,
                stack: "total",
                showMark: false,
              },
              {
                data: studentProgress,
                label: "pv",
                area: true,
                stack: "total",
                showMark: false,
              },
            ]}
            legend={{ hidden: true }}
            xAxis={[{ scaleType: "point", data: xLabels }]}
            sx={{
              ".MuiLineElement-root": {
                display: "none",
              },
            }}
          />
        </div>
      </>
    </div>
  );
}

// <ResponsiveContainer width="100%" height="100%">
//   <AreaChart
//     data={data}
//     margin={{
//       top: 10,
//       right: 30,
//       left: 0,
//       bottom: 0,
//     }}
//   >
//     <CartesianGrid strokeDasharray="3 3" />
//     <XAxis dataKey="name" />
//     <YAxis />
//     <Tooltip />
//     <Area
//       type="monotone"
//       dataKey="uv"
//       stackId="1"
//       stroke="#8884d8"
//       fill="#8884d8"
//     />
//     <Area
//       type="monotone"
//       dataKey="pv"
//       stackId="1"
//       stroke="#82ca9d"
//       fill="#82ca9d"
//     />
//   </AreaChart>
// </ResponsiveContainer>
