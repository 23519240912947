import React, { useState } from "react";
import Notifications from "./Notifications";
import { Link, useLocation } from "react-router-dom";
import { useStateContext } from "../../context/CreateContext";
import { imageUrl } from "../../utils";

const Nav = () => {
  const [notifications, setNotifications] = useState(false);
  const { img, user, navActive, setNavActive } = useStateContext();

  const { pathname } = useLocation();

  return (
    <div className="flex h-[60px] w-[100%] items-center justify-between relative mt-3">
      <div className="inline-flex items-center justify-center relative flex-[0_0_auto] gap-3">
        <div className="relative w-fit mt-[-1.00px] text-[#338B3C] text-[22px] font-semibold hidden lg:block">
          Welcome back,{user}.
        </div>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer lg:hidden"
          onClick={() => setNavActive(false)}>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 12C8 10.8954 8.89543 10 10 10H34C35.1046 10 36 10.8954 36 12C36 13.1046 35.1046 14 34 14H10C8.89543 14 8 13.1046 8 12Z"
            fill="#1E69CB"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 22C8 20.8954 8.89543 20 10 20H34C35.1046 20 36 20.8954 36 22C36 23.1046 35.1046 24 34 24H10C8.89543 24 8 23.1046 8 22Z"
            fill="#1E69CB"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 32C8 30.8954 8.89543 30 10 30H34C35.1046 30 36 30.8954 36 32C36 33.1046 35.1046 34 34 34H10C8.89543 34 8 33.1046 8 32Z"
            fill="#1E69CB"
          />
        </svg>
        <h3 className="text-[28px] font-semibold text-[#1E69CB] lg:hidden">
          {pathname === "/" ? "Dashboard" : pathname.split("/")[1]}
        </h3>
      </div>
      <div className="inline-flex items-center justify-end gap-[30px] relative flex-[0_0_auto]">
        <div className="icon_not relative hidden lg:block">
          <Notifications />
        </div>

        <Link to={"MyProfile"}>
          <div className="inline-flex items-center justify-center gap-[10px] relative flex-[0_0_auto]">
            <img
              src={imageUrl + img + "?" + new Date().getTime()}
              alt=""
              className="w-[60px] h-[60px] rounded-full object-cover object-top"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Nav;
