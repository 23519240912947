import React, { useEffect, useState } from "react";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import DatePickerCom from "../../components/Calender/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import DeleteMessage from "../../components/DeleteMessage/DeleteMessage";
import { courseUrl, imageUrl, listCatogray, listLanguage } from "../../utils";
import {
  useDescValidation,
  usePriceValidation,
  useSelectValidation,
  useTitleValidation,
} from "../../hooks";
import { emailRegex } from "../../utils/regEx";
import s3 from "../../utils/aws";

const Overview = () => {
  const [course, setCourses] = useState({});

  const [
    sectionDescription,
    sectionDescriptionValid,
    setSectionDescriptionValid,
    setDescriptionSection,
  ] = useTitleValidation();
  // const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [category, setCategory] = useState("");
  // const [price, setPrice] = useState("");
  const [requirement, setRequirement] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [rate, setRate] = useState("");
  const [totalEnrolls, setTotalEnrolls] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");
  const [courseImage, setCourseImage] = useState(null);
  const [teacher, setTeacher] = useState([]);
  const [openDate, setOpenDate] = useState("");
  const [changeData, setChangeData] = useState(false);
  const { id } = useParams();
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();
  const [price, priceValid, setPriceValid, handlePriceChange] =
    usePriceValidation();
  const [
    paymentType,
    paymentTypeValid,
    setPaymentTypeValid,
    handlePaymentTypeChange,
  ] = useSelectValidation();
  const [
    description,
    descriptionValid,
    setDescriptionValid,
    handleDescriptionChange,
  ] = useDescValidation();
  const [emailValid, setEmailValid] = useState(false);
  useEffect(() => {
    const getCourser = async () => {
      try {
        const res = await axios.get(`public/courses/${id}/overview`);
        const {
          title,
          subtitle,
          price,
          category,
          language,
          openDate,
          requirements,
          totalRates,
          totalEnrolls,
          lastUpdate,
          image,
          outcomes,
          teachers,
          description,
          monthly,
        } = res.data;
        handleTitleChange(title);
        setDescriptionSection(subtitle);
        handlePriceChange(price);
        setCategory(category);
        setLanguage(language);
        setRequirement(requirements);
        setRate(totalRates);
        setTotalEnrolls(totalEnrolls);
        setLastUpdate(lastUpdate);
        setCourseImage(image);
        setOutcomes(outcomes);
        setTeacher(teachers);
        setOpenDate(openDate);
        handleDescriptionChange(description);
        handlePaymentTypeChange(monthly === true ? "SUBSCRIPTION" : "ONE_TIME");
      } catch (err) {
        console.log(err);
      }
    };
    getCourser();
  }, [changeData]);
  return (
    <div className="mt-5 flex items-start justify-between gap-5">
      <div className="lg:w-[60%] w-full">
        <CourseCardPhone
          title={title}
          bref={sectionDescription}
          rate={rate}
          price={price}
          totalEnrolls={totalEnrolls}
          lan={language}
          lastUpdate={lastUpdate}
          image={courseImage}
          id={id}
          setChangeData={setChangeData}
          // setDeleteMessage={setDeleteMessage}
        />
        {course && (
          <AboutCourse
            title={title}
            setTitle={handleTitleChange}
            titleValid={titleValid}
            setTitleValid={setTitleValid}
            sectionDescription={sectionDescription}
            description={description}
            language={language}
            category={category}
            price={price}
            setDescriptionSection={setDescriptionSection}
            setDescription={handleDescriptionChange}
            descriptionValid={descriptionValid}
            setDescriptionValid={setDescriptionValid}
            setLanguage={setLanguage}
            setCategory={setCategory}
            setPrice={handlePriceChange}
            priceValid={priceValid}
            setPriceValid={setPriceValid}
            openDate={openDate}
            setOpenDate={setOpenDate}
            setChangeData={setChangeData}
            id={id}
            sectionDescriptionValid={sectionDescriptionValid}
            setSectionDescriptionValid={setSectionDescriptionValid}
            paymentType={paymentType}
            setPaymentType={handlePaymentTypeChange}
            paymentTypeValid={paymentTypeValid}
            setPaymentTypeValid={setPaymentTypeValid}
          />
        )}

        <Requirement
          title={"Requirement"}
          listData={requirement}
          setRequirement={setRequirement}
          setChangeData={setChangeData}
          id={id}
          nameChange={"requirements"}
        />
        <Requirement
          title={"Out Come"}
          listData={outcomes}
          setRequirement={setOutcomes}
          setChangeData={setChangeData}
          id={id}
          nameChange={"outcomes"}
        />
        <CoTeachers
          listData={teacher}
          title={"Co-Teachers"}
          setRequirement={setTeacher}
          setChangeData={setChangeData}
          id={id}
          nameChange={"outcomes"}
          valid={emailValid}
          setValid={setEmailValid}
        />
      </div>
      <CourseCard
        title={title}
        bref={sectionDescription}
        rate={rate}
        price={price}
        totalEnrolls={totalEnrolls}
        lan={language}
        lastUpdate={lastUpdate}
        image={courseImage}
        id={id}
        setChangeData={setChangeData}
        // setDeleteMessage={setDeleteMessage}
      />
    </div>
  );
};

export default Overview;

const AboutCourse = ({
  title,
  sectionDescription,
  description,
  language,
  category,
  price,
  setTitle,
  setDescriptionSection,
  setDescription,
  setLanguage,
  setCategory,
  setPrice,
  openDate,
  setOpenDate,
  setChangeData,
  titleValid,
  setTitleValid,
  priceValid,
  setPriceValid,
  descriptionValid,
  setDescriptionValid,
  sectionDescriptionValid,
  setSectionDescriptionValid,
  paymentType,
  setPaymentType,
  paymentTypeValid,
  setPaymentTypeValid,
}) => {
  const [edit, setEdit] = useState(false);
  const [subtitleValid, setSubtitleValid] = useState(true);
  const [languageValid, setLanguageValid] = useState(true);
  const [categoryValid, setCategoryValid] = useState(true);
  const [dateChanged, setDateChanged] = useState(false);

  const handleDataFromChild = (dateChange) => {
    setDateChanged(dateChange);
  };

  const [dateValid, setDateValid] = useState(true);
  const { id } = useParams();

  const handleSubmit = () => {
    // Reset validation
    // Check for errors

    if (!title) {
      setTitleValid(false);
    }
    if (!sectionDescription) {
      setSectionDescriptionValid(false);
    }
    if (!language) {
      setLanguageValid(false);
    }
    if (!category) {
      setCategoryValid(false);
    }
    if (!price) {
      setPriceValid(false);
    }
    if (description === "" || !description) {
      setDescriptionValid(false);
    } else {
      setDescriptionValid(true);
    }

    // If all fields are valid, proceed with form submission logic
    if (
      titleValid &&
      price &&
      language &&
      category &&
      openDate &&
      titleValid &&
      sectionDescriptionValid &&
      languageValid &&
      priceValid &&
      descriptionValid
    ) {
      // Your form submission logic here
      handleChange();
    }
  };

  const handleChange = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("subtitle", sectionDescription);
    bodyFormData.append("language", language);
    bodyFormData.append("category", category);
    bodyFormData.append("price", price);
    dateChanged && bodyFormData.append("openDate", openDate);
    bodyFormData.append("description", description);
    // bodyFormData.append(
    //   "monthly",
    //   paymentType === "Subscription" ? true : false
    // );

    try {
      const res = await axios.put(`api/courses/${id}/about`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setEdit(false);
    } catch (err) {
      const {} = err.response.data;
    }
  };
  const HandleCansle = () => {
    setEdit(false);
    setChangeData((e) => !e);
  };
  return (
    <div className=" lg:rounded-[4px] rounded-[10px] bg-[#E5E7EB] lg:bg-white py-8 px-5 relative">
      {!edit && (
        <button
          className="w-full h-[44px] rounded-[4px] border border-[#40A726] flex lg:hidden items-center justify-center gap-1 text-lg text-[#40A726] mb-2"
          onClick={() => setEdit(true)}
          id="editAboutCourseMob">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
              fill="#40A726"
            />

            <g mask="url(#mask0_1461_1482)"></g>
          </svg>
          Edit
        </button>
      )}
      <h3 className="text-[22px] font-semibold ">About Course</h3>
      {!edit && (
        <button
          className="lg:w-[98px] h-[44px] hidden rounded-[4px] border border-[#40A726] lg:flex items-center justify-center gap-1 text-lg text-[#40A726] absolute right-3 top-8"
          onClick={() => setEdit(true)}
          id="editAboutCourse">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
              fill="#40A726"
            />

            <g mask="url(#mask0_1461_1482)"></g>
          </svg>
          Edit
        </button>
      )}

      <div className=" flex flex-col gap-5 mt-10">
        <div>
          <Input
            edit={edit}
            title={"Section Title"}
            setValid={setTitleValid}
            valid={titleValid}
            value={title}
            onChange={setTitle}
          />
        </div>
        <div>
          <Input
            edit={edit}
            title={"Section Subtitle"}
            value={sectionDescription}
            onChange={setDescriptionSection}
            valid={sectionDescriptionValid}
            setValid={setSectionDescriptionValid}
          />
        </div>
        <div>
          <Select
            title={"Language Course"}
            edit={edit}
            value={language}
            onChange={setLanguage}
            valid={languageValid}
            setvalid={setLanguageValid}
            list={listLanguage}
          />
        </div>
        <div>
          <Select
            title={"Category Course"}
            edit={edit}
            value={category}
            onChange={setCategory}
            valid={categoryValid}
            setvalid={setCategoryValid}
            list={listCatogray}
          />
        </div>
        <div>
          <Input
            edit={edit}
            title={"Price"}
            valid={priceValid}
            value={price}
            onChange={setPrice}
            setValid={setPriceValid}
          />
        </div>
        <div>
          <Input
            edit={edit}
            title={"Payment Type"}
            valid={paymentTypeValid}
            value={
              String(paymentType).charAt(0).toUpperCase() +
              String(paymentType).slice(1).toLocaleLowerCase()
            }
            onChange={setPaymentType}
            setValid={setPaymentTypeValid}
            inputDisabled={true}
          />
        </div>
        <DateRange
          edit={edit}
          openDate={openDate}
          setOpenDate={setOpenDate}
          dateValid={dateValid}
          handleDataFromChild={handleDataFromChild}
        />

        <div>
          <Input
            edit={edit}
            title={"Descriotion"}
            height={"h-[101px]"}
            value={description}
            onChange={setDescription}
            valid={descriptionValid}
            setValid={setDescriptionValid}
          />
        </div>
      </div>

      {edit && (
        <Button
          onCancle={HandleCansle}
          HandleSave={handleSubmit}
        />
      )}
    </div>
  );
};

const Input = ({
  title,
  placeHolder,
  edit,
  height,
  valid,
  value,
  onChange,
  setValid,
  inputDisabled,
}) => {
  return (
    <div className="mt-2">
      <p className="font-semibold">{title}</p>
      {edit ? (
        <div
          className={`${
            height ? height : "h-[50px]"
          } border border-[#9CA3AF] rounded-[4px] mt-1`}>
          <input
            disabled={inputDisabled}
            type="text"
            value={value}
            className={`w-full h-full rounded-[4px] pl-5 placeholder:text-[#6B7280] outline-none capitalize ${
              inputDisabled && "cursor-not-allowed text-[#6B7280]"
            }`}
            placeholder={placeHolder}
            onChange={(e) => onChange(e.target.value)}
            id={title.split(" ").join("")}
          />

          {!valid && (
            <div className="flex items-center gap-1 mt-2 mb-5">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93726 5.93692 1.83334 10.9997 1.83334C16.0624 1.83334 20.1663 5.93726 20.1663 11C20.1663 16.0628 16.0624 20.1667 10.9997 20.1667C5.93692 20.1667 1.83301 16.0628 1.83301 11ZM11.9163 11.9167C11.9163 12.4227 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4227 10.083 11.9167V7.33334C10.083 6.82734 10.4937 6.41668 10.9997 6.41668C11.5057 6.41668 11.9163 6.82734 11.9163 7.33334V11.9167ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1727 10.083 14.6667C10.083 14.1607 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1607 11.9163 14.6667C11.9163 15.1727 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />
              </svg>
              <p className="text-[12px] font-semibold text-[#DD0303]">{`Please, Enter a valid ${title}`}</p>
            </div>
          )}
        </div>
      ) : (
        <p className="text-[#6B7280] capitalize">{value}</p>
      )}
    </div>
  );
};

const Select = ({ title, edit, value, onChange, valid, setvalid, list }) => {
  const [showSelect, setShowSelect] = useState(false);
  useEffect(() => {
    if (value) {
      setvalid(true);
    }
  }, [value]);

  return (
    <div>
      <div className="mt-2 relative">
        <p className="font-semibold">{title}</p>

        {edit ? (
          <>
            <div
              className="flex items-center justify-between h-[50px] border border-[#9CA3AF] rounded-[4px] px-2 bg-white mt-1 cursor-pointer"
              onClick={() => setShowSelect((e) => !e)}
              id={title.split(" ").join("")}>
              {value ? (
                <p className="text-sm text-[#374151] font-semibold">{value}</p>
              ) : (
                <p className="text-[#6B7280]">Choose your language </p>
              )}
              {showSelect ? (
                <IoIosArrowUp
                  className="w-[20px] h-[20px] cursor-pointer"
                  onClick={() => setShowSelect(true)}
                />
              ) : (
                <IoIosArrowDown
                  className="w-[20px] h-[20px] cursor-pointer"
                  onClick={() => setShowSelect(false)}
                />
              )}
            </div>
            {showSelect && (
              <div className="flex items-start justify-between flex-col max-h-[150px] overflow-y-auto w-full border border-[#9CA3AF]  bg-white mt-1 absolute  z-20 left-0 top-[93%]">
                {list.map((lan) => (
                  <div
                    className="flex items-center  gap-2 hover:bg-blue-300 w-full py-3 px-3 duration-100 transition-all cursor-pointer"
                    onClick={() => {
                      onChange(lan.name);
                      setShowSelect(false);
                    }}>
                    {lan.icon}
                    <p>{lan.name}</p>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <p className="text-[#6B7280]">{value}</p>
        )}
      </div>
      {!valid && edit && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please Fill Out This Field`}</p>
        </div>
      )}
    </div>
  );
};

const Requirement = ({
  title,
  listData,
  setRequirement,
  setChangeData,
  id,
  nameChange,
}) => {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState("");
  const [requirementValid, setRequirementValid] = useState(true);

  const handleSubmit = () => {
    // Reset validation
    // Check for errors
    if (!listData) {
      setRequirementValid(false);
    }

    // If all fields are valid, proceed with form submission logic
    if (listData) {
      // Your form submission logic here
      handleChange();
    }
  };
  const handleChange = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append(nameChange, listData);

    try {
      const res = await axios.put(
        `api/courses/${id}/${nameChange}`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEdit(false);
    } catch (err) {
      const {} = err.response.data;
    }
  };

  const HandleEdit = () => {
    setEdit(false);
    setChangeData((e) => !e);
    setAdd("");
  };
  return (
    <div className="min-h-[40px] max-h-content mt-5 lg:rounded-[4px] rounded-[10px] bg-[#E5E7EB] lg:bg-white py-8 px-5 relative mb-3">
      {!edit && (
        <button
          className="w-full h-[44px] rounded-[4px] border border-[#40A726] flex lg:hidden items-center justify-center gap-1 text-lg text-[#40A726] mb-2"
          onClick={() => setEdit(true)}
          id={title + "Edit-Mobile"}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
              fill="#40A726"
            />

            <g mask="url(#mask0_1461_1482)"></g>
          </svg>
          Edit
        </button>
      )}
      <h3 className="text-[22px] font-semibold ">{title}</h3>
      {!edit && (
        <button
          className="lg:w-[98px] h-[44px] hidden rounded-[4px] border border-[#40A726] lg:flex items-center justify-center gap-1 text-lg text-[#40A726] absolute right-3 top-8"
          onClick={() => setEdit(true)}
          id={title.split(" ").join("") + "Edit"}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
              fill="#40A726"
            />

            <g mask="url(#mask0_1461_1482)"></g>
          </svg>
          Edit
        </button>
      )}

      <InptAdd
        edit={edit}
        value={listData}
        onChange={setRequirement}
        valid={requirementValid}
        add={add}
        setAdd={setAdd}
        title={title}
        setRequirementValid={setRequirementValid}
      />
      {!edit && !listData && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">
            Please, Enter at least one item
          </p>
        </div>
      )}
      {edit && (
        <ButtonReq
          requirementValid={requirementValid}
          setShowAddCourse={handleSubmit}
          notEdit={HandleEdit}
          title={title}
        />
      )}
    </div>
  );
};
const CoTeachers = ({
  title,
  listData,
  setRequirement,
  setChangeData,
  id,
  nameChange,
}) => {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [requirementValid, setRequirementValid] = useState(true);
  const [valid, setValid] = useState(true);

  const handleSubmit = () => {
    if (!listData) {
      setRequirementValid(false);
    }

    if (listData && requirementValid) {
      handleChange();
    }
  };
  const handleChange = async () => {
    let bodyFormData = new FormData();
    const teacherMails = listData?.map((mail) => mail.email);

    bodyFormData.append("teacherMails", teacherMails);

    try {
      const res = await axios.put(`api/courses/${id}/teachers`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setEdit(false);
      setChangeData((e) => !e);
      setIsValid(true);
      setValid(true);
    } catch (err) {
      const {} = err.response.data;
      console.log(err);
      setIsValid(false);
    }
  };
  const HandleEdit = () => {
    setEdit(false);
    setChangeData((e) => !e);
    setAdd("");
    setValid(true);
  };
  return (
    <div className="min-h-[40px] max-h-content mt-5 lg:rounded-[4px] rounded-[10px] bg-[#E5E7EB] lg:bg-white py-8 px-5 relative mb-3">
      {!edit && (
        <button
          className="w-full h-[44px] rounded-[4px] border border-[#40A726] flex lg:hidden items-center justify-center gap-1 text-lg text-[#40A726] mb-2"
          onClick={() => setEdit(true)}
          id={"edit-coTeachers-Mobile"}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
              fill="#40A726"
            />

            <g mask="url(#mask0_1461_1482)"></g>
          </svg>
          Edit
        </button>
      )}
      <h3 className="text-[22px] font-semibold ">{title}</h3>
      {!edit && (
        <button
          className="lg:w-[98px] h-[44px] hidden rounded-[4px] border border-[#40A726] lg:flex items-center justify-center gap-1 text-lg text-[#40A726] absolute right-3 top-8"
          onClick={() => setEdit(true)}
          id={"edit-coTeachers"}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
              fill="#40A726"
            />

            <g mask="url(#mask0_1461_1482)"></g>
          </svg>
          Edit
        </button>
      )}

      <InptAddEmail
        edit={edit}
        value={listData}
        onChange={setRequirement}
        valid={requirementValid}
        setRequirementValid={setRequirementValid}
        add={add}
        setAdd={setAdd}
        setIsValid={setIsValid}
        validCo={valid}
        setvalid={setValid}
        title={"CoTeachers"}
      />
      {!isValid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">
            Please, Enter a email exist
          </p>
        </div>
      )}
      {edit && (
        <ButtonReq
          requirementValid={requirementValid}
          setShowAddCourse={handleSubmit}
          notEdit={HandleEdit}
          title={"CoTeachers"}
        />
      )}
    </div>
  );
};

const CourseCard = ({
  title,
  bref,
  rate,
  lan,
  lastUpdate,
  totalEnrolls,
  image,
  id,
  setChangeData,
}) => {
  const [state, setstate] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [uploadPhoto, setUploadPhoto] = useState(false);
  let navigate = useNavigate();
  const HandleDelete = async (id) => {
    try {
      const res = await axios.delete(`api/courses/${id}`);

      setDeleteMessage(false);
      return navigate(`/MyCourses`);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (deleteMessage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    if (uploadPhoto) {
      document.body.style.overflow = "hidden";
    }

    return () => (document.body.style.overflow = "scroll");
  }, [deleteMessage, uploadPhoto]);
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 fixed bottom-0 left-0  overview"></div>
          <DeleteMessage
            title={`Are you sure about to delete this Course (${title})`}
            bref={"If you delete this file you can’t receive this file again"}
            nameButton={"Delete"}
            onCancle={setDeleteMessage}
            onDelete={() => HandleDelete(id)}
          />
        </>
      )}

      <div
        className="w-[40%] h-[550px] rounded-md bg-white shadow-md relative cursor-pointer lg:flex hidden flex-col justify-between pb-4"
        onMouseEnter={() => setstate(true)}
        onMouseLeave={() => setstate(false)}>
        {uploadPhoto && (
          <>
            <div
              className="w-full h-full z-10 fixed bottom-0 left-0  overview"
              onClick={() => setUploadPhoto(false)}></div>
            <UpdatedPhoto
              image={image}
              id={id}
              setUploadPhoto={setUploadPhoto}
              changeData={setChangeData}
            />
          </>
        )}

        <div>
          <div className="w-full h-[180px] overflow-hidden rounded-tl-md rounded-tr-md">
            <img
              src={`${courseUrl}${image}?${new Date().getTime()}`}
              alt="Course Img"
              className="w-full h-full overflow-hidden object-cover"
            />
          </div>
          <Text
            title={title}
            bref={bref}
          />
        </div>

        <div className="mx-auto flex-col flex justify-between gap-2 py-1 px-5 w-full">
          <div className="flex items-center mt-1 gap-2">
            <StarRating rate={rate} />
            <p className="text-[14px] text-[#374151] font-semibold">
              ( {rate} rating )
            </p>
          </div>

          <div className="text-[14px] text-[#374151] font-semibold mt-1">
            <p>{totalEnrolls}, Students</p>
            {/* <p>Income, {2873}$</p> */}
          </div>
          <div className="flex items-center gap-1 mt-2">
            <TbWorld className="w-[18px] h-[18px]" />
            <p className="text-[14px] text-[#374151] font-semibold ">{lan}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-[14px] text-[#374151] font-semibold mt-1">
              Last Updated {lastUpdate}
            </p>
            <button
              className="w-[150px] rounded-md h-[38px] bg-[#DD0303] flex items-center justify-center text-sm text-white font-semibold gap-1"
              onClick={() => setDeleteMessage(true)}
              id="delete Course">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.666 3.49999H12.2493C12.5702 3.49999 12.8327 3.76249 12.8327 4.08332C12.8327 4.40416 12.5702 4.66666 12.2493 4.66666H11.666V11.0833C11.666 12.0482 10.8808 12.8333 9.91602 12.8333H4.08268C3.11785 12.8333 2.33268 12.0482 2.33268 11.0833V4.66666H1.74935C1.42852 4.66666 1.16602 4.40416 1.16602 4.08332C1.16602 3.76249 1.42852 3.49999 1.74935 3.49999H2.33268H4.66602V2.52466C4.66602 1.77566 5.31993 1.16666 6.12435 1.16666H7.87435C8.67877 1.16666 9.33268 1.77566 9.33268 2.52466V3.49999H11.666ZM6.12435 2.33332C5.95752 2.33332 5.83268 2.43424 5.83268 2.52466V3.49999H8.16602V2.52466C8.16602 2.43424 8.04118 2.33332 7.87435 2.33332H6.12435ZM8.74935 9.91666C9.07018 9.91666 9.33268 9.65416 9.33268 9.33332V6.99999C9.33268 6.67916 9.07018 6.41666 8.74935 6.41666C8.42852 6.41666 8.16602 6.67916 8.16602 6.99999V9.33332C8.16602 9.65416 8.42852 9.91666 8.74935 9.91666ZM5.83268 9.33332C5.83268 9.65416 5.57018 9.91666 5.24935 9.91666C4.92852 9.91666 4.66602 9.65416 4.66602 9.33332V6.99999C4.66602 6.67916 4.92852 6.41666 5.24935 6.41666C5.57018 6.41666 5.83268 6.67916 5.83268 6.99999V9.33332Z"
                  fill="white"
                />
              </svg>
              Delete Course
            </button>
          </div>
        </div>

        <div
          className="w-[40px] h-[40px] bg-white rounded-tr-[4px] flex items-center justify-center absolute top-0 right-0"
          onClick={() => setUploadPhoto(true)}>
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              y="0.5"
              width="30"
              height="30"
              rx="15"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.80039 9.5001C7.16387 9.5001 6.55342 9.75295 6.10333 10.203C5.65325 10.6531 5.40039 11.2636 5.40039 11.9001V21.5001C5.40039 22.1366 5.65325 22.7471 6.10333 23.1972C6.55342 23.6472 7.16387 23.9001 7.80039 23.9001H22.2004C22.8369 23.9001 23.4474 23.6472 23.8974 23.1972C24.3475 22.7471 24.6004 22.1366 24.6004 21.5001V11.9001C24.6004 11.2636 24.3475 10.6531 23.8974 10.203C23.4474 9.75295 22.8369 9.5001 22.2004 9.5001H20.2972C19.979 9.50003 19.6738 9.37356 19.4488 9.1485L18.1036 7.8033C17.6536 7.35318 17.0433 7.10023 16.4068 7.1001H13.594C12.9575 7.10023 12.3472 7.35318 11.8972 7.8033L10.552 9.1485C10.327 9.37356 10.0218 9.50003 9.70359 9.5001H7.80039ZM15.0004 20.3001C15.4731 20.3001 15.9413 20.207 16.3781 20.0261C16.8148 19.8451 17.2117 19.58 17.546 19.2457C17.8803 18.9114 18.1454 18.5145 18.3264 18.0778C18.5073 17.641 18.6004 17.1729 18.6004 16.7001C18.6004 16.2273 18.5073 15.7592 18.3264 15.3224C18.1454 14.8857 17.8803 14.4888 17.546 14.1545C17.2117 13.8202 16.8148 13.555 16.3781 13.3741C15.9413 13.1932 15.4731 13.1001 15.0004 13.1001C14.0456 13.1001 13.1299 13.4794 12.4548 14.1545C11.7797 14.8296 11.4004 15.7453 11.4004 16.7001C11.4004 17.6549 11.7797 18.5706 12.4548 19.2457C13.1299 19.9208 14.0456 20.3001 15.0004 20.3001Z"
              fill="#40A726"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
const CourseCardPhone = ({
  title,
  bref,
  rate,
  lan,
  lastUpdate,
  totalEnrolls,
  image,
  id,
  setChangeData,
}) => {
  const [state, setstate] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [uploadPhoto, setUploadPhoto] = useState(false);

  let navigate = useNavigate();
  const HandleDelete = async (id) => {
    try {
      const res = await axios.delete(`api/courses/${id}`);

      setDeleteMessage(false);
      return navigate(`/MyCourses`);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (deleteMessage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    if (uploadPhoto) {
      document.body.style.overflow = "hidden";
    }

    return () => (document.body.style.overflow = "scroll");
  }, [deleteMessage, uploadPhoto]);
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 fixed bottom-0 left-0 overview"></div>
          <DeleteMessage
            title={`Are you sure about to delete this Course (${title})`}
            bref={"If you delete this file you can’t receive this file again"}
            nameButton={"Delete"}
            onCancle={setDeleteMessage}
            onDelete={() => HandleDelete(id)}
          />
        </>
      )}

      <div className="w-[100%]  rounded-md  shadow-md relative cursor-pointer flex lg:hidden flex-col justify-between mb-5">
        {uploadPhoto && (
          <>
            <div
              className="w-full h-full z-10 fixed bottom-0 left-0  overview"
              onClick={() => setUploadPhoto(false)}></div>
            <UpdatedPhoto
              image={image}
              id={id}
              setUploadPhoto={setUploadPhoto}
              changeData={setChangeData}
              setstate={setstate}
              mobile={true}
            />
          </>
        )}

        <div>
          <div className="w-full h-[200px] overflow-hidden rounded-md bg-white shadow-md py-2 px-2">
            <img
              src={`${courseUrl}${image}?${new Date().getTime()}`}
              alt=""
              className="w-full h-full overflow-hidden rounded-md"
            />
          </div>
        </div>

        <div className="w-[30px] h-[30px] bg-white rounded-full flex items-center justify-center absolute top-4 right-4 shadow-md">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setstate((e) => !e)}>
            <g filter="url(#filter0_d_2863_11207)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.1046 8.798L23.8916 11.319C24.2706 12.537 23.6136 13.851 22.4266 14.248L22.2256 14.315C21.6486 14.509 21.1966 14.989 21.0156 15.601C20.8496 16.162 20.9456 16.779 21.2736 17.253L21.5346 17.628C22.2486 18.66 22.0206 20.108 21.0266 20.857L18.9656 22.413C18.4706 22.787 17.8636 22.938 17.2546 22.841C16.6406 22.742 16.1046 22.402 15.7456 21.884L15.6276 21.712C15.2776 21.208 14.7176 20.902 14.1306 20.935C13.5416 20.951 13.0346 21.23 12.7026 21.702L12.4716 22.03C12.1096 22.543 11.5726 22.878 10.9606 22.974C10.8446 22.992 10.7286 23 10.6136 23C10.1266 23 9.65563 22.842 9.25763 22.54L7.18563 20.966C6.19563 20.216 5.97663 18.773 6.69663 17.75C7.07063 17.22 7.14663 16.539 6.92763 15.893L6.87263 15.725C6.68863 15.143 6.27163 14.691 5.75363 14.514L5.58963 14.458C4.37263 14.041 3.72263 12.749 4.10963 11.517L4.92563 8.922C5.11063 8.335 5.50963 7.861 6.04963 7.588C6.57763 7.322 7.17463 7.281 7.73263 7.472C8.33163 7.676 8.99663 7.565 9.50963 7.175L9.63863 7.077C10.0946 6.729 10.3696 6.165 10.3716 5.567L10.3726 5.326C10.3776 4.042 11.4016 3 12.6556 3H12.6596L15.2066 3.003C15.8086 3.004 16.3756 3.242 16.8046 3.673C17.2476 4.118 17.4896 4.713 17.4876 5.348L17.4856 5.627C17.4836 6.193 17.7426 6.728 18.1796 7.056L18.2866 7.137C18.7456 7.482 19.3436 7.582 19.8796 7.401L20.2196 7.288C20.7956 7.096 21.4106 7.143 21.9516 7.42C22.5066 7.704 22.9166 8.193 23.1046 8.798ZM13.9996 16.5C12.0696 16.5 10.4996 14.93 10.4996 13C10.4996 11.07 12.0696 9.5 13.9996 9.5C15.9296 9.5 17.4996 11.07 17.4996 13C17.4996 14.93 15.9296 16.5 13.9996 16.5ZM13.9996 11.5C13.1726 11.5 12.4996 12.173 12.4996 13C12.4996 13.827 13.1726 14.5 13.9996 14.5C14.8266 14.5 15.4996 13.827 15.4996 13C15.4996 12.173 14.8266 11.5 13.9996 11.5Z"
                fill="#1E69CB"
              />

              <g mask="url(#mask0_2863_11207)"></g>
            </g>
          </svg>
          {state && (
            <div className="w-[160px] absolute top-10 bg-[#F3F4F6] shadow-md rounded-lg right-0 px-2 py-3 flex flex-col justify-between">
              <div
                className="text-xs text-[#1F2937] font-bold flex items-center justify-between  px-1 py-1 rounded-lg "
                onClick={() => setDeleteMessage(true)}>
                Delete
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.666 5.00008H17.4993C17.9577 5.00008 18.3327 5.37508 18.3327 5.83342C18.3327 6.29175 17.9577 6.66675 17.4993 6.66675H16.666V15.8334C16.666 17.2117 15.5443 18.3334 14.166 18.3334H5.83268C4.45435 18.3334 3.33268 17.2117 3.33268 15.8334V6.66675H2.49935C2.04102 6.66675 1.66602 6.29175 1.66602 5.83342C1.66602 5.37508 2.04102 5.00008 2.49935 5.00008H3.33268H6.66602V3.60675C6.66602 2.53675 7.60018 1.66675 8.74935 1.66675H11.2493C12.3985 1.66675 13.3327 2.53675 13.3327 3.60675V5.00008H16.666ZM8.74935 3.33341C8.51102 3.33341 8.33268 3.47758 8.33268 3.60675V5.00008H11.666V3.60675C11.666 3.47758 11.4877 3.33341 11.2493 3.33341H8.74935ZM12.4993 14.1667C12.9577 14.1667 13.3327 13.7917 13.3327 13.3334V10.0001C13.3327 9.54175 12.9577 9.16675 12.4993 9.16675C12.041 9.16675 11.666 9.54175 11.666 10.0001V13.3334C11.666 13.7917 12.041 14.1667 12.4993 14.1667ZM8.33268 13.3334C8.33268 13.7917 7.95768 14.1667 7.49935 14.1667C7.04102 14.1667 6.66602 13.7917 6.66602 13.3334V10.0001C6.66602 9.54175 7.04102 9.16675 7.49935 9.16675C7.95768 9.16675 8.33268 9.54175 8.33268 10.0001V13.3334Z"
                    fill="#DD0303"
                  />

                  <g mask="url(#mask0_2863_17935)"></g>
                </svg>
              </div>
              <div
                className="text-xs text-[#1F2937] font-bold flex items-center justify-between  px-1 pt-4 rounded-lg"
                onClick={() => setUploadPhoto(true)}>
                Change Photo
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                    fill="#40A726"
                  />

                  <g mask="url(#mask0_1461_1482)"></g>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Text = ({ title, bref }) => {
  return (
    <div className="text-[#374151] px-5 mt-2">
      <h3 className="font-bold text-start text-[32px]">{title}</h3>
      <p className="text-[16px] ">{bref}</p>
    </div>
  );
};

const DateRange = ({
  edit,
  openDate,
  setOpenDate,
  dateValid,
  handleDataFromChild,
}) => {
  return (
    <div className="w-full">
      {edit ? (
        <DatePickerCom
          edit={edit}
          value={openDate}
          onChange={setOpenDate}
          setDateChange={handleDataFromChild}
          dateValid={dateValid}
          isEdit={true}
        />
      ) : (
        <>
          <p className="font-semibold">Open Date</p>
          <p className="text-[#6B7280]">{openDate}</p>
        </>
      )}
    </div>
  );
};

const Button = ({ onCancle, HandleSave }) => {
  return (
    <div className="mt-10 flex items-center gap-5 flex-col lg:flex-row">
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] bg-[#0455BA] font-semibold text-white"
        onClick={HandleSave}
        id="Save">
        Save
      </button>
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151]"
        onClick={onCancle}
        id="Cancel">
        Cancel
      </button>
    </div>
  );
};
const ButtonReq = ({ setShowAddCourse, notEdit, title, requirementValid }) => {
  return (
    <div className="mt-5 flex items-center gap-5  bottom-5  flex-col lg:flex-row">
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] bg-[#0455BA] font-semibold text-white"
        onClick={() => requirementValid && setShowAddCourse(false)}
        // onClick={() =>
        //   console.log("clicked", requirementValid) &&
        //   requirementValid &&
        //   setShowAddCourse(false)
        // }
        id={"Save" + title.split(" ").join("")}>
        Save
      </button>
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151]"
        onClick={notEdit}
        id={"Cancel" + title.split(" ").join("")}>
        Cancel
      </button>
    </div>
  );
};

const InptAdd = ({
  title,
  placeHolder,
  edit,
  value,
  onChange,
  valid,
  add,
  setAdd,
  setRequirementValid,
}) => {
  const handleAdd = () => {
    if (add) {
      onChange((e) => [...e, add]);
      setRequirementValid(true);
      setAdd("");
    } else {
      setRequirementValid(false);
    }
  };
  const handleDeleteItem = (indexToDelete) => {
    // Create a new array without the item at the specified index
    const newArray = value.filter((_, index) => index !== indexToDelete);
    // Update the state with the new array
    onChange(newArray);
  };
  return (
    <div>
      {edit ? (
        <div className="mt-5">
          <div className="h-[50px] border border-[#374151] rounded-[4px] flex items-center pr-4 mt-1 bg-white">
            <input
              type="text"
              className="w-full h-full rounded-[4px] pl-5 placeholder:text-[#6B7280] outline-none"
              placeholder={placeHolder}
              onChange={(e) => setAdd(e.target.value)}
              value={add}
              id={title.split(" ").join("")}
            />
            <button
              className="w-[70px] h-[29px] rounded-[4px] bg-[#1E69CB] text-[#F3F4F6] font-semibold"
              onClick={handleAdd}
              id={"add" + title.split(" ").join("")}>
              Add
            </button>
          </div>
          {!valid && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">
                Please, Enter a valid value
              </p>
            </div>
          )}

          {value?.length > 0 && (
            <div className="flex items-start  flex-col bg-[#FFFFFF] border border-[#374151] rounded-[4px] mt-2 py-3 px-3 gap-5 h-[172px] overflow-scroll add">
              {value.map((data, i) => (
                <div className="flex justify-between w-full">
                  <label class="custom-checkbox flex gap-3 text-[#2F2F2F]">
                    <input type="checkbox" />
                    <span class="checkmark min-w-[15px] min-h-[15px] mt-2"></span>
                    <div className="w-[10px] min-w-[10px] min-h-[10px] h-[10px] mt-2 bg-[#0455BA] rounded-full"></div>
                    <p className="w-[contents]">{data}</p>
                  </label>
                  <button
                    id={`delete${i}`}
                    onClick={() => handleDeleteItem(i)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 6H21C21.55 6 22 6.45 22 7C22 7.55 21.55 8 21 8H20V19C20 20.654 18.654 22 17 22H7C5.346 22 4 20.654 4 19V8H3C2.45 8 2 7.55 2 7C2 6.45 2.45 6 3 6H4H8V4.328C8 3.044 9.121 2 10.5 2H13.5C14.879 2 16 3.044 16 4.328V6H20ZM10.5 4C10.214 4 10 4.173 10 4.328V6H14V4.328C14 4.173 13.786 4 13.5 4H10.5ZM15 17C15.55 17 16 16.55 16 16V12C16 11.45 15.55 11 15 11C14.45 11 14 11.45 14 12V16C14 16.55 14.45 17 15 17ZM10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16Z"
                        fill="#DD0303"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        value.length > 0 && (
          <div className="flex flex-col gap-8 mt-10">
            {value.map((data) => (
              <div className="flex items-start gap-2">
                <div className="w-[10px] h-[10px] bg-[#0455BA] rounded-full mt-1"></div>
                <p className="w-[99%]">{data}</p>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};
const InptAddEmail = ({
  title,
  placeHolder,
  edit,
  value,
  onChange,
  add,
  setAdd,
  setIsValid,
  valid,
  setvalid,
  validCo,
  setRequirementValid,
}) => {
  const containsSubstring = value.some((item) => item.email.includes(add));
  const [validExsti, setValidExsti] = useState(false);
  // const [valid, setvalid] = useState(true);
  const handleAdd = () => {
    if (emailRegex.test(add) && !containsSubstring) {
      onChange((e) => [...e, { email: add }]);
      setAdd("");
      setIsValid(true);
      setvalid(true);
      setValidExsti(false);
      setRequirementValid(true);
    } else {
      setRequirementValid(false);
      setvalid(false);
      setValidExsti(false);
    }
    if (containsSubstring) {
      setValidExsti(true);
    }
  };

  const handleDeleteItem = (indexToDelete) => {
    // Create a new array without the item at the specified index
    const newArray = value.filter((_, index) => index !== indexToDelete);
    // Update the state with the new array
    onChange(newArray);
  };

  return (
    <div>
      {edit ? (
        <div className="mt-5">
          <div className="h-[50px] border border-[#374151] rounded-[4px] flex items-center pr-4 mt-1 bg-white">
            <input
              type="text"
              className="w-full h-full rounded-[4px] pl-5 placeholder:text-[#6B7280] outline-none"
              placeholder={placeHolder}
              onChange={(e) => setAdd(e.target.value)}
              value={add}
              id={title}
            />
            <button
              className="w-[70px] h-[29px] rounded-[4px] bg-[#1E69CB] text-[#F3F4F6] font-semibold"
              onClick={handleAdd}
              id={"add email"}>
              Add
            </button>
          </div>

          {value?.length > 0 && (
            <div className="flex items-start  flex-col bg-[#FFFFFF] border border-[#374151] rounded-[4px] mt-2 py-3 px-3 gap-5 min-h-[60px] overflow-scroll add">
              {value.map((data, i) => (
                <div className="flex justify-between w-full">
                  <label class="custom-checkbox flex items-center gap-3 text-[#2F2F2F]">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                    <div className="w-[10px] h-[10px] bg-[#0455BA] rounded-full"></div>
                    <p className="truncate ">{data?.email}</p>
                  </label>
                  <button
                    id="delete"
                    onClick={() => handleDeleteItem(i)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 6H21C21.55 6 22 6.45 22 7C22 7.55 21.55 8 21 8H20V19C20 20.654 18.654 22 17 22H7C5.346 22 4 20.654 4 19V8H3C2.45 8 2 7.55 2 7C2 6.45 2.45 6 3 6H4H8V4.328C8 3.044 9.121 2 10.5 2H13.5C14.879 2 16 3.044 16 4.328V6H20ZM10.5 4C10.214 4 10 4.173 10 4.328V6H14V4.328C14 4.173 13.786 4 13.5 4H10.5ZM15 17C15.55 17 16 16.55 16 16V12C16 11.45 15.55 11 15 11C14.45 11 14 11.45 14 12V16C14 16.55 14.45 17 15 17ZM10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16Z"
                        fill="#DD0303"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        value?.length > 0 && (
          <div className="flex flex-col gap-8 mt-10">
            {value.map((data) => (
              <div className="flex items-center gap-2">
                <div className="w-[36px] h-[36px] rounded-full">
                  <img
                    src={imageUrl + data.image}
                    alt=""
                    className="w-full h-full overflow-hidden rounded-full"
                  />
                </div>
                <p className="w-[99%]">
                  {data?.name} ({data?.email})
                </p>
              </div>
            ))}
          </div>
        )
      )}

      {!validCo && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">
            {validExsti
              ? "Email Already Added to This Course, Please Enter New Email"
              : `Please, Enter a valid Email`}
          </p>
        </div>
      )}
    </div>
  );
};
const StarRating = ({ rate }) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <div className="flex items-center text-[#F6C208] gap-1">
      {stars.map((starNumber) =>
        starNumber <= rate ? (
          <IoStar
            key={starNumber}
            className={"w-[24px] h-[24px]"}
          />
        ) : (
          <IoStarOutline
            key={starNumber}
            className={"w-[24px] h-[24px]"}
          />
        )
      )}
    </div>
  );
};

const UpdatedPhoto = ({ image, setUploadPhoto, setstate, mobile }) => {
  // const [file, setFile] = useState(null);
  const [valid, setValid] = useState(true);

  const HandleUpload = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check file type
      const fileType = file.type;
      if (
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg"
      ) {
        // Check file size (in bytes)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size <= maxSize) {
          // File is valid
          // setFile(file);
          setValid(true);
          uploadToS3(image, file);
        } else {
          setValid(false);
        }
      } else {
        setValid(false);
      }
    }
  };
  const uploadToS3 = async (imageName, file) => {
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/images/courseImages`,
      Key: imageName,
      Body: file,
      ACL: "public-read",
    };

    try {
      await new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
          setUploadPhoto(false);
          mobile && setstate(false);
        });
      });
    } catch (err) {
      // Error handling for the S3 upload
      console.error("S3 Upload Error:", err);
    }
  };

  return (
    <div className="w-[352px] h-[171px] rounded-[10px] py-5 px-5 absolute bg-white z-20 top-14 flex-col right-0 flex justify-between items-center">
      <div>
        <h2 className="text-3xl text-[#1F2937] font-bold border-b pb-1">
          Update profile Photo
        </h2>
        <p
          className={`${
            valid ? "text-[#6B7280]" : "text-[#DD0303]"
          }  text-center pt-1`}>
          JPG or PNG no larger than 2 MB
        </p>
      </div>
      <div className="flex items-center justify-center">
        <input
          type="file"
          id="upload"
          hidden
          onChange={(e) => HandleUpload(e)}
        />
        <label
          htmlFor="upload"
          className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1 cursor-pointer">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
              fill="white"
            />

            <g mask="url(#mask0_2294_15588)"></g>
          </svg>
          Upload Photo
        </label>
      </div>
      {/* <button className="w-[179px] h-[44px] rounded-[4px] bg-[#0455BA] text-white font-semibold flex items-center justify-center gap-1">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5 11H13.5V5C13.5 4.447 13.052 4 12.5 4C11.948 4 11.5 4.447 11.5 5V11H5.5C4.948 11 4.5 11.447 4.5 12C4.5 12.553 4.948 13 5.5 13H11.5V19C11.5 19.553 11.948 20 12.5 20C13.052 20 13.5 19.553 13.5 19V13H19.5C20.052 13 20.5 12.553 20.5 12C20.5 11.447 20.052 11 19.5 11Z"
            fill="white"
          />

          <g mask="url(#mask0_2294_15588)"></g>
        </svg>
        Upload Photo
      </button> */}
    </div>
  );
};
