import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { handleDate } from "../../utils";
import { DateTimePicker } from "@mui/x-date-pickers";
const DatePickerCom = ({
  value,
  onChange,
  dateValid,
  disblePast,
  setDateChange,
  isEdit,
}) => {
  const HandleChangeDate = (e) => {
    const formattedDate = handleDate(e);
    onChange(formattedDate);
    isEdit && setDateChange(formattedDate);
  };
  return (
    <div
      className="mt-5"
      id="Open Date">
      <p className="font-semibold text-[#374151]">Open Date</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DateTimePicker", "DateTimePicker", "DateTimePicker"]}>
          {/* <DatePicker
            views={["year", "month", "day"]}
            className="bg-[#fff] outline-none focus:border-none hover:border-none"
            onChange={HandleChangeDate}
            format="YYYY-MM-DD"
            disablePast={disblePast}
          /> */}
          <DateTimePicker
            views={["year", "month", "day", "hours", "minutes"]}
            value={dayjs(value)}
            className="bg-[#fff] outline-none focus:border-none hover:border-none"
            onChange={HandleChangeDate}
            format="YYYY-MM-DD"
            disablePast={disblePast}
          />
        </DemoContainer>
      </LocalizationProvider>
      {!dateValid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid date`}</p>
        </div>
      )}
    </div>
  );
};

export default DatePickerCom;
