import React, { useState } from "react";
import { useStateContext } from "./../context/CreateContext";
import axios from "./../api/axios";
import { usePriceValidation, useSelectValidation } from "./../hooks";
import Input from "./Courses/Input";
import Select from "./Courses/Select";
import { listMethods } from "../utils";
import Button from "./Courses/Button";

const Withdraw = (totalBalance) => {
  const [withdraw, setWithdraw] = useState(true);
  const { setShowWithdraw, balance } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [method, methodValid, setMethodValid, handleMethodChange] =
    useSelectValidation();

  const [amount, amountValid, setAmountValid, handleAmountChange] =
    usePriceValidation();
  const [number, numberValid, setNumberValid, handleNumberChange] =
    usePriceValidation();

  const handleSubmit = () => {
    // Check for errors
    if (amount > balance) {
      setAmountValid(false);
    }

    if (!method) {
      setMethodValid(false);
    }
    if (!amount) {
      setAmountValid(false);
    }
    if (!number) {
      setNumberValid(false);
    }

    if (
      !methodValid &&
      !method &&
      !amountValid &&
      !amount &&
      !numberValid &&
      !number
    ) {
      setWithdraw(false);
    } else {
      setWithdraw(true);
    }

    // If all fields are valid, proceed with form submission logic
    if (
      numberValid &&
      methodValid &&
      amountValid &&
      amount &&
      method &&
      number &&
      withdraw
    ) {
      withdrawMoney();
    }
  };

  const withdrawMoney = async () => {
    setIsLoading(true);
    let bodyData = {
      method,
      amount,
      number,
    };

    try {
      const res = await axios.post(`api/statistics/withdraw`, bodyData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { id } = res.data;

      setShowWithdraw(false);
    } catch (err) {
      const { amount, number, method } = err.response.data;
      setAmountValid(amount ? false : true);
      setNumberValid(number ? false : true);
      setMethodValid(method ? false : true);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-h-[856px] overflow-y-auto  bg-[#E5E7EB]">
      <div className="add__course lg:w-[749px] w-full h-full lg:h-fit flex flex-col overflow-y-auto max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed ">
        <div className="lg:hidden items-center justify-between mb-10 flex">
          <h2 className="text-[28px] font-semibold text-[#1E69CB] ">
            Add New Course
          </h2>{" "}
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // onClick={() => setShowAddCourse(false)}
            className="cursor-pointer">
            <path
              d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
              fill="#374151"
            />

            <g mask="url(#mask0_1240_20873)"></g>
          </svg>
        </div>

        <Header />
        <div>
          <Input
            title={"Amount"}
            placeHolder={"Enter amount to withdraw"}
            value={amount}
            onChange={handleAmountChange}
            valid={amountValid}
          />
          <Select
            title={"Method"}
            placeHolder={"Select your method"}
            value={method}
            onChange={handleMethodChange}
            listSelect={listMethods}
            valid={methodValid}
          />
          <Input
            title={"Number"}
            placeHolder={"Enter your account number"}
            value={number}
            onChange={handleNumberChange}
            valid={numberValid}
          />
          <Button
            isLoading={isLoading}
            title={"Withdraw"}
            onSave={handleSubmit}
            onCancle={setShowWithdraw}
          />
        </div>
      </div>
    </div>
  );
};

export default Withdraw;

const Header = () => {
  return (
    <div className="w-full text-left ">
      <p className="lg:text-[20px] font-semibold text-center text-[#374151] text-lg">
        Withdraw your money
      </p>
      <div className={`h-[4px] w-full bg-[#438EF2] rounded-[4px] mt-2`}></div>
    </div>
  );
};
