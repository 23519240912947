import React, { useState } from "react";
import Overview from "./Overview";
import Lessons from "./Lessons";
import Assignments from "./Assignments";
import Submissions from "./Submissions";
import Rates from "./Rates";
const CourseDetails = () => {
  const [page, setPage] = useState("Overview");
  return (
    <>
      <div>
        <h2 className="text-[57px] font-bold text-[#374151] hidden lg:block">
          My Courses
        </h2>
        <Banner
          setPage={setPage}
          page={page}
        />
        <BannerPhone
          setPage={setPage}
          page={page}
        />
      </div>
      {page === "Overview" && <Overview />}
      {page === "Lessons" && <Lessons />}
      {page === "Assignments" && <Assignments />}
      {page === "Submissions" && <Submissions />}
      {page === "Rates" && <Rates />}
    </>
  );
};

export default CourseDetails;

const Banner = ({ page, setPage }) => {
  const list = ["Overview", "Lessons", "Assignments", "Submissions", "Rates"];

  return (
    <div className="h-[289px] bg-[#374151] rounded-[4px] px-5 lg:flex items-center relative hidden">
      <h2 className="text-[90px] font-semibold text-[#FFFFFF]">{page}</h2>

      <div className=" absolute bottom-0 flex items-center  justify-between w-full px-10">
        {list.map((list) => (
          <div
            className="cursor-pointer"
            onClick={() => setPage(list)}
            id={list}>
            <p
              className="text-[28px] font-semibold text-white"
              id={list}>
              {list}
            </p>
            <div
              className={`w-[100%] h-[9px] ${
                page == list ? "bg-white" : "bg-transparent"
              } `}></div>
          </div>
        ))}
      </div>
    </div>
  );
};
const BannerPhone = ({ page, setPage }) => {
  const list = ["Overview", "Lessons", "Assignments", "Submissions", "Rates"];
  return (
    <div className="flex items-center justify-between w-full mt-5 lg:hidden">
      {list?.map((pag) => (
        <div
          className="cursor-pointer"
          onClick={() => setPage(pag)}
          id={pag}>
          <p
            className={`text-[14px] font-semibold ${
              page === pag ? "text-[#2D78DB]" : "text-[#6B7280]"
            } `}
            id={pag}>
            {pag}
          </p>
          <div
            className={`w-full h-[3px] rounded-lg ${
              page == pag ? "bg-[#2D78DB]" : "bg-transparent"
            } `}
            id={pag}></div>
        </div>
      ))}
    </div>
  );
};
