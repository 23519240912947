const UploadImage = ({ valid, uploadFile, setUploadFile, setValid }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check file type
      const fileType = file.type;
      if (
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg"
      ) {
        // Check file size (in bytes)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size <= maxSize) {
          // File is valid
          setUploadFile(file);
          setValid(true);
        } else {
          setValid(false);
          setUploadFile(null);
        }
      } else {
        setValid(false);
        setUploadFile(null);
      }
    }
  };
  return (
    <div className="mt-5">
      <p className="font-semibold text-[#374151]">Add Image</p>
      <div className="flex items-center justify-center h-[110px] border border-[#9CA3AF] rounded-[4px] px-2 bg-white gap-2">
        <svg
          width="71"
          height="70"
          viewBox="0 0 71 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.2945 45.5001C16.1603 45.496 13.1469 44.2907 10.8744 42.1323C8.60189 39.9739 7.2431 37.0266 7.07764 33.8968C6.91219 30.767 7.95264 27.6929 9.98492 25.3069C12.0172 22.9209 14.8867 21.4047 18.003 21.0701C17.0854 17.4717 17.6348 13.6562 19.5304 10.4629C21.4261 7.26965 24.5126 4.96019 28.111 4.04261C31.7093 3.12502 35.5249 3.67448 38.7181 5.5701C41.9114 7.46571 44.2209 10.5522 45.1385 14.1506C47.2785 13.8526 49.457 13.9982 51.5384 14.5782C53.6198 15.1582 55.5596 16.1603 57.2371 17.5221C58.9147 18.8839 60.2941 20.5763 61.2895 22.4941C62.285 24.4118 62.8752 26.5139 63.0234 28.6695C63.1717 30.8251 62.8748 32.9882 62.1512 35.0241C61.4277 37.0601 60.293 38.9254 58.8177 40.5041C57.3424 42.0827 55.558 43.3409 53.5756 44.2004C51.5932 45.0599 49.4552 45.5023 47.2945 45.5001H38.5445V32.9456L43.07 37.4711C43.7301 38.1087 44.6142 38.4614 45.5319 38.4535C46.4496 38.4455 47.3274 38.0774 47.9763 37.4285C48.6253 36.7795 48.9933 35.9017 49.0013 34.984C49.0093 34.0663 48.6565 33.1822 48.019 32.5221L37.519 22.0221C36.8626 21.366 35.9725 20.9974 35.0445 20.9974C34.1164 20.9974 33.2263 21.366 32.57 22.0221L22.07 32.5221C21.4324 33.1822 21.0796 34.0663 21.0876 34.984C21.0956 35.9017 21.4637 36.7795 22.1126 37.4285C22.7615 38.0774 23.6394 38.4455 24.557 38.4535C25.4747 38.4614 26.3588 38.1087 27.019 37.4711L31.5445 32.9491V45.5001H19.2945Z"
            fill="#0455BA"
          />
          <path
            d="M31.5449 45.4998H38.5449V62.9998C38.5449 63.928 38.1762 64.8183 37.5198 65.4746C36.8634 66.131 35.9732 66.4998 35.0449 66.4998C34.1167 66.4998 33.2264 66.131 32.57 65.4746C31.9137 64.8183 31.5449 63.928 31.5449 62.9998V45.4998Z"
            fill="#0455BA"
          />
        </svg>

        <div>
          <h3 className="font-semibold text-[#374151]">
            {uploadFile
              ? "Change Image For Your Course"
              : "Add Image For Your Course"}
          </h3>
          <p
            className={`text-[12px] ${
              valid ? "text-[#6B7280]" : "text-[#DD0303] font-bold"
            }  text-center mt-1`}
          >
            {uploadFile
              ? uploadFile.name
              : "files supported: (JPEG, PNG) Maximum Image Is 2MB"}
          </p>
          <div className="flex items-center justify-center">
            <input
              type="file"
              id="upload"
              hidden
              onChange={(e) => handleFileChange(e)}
            />

            {uploadFile ? (
              <label
                for="upload"
                className="w-[90px] h-[30px] border border-[#374151] text-[12px] font-semibold rounded-[4px] flex items-center justify-center mt-2"
              >
                Change Image
              </label>
            ) : (
              <label
                for="upload"
                className="w-[77px] h-[30px] border border-[#374151] text-[12px] font-semibold rounded-[4px] flex items-center justify-center mt-2"
              >
                Add Image
              </label>
            )}
          </div>
        </div>
      </div>

      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid Image `}</p>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
