import AWS from "aws-sdk";

const s3 = new AWS.S3({
  endpoint: new AWS.Endpoint("https://fra1.digitaloceanspaces.com"),
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION, // This is just a placeholder as AWS SDK expects a region, but it's not used by DigitalOcean.
  signatureVersion: "v4",
});

export default s3;
