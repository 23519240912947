import React, { useEffect, useState } from "react";
import { useStateContext } from "../context/CreateContext";
import axios from "../api/axios";
import { courseUrl } from "../utils";

const Notification = () => {
  const { idUser } = useStateContext();
  const [notifys, setNotifys] = useState([]);
  const [read, setRead] = useState(false);
  useEffect(() => {
    const getNotify = async () => {
      try {
        const res = await axios.get(`api/teachers/${idUser}/notify`);
        setNotifys(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getNotify();
  }, [read]);
  const activeNoti = notifys.filter((notify) => notify.read === false);
  const readNotiy = async () => {
    try {
      const res = await axios.get(`api/teachers/${idUser}/read-notify`);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex flex-col gap-5 mt-5">
      {notifys.length > 0 &&
        notifys.map((notify) => (
          <div className="flex items-center gap-2 px-2 border-[#D1D5DB] w-full h-[70px] bg-white rounded-lg shadow-md">
            <div className="inline-flex items-center justify-center gap-[10px] relative ">
              <img
                src={courseUrl + notify?.senderImg}
                className="relative w-[50px] h-[50px] rounded-full"
                alt={notify?.senderName}
              />
            </div>

            <div className=" text-[#374151]">
              <p className="font-semibold text-[#0455BA]">
                {notify?.senderName}
              </p>
              <p className="text-[#374151] text-[10px]">
                {notify?.notifyContent}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Notification;
