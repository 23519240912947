import React from "react";
import DatePickerCom from "../Calender/DatePicker";
import InptAdd from "./InputAdd";
import Description from "./Description";
import Button from "./Button";
import { useStateContext } from "../../context/CreateContext";
import Input from "./Input";
import InptAddEmail from "./InputAddEmail";
import Select from "./Select";
import { listPaymentType } from "../../utils";

const CouresReq = ({
  requirement,
  setRequirement,
  requirementValid,
  outCome,
  setOutCome,
  outComeValid,
  email,
  setEmail,
  emailValid,
  price,
  setPrice,
  priceValid,
  setFor,
  setSetFor,
  forValid,
  description,
  setDescription,
  descriptionValid,
  handleSubmit,
  setEmailValid,
  isLoading,
  paymentType,
  setPaymentType,
  paymentTypeValid,
  setPaymentTypeValid,
}) => {
  const { setShowAddCourse } = useStateContext();

  return (
    <div className="max-h-[800px] overflow-y-auto">
      <div className="  bg-[#E5E7EB] px-4 py-4 lg:px-0 lg:py-0 rounded-[10px] mt-5 lg:mt-0 lg:bg-[#F5F5F5]">
        <InptAdd
          title={"Add Requirement "}
          placeHolder={"Add your requirement "}
          add={requirement}
          setAdd={setRequirement}
          valid={requirementValid}
        />
        <InptAdd
          title={"Out Come List"}
          placeHolder={"add your out come list"}
          add={outCome}
          setAdd={setOutCome}
          valid={outComeValid}
        />
        <InptAddEmail
          title={"Co-Teacher Email"}
          placeHolder={"Add another teacher for this project"}
          add={email}
          setAdd={setEmail}
          valid={emailValid}
          setValid={setEmailValid}
        />
        <Input
          title={"Course Price"}
          placeHolder={"Enter your title"}
          value={price}
          onChange={setPrice}
          valid={priceValid}
        />
        <Select
          title={"Payment type"}
          placeHolder={"Choose your Payment type"}
          value={paymentType}
          onChange={setPaymentType}
          valid={paymentTypeValid}
          listSelect={listPaymentType}
        />
        <DatePickerCom
          value={setFor}
          onChange={setSetFor}
          dateValid={forValid}
          disblePast={true}
        />
        <Description
          placeHolder={"Description about your course..."}
          value={description}
          onChange={setDescription}
          valid={descriptionValid}
        />
      </div>
      <Button
        onSave={handleSubmit}
        onCancle={setShowAddCourse}
        title={"Save"}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CouresReq;
