import { useState } from "react";

const InputPass = ({ title, placholder, onChange, value, valid, edit }) => {
  const [showPass, setshowPass] = useState(false);
  return (
    <div className="w-full mt-6 ">
      {!edit ? (
        <>
          <label htmlFor={title} className="font-semibold text-[#374151]">
            {title}
          </label>
          <div
            className={`w-full h-[50px] border-[#9CA3AF] border rounded-[4px] mt-2 pr-5 flex items-center ${
              edit ? "bg-[#F5F5F5] " : "bg-white"
            }`}
          >
            <input
              type={showPass ? "text" : "password"}
              name={title}
              id={title}
              className="w-full h-full rounded-[4px]  text-[#6B7280] outline-none px-3"
              onChange={(e) => onChange(e.target.value)}
              value={value}
            />
            {showPass ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.407 17.993L20.707 19.293C21.098 19.684 21.098 20.316 20.707 20.707C20.512 20.902 20.256 21 20 21C19.744 21 19.488 20.902 19.293 20.707L16.586 18L16 17.414L14.655 16.069L14.586 16L12.0837 13.4977C12.056 13.4992 12.0281 13.5 12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.9719 10.5008 11.944 10.5023 11.9163L7.283 8.69701L5.532 6.94601L3.293 4.70701C2.902 4.31601 2.902 3.68401 3.293 3.29301C3.684 2.90201 4.316 2.90201 4.707 3.29301L6.42 5.00601L7.885 6.47101L11.9163 10.5023C11.944 10.5008 11.9719 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.0281 13.4992 12.056 13.4977 12.0837L17.414 16L19.407 17.993ZM8.5928 5.76421L11.3898 8.56121C11.5888 8.52621 11.7908 8.50021 11.9998 8.50021C13.9298 8.50021 15.4998 10.0702 15.4998 12.0002C15.4998 12.2092 15.4738 12.4112 15.4388 12.6102L18.1208 15.2932L18.9548 16.1272C20.4768 14.7392 21.4528 13.2202 21.8678 12.4972C22.0438 12.1892 22.0438 11.8102 21.8678 11.5022C21.2298 10.3902 17.7058 4.81621 11.7298 5.00321C10.5458 5.03421 9.4738 5.28921 8.5018 5.67421L8.5928 5.76421ZM13.948 16.7771L15.293 18.1211L15.498 18.3261C14.526 18.7111 13.455 18.9661 12.271 18.9961C12.189 18.9991 12.106 19.0001 12.025 19.0001C6.162 19.0001 2.762 13.5951 2.132 12.4971C1.956 12.1891 1.956 11.8101 2.132 11.5021C2.547 10.7801 3.523 9.26111 5.045 7.87311L6.576 9.40511L8.562 11.3901C8.526 11.5891 8.5 11.7911 8.5 12.0001C8.5 13.9301 10.07 15.5001 12 15.5001C12.209 15.5001 12.411 15.4741 12.61 15.4381L13.879 16.7071L13.948 16.7771Z"
                  fill="#6B7280"
                  onClick={() => setshowPass(false)}
                />

                <g mask="url(#mask0_1_4369)"></g>
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setshowPass(true)}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.729 5.00264C17.706 4.81564 21.229 10.3896 21.868 11.5016C22.044 11.8106 22.044 12.1886 21.868 12.4976C21.014 13.9856 17.798 18.8556 12.271 18.9966C12.188 18.9986 12.106 18.9996 12.025 18.9996C6.162 18.9996 2.762 13.5946 2.132 12.4976C1.956 12.1886 1.956 11.8106 2.132 11.5016C2.986 10.0136 6.202 5.14364 11.729 5.00264ZM12 15.4996C10.07 15.4996 8.5 13.9296 8.5 11.9996C8.5 10.0696 10.07 8.49964 12 8.49964C13.93 8.49964 15.5 10.0696 15.5 11.9996C15.5 13.9296 13.93 15.4996 12 15.4996ZM12 10.4996C11.173 10.4996 10.5 11.1726 10.5 11.9996C10.5 12.8266 11.173 13.4996 12 13.4996C12.827 13.4996 13.5 12.8266 13.5 11.9996C13.5 11.1726 12.827 10.4996 12 10.4996Z"
                  fill="#6B7280"
                />
                <g mask="url(#mask0_837_12098)"></g>
              </svg>
            )}
          </div>
        </>
      ) : (
        <>
          <p className="font-semibold text-[#374151]">{title}</p>
          <div
            className={`${
              valid ? "border border-[#9CA3AF] " : "border-2 border-[#DD0303]"
            }  rounded-[4px] mt-1 flex items-center px-3 h-[50px]`}
          >
            <input
              type="password"
              name=""
              id=""
              className="w-full h-full rounded-[4px] bg-[#E5E7EB] md:bg-[#F5F5F5] text-[#6B7280] outline-none px-3"
              value={"Test.01020@"}
            />
          </div>
        </>
      )}
      {title === "Confirm Password" ? (
        <>
          {!valid && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">
                {value
                  ? "Mismatching Passwords"
                  : `Please, Enter a valid ${title}`}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {!valid && (
            <div className="flex items-center mt-1 gap-2">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
                  fill="#DD0303"
                />

                <g mask="url(#mask0_972_11061)"></g>
              </svg>
              <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InputPass;
