import { useState } from "react";
import { titleRegex } from "../utils/regEx";
import { useEffect } from "react";
//
const useDescValidation = () => {
  const [title, setTitle] = useState("");
  const [titleValid, setTitleValid] = useState(true);

  const validateTitle = (newTitle) => {
    // You can customize the validation logic here
    const isNotNumber = isNaN(newTitle);

    const isValidTitle =
      isNotNumber &&
      newTitle &&
      titleRegex.test(newTitle) &&
      newTitle.length > 0 &&
      newTitle.length < 1000;
    setTitleValid(isValidTitle);
  };

  const handleTitleChange = (e) => {
    const newTitle = e;
    setTitle(newTitle);
    if (!title && !titleValid) {
      validateTitle(newTitle);
    }
  };
  useEffect(() => {
    if (!title) {
      setTitleValid(false);
    }
    if (title === "") {
      setTitleValid(true);
    } else {
      validateTitle(title);
    }
  }, [title]);
  return [title, titleValid, setTitleValid, handleTitleChange];
};
export default useDescValidation;
