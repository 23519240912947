import React, { useEffect, useState } from "react";
import Input from "../Lessons/Input";
import { useStateContext } from "../../context/CreateContext";
import DeleteMessage from "../DeleteMessage/DeleteMessage";
import DateRange from "./DateRange";
import dayjs from "dayjs";
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import { useDescValidation, useTitleValidation } from "../../hooks";

const EditAssignment = ({
  setShowEditAssignment,
  Title,
  Deadline,
  OpenDate,
  Description,
  setchangeData,
  idAssignment,
}) => {
  useEffect(() => {
    handleDescriptionChange(Description);
    handleTitleChange(Title);
  }, []);
  const { id } = useParams();
  const [edit, setEdit] = useState(true);
  const [deleteMessage, setdeleteMessage] = useState(false);
  // const [title, setTitle] = useState(Title);
  // const [description, setDescription] = useState("");
  // const [titleValid, setTitleValid] = useState(true);
  // const [descriptionValid, setDescriptionValid] = useState(true);
  const [schedule, setSchedule] = useState("");
  const [scheduleValid, setScheduleValid] = useState(true);
  const [openDate, setOpenDate] = useState();
  const [deadline, setDeadline] = useState();
  const [openDateSave, setOpenDateSave] = useState(OpenDate);
  const [deadlinSave, setDeadlinSave] = useState(Deadline);
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();
  const [
    description,
    descriptionValid,
    setDescriptionValid,
    handleDescriptionChange,
  ] = useDescValidation();
  const handleSubmit = () => {
    const nowDate = dayjs();
    const deadlinebefore = dayjs(deadline).isBefore(openDate);

    // Reset validation
    // Check for errors
    if (dayjs(openDate).isBefore(nowDate, "day")) {
      setScheduleValid(false);
    }
    if (deadlinebefore) {
      setScheduleValid(false);
    } else {
      setScheduleValid(true);
    }

    if (!title) {
      setTitleValid(false);
    }
    if (!openDate && !deadline && !OpenDate && !Deadline) {
      setScheduleValid(false);
    }
    if (!description) {
      setDescriptionValid(false);
    }
    if (
      title &&
      description &&
      titleValid &&
      descriptionValid &&
      scheduleValid &&
      !deadlinebefore
    ) {
      // Your form submission logic here
      createAssignment();
    }
  };
  const createAssignment = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("courseId", id);
    bodyFormData.append("description", description);
    if (openDate && deadline) {
      bodyFormData.append("openDate", openDate);
      bodyFormData.append("deadline", deadline);
    }

    try {
      const res = await axios.put(
        `api/assignments/${idAssignment}`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { id } = res.data;
      setShowEditAssignment(false);
      setchangeData((e) => !e);
    } catch (err) {
      const { title, openDate, description, deadline } = err.response.data;
      setTitleValid(title ? false : true);
      setScheduleValid(openDate ? false : true);
      setScheduleValid(deadline ? false : true);
      setDescriptionValid(description ? false : true);
    }
  };
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 absolute top-0 left-0 overview"></div>
          <DeleteMessage
            title={"If you out now you will lose data you was entered "}
            nameButton={"Yes"}
            onDelete={() => {
              setShowEditAssignment(false);
              setdeleteMessage(false);
            }}
            onCancle={setdeleteMessage}
          />
        </>
      )}
      <div className="rounded-[4px]  add__course  w-full lg:w-[729px] lg:min-h-[343px] h-full  lg:h-fit flex flex-col max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed  ">
        <div className="lg:hidden items-center justify-between mb-10 flex">
          <h2 className="text-[28px] font-semibold text-[#1E69CB]">
            Edit File
          </h2>{" "}
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowEditAssignment(false)}
            className="cursor-pointer">
            <path
              d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
              fill="#374151"
            />

            <g mask="url(#mask0_1240_20873)"></g>
          </svg>
        </div>
        <div className="bg-[#E5E7EB] lg:bg-[#F5F5F5] px-3 py-3 rounded-xl">
          {edit && (
            <button
              className="w-full h-[44px] rounded-[4px] border border-[#40A726] flex lg:hidden items-center justify-center gap-1 text-lg text-[#40A726] mb-2"
              onClick={() => setEdit(false)}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                  fill="#40A726"
                />

                <g mask="url(#mask0_1461_1482)"></g>
              </svg>
              Edit
            </button>
          )}
          {edit && (
            <button
              className="lg:w-[98px] h-[44px] hidden rounded-[4px] border border-[#40A726] lg:flex items-center justify-center gap-1 text-lg text-[#40A726] absolute right-3 top-8"
              onClick={() => setEdit(false)}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4029 7.83667L19.4039 7.83767C20.1689 8.60367 20.1999 9.82167 19.4719 10.5517L10.4749 19.5487C10.1499 19.8737 9.7189 20.0747 9.2609 20.1167L5.0909 20.4957C5.0609 20.4987 5.0309 20.4997 4.9999 20.4997C4.7369 20.4997 4.4819 20.3957 4.2929 20.2067C4.0829 19.9967 3.9779 19.7047 4.0039 19.4097L4.3829 15.2397C4.4249 14.7827 4.6269 14.3507 4.9529 14.0257L13.9489 5.02967C14.6509 4.32467 15.9239 4.35967 16.6649 5.09867L19.4029 7.83667ZM13.3239 8.48367L16.0189 11.1787L17.9659 9.22867L15.2719 6.53467L13.3239 8.48367Z"
                  fill="#40A726"
                />

                <g mask="url(#mask0_1461_1482)"></g>
              </svg>
              Edit
            </button>
          )}

          <div className=" flex flex-col gap-5 mt-5 bg-[#E5E7EB] lg:bg-[#F5F5F5]">
            <div>
              <Input
                edit={edit}
                title={"Title"}
                setValid={setTitleValid}
                valid={titleValid}
                value={title}
                onChange={handleTitleChange}
              />
            </div>
            <div>
              <Input
                edit={edit}
                title={"Content"}
                value={description}
                onChange={handleDescriptionChange}
                valid={descriptionValid}
                setValid={setDescriptionValid}
                height={"h-[101px]"}
              />
            </div>
            <div>
              <InputSchedule
                edit={edit}
                title={"Schedule"}
                placeHolder={"Schedule"}
                valid={scheduleValid}
                setValid={setScheduleValid}
                value={schedule}
                onChange={setSchedule}
                OpenDate={OpenDate}
                Deadline={Deadline}
                openDate={openDate}
                setOpenDate={setOpenDate}
                deadline={deadline}
                setDeadline={setDeadline}
                openDateSave={openDateSave}
                setOpenDateSave={setOpenDateSave}
                deadlinSave={deadlinSave}
                setDeadlinSave={setDeadlinSave}
              />
            </div>
          </div>
        </div>

        {!edit ? (
          <Button
            setShowAddCourse={setEdit}
            HandleSave={handleSubmit}
            onCancle={setdeleteMessage}
          />
        ) : (
          <button
            className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151] mt-5"
            onClick={() => setShowEditAssignment(false)}>
            Cancel
          </button>
        )}
      </div>
    </>
  );
};
export default EditAssignment;

const Button = ({ setShowAddCourse, HandleSave, onCancle }) => {
  return (
    <div className="lg:mt-3 mt-8 flex items-center gap-5 flex-col lg:flex-row text-[24px] lg:text-base">
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] bg-[#0455BA] font-semibold text-white"
        onClick={HandleSave}>
        Save
      </button>
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151]"
        onClick={() => onCancle(true)}>
        Cancel
      </button>
    </div>
  );
};

const InputSchedule = ({
  title,
  placeHolder,
  onChange,
  value,
  valid,
  setValid,
  height,
  edit,
  Deadline,
  OpenDate,
  openDate,
  setOpenDate,
  deadline,
  setDeadline,
  openDateSave,
  setOpenDateSave,
  deadlinSave,
  setDeadlinSave,
}) => {
  useEffect(() => {
    if (value) {
      setValid(true);
    }
  }, [value]);
  const [showCalender, setshowCalender] = useState(false);
  return (
    <div className="mt-5">
      {!edit ? (
        <InputDate
          title={title}
          valid={valid}
          setValid={setValid}
          showCalender={showCalender}
          setshowCalender={setshowCalender}
          openDate={openDate}
          setDeadline={setDeadline}
          deadline={deadline}
          setOpenDate={setOpenDate}
          openDateSave={openDateSave}
          setOpenDateSave={setOpenDateSave}
          deadlinSave={deadlinSave}
          setDeadlinSave={setDeadlinSave}
        />
      ) : (
        <div className="flex items-center justify-between">
          {OpenDate && (
            <div>
              <p className="text-[#374151] text-xl font-semibold">Open Date</p>
              <p className="text-[#6B7280]">{OpenDate}</p>
            </div>
          )}
          {Deadline && (
            <div className="w-1/2">
              <p className="text-[#374151] text-xl font-semibold">Deadline</p>
              <p className="text-[#6B7280]">{Deadline}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const InputDate = ({
  title,
  placeHolder,
  onChange,
  value,
  valid,
  setValid,
  height,
  edit,
  openDate,
  setOpenDate,
  deadline,
  setDeadline,
  openDateSave,
  setOpenDateSave,
  deadlinSave,
  setDeadlinSave,
}) => {
  useEffect(() => {
    if (value) {
      setValid(true);
    }
  }, [value]);
  const [showCalender, setshowCalender] = useState(false);

  return (
    <div className="mt-5">
      {!edit ? (
        <>
          <p className="font-semibold text-[#374151]">{title}</p>
          <div
            className={`${height ? height : "h-[50px]"} ${
              valid ? "border border-[#9CA3AF] " : "border-2 border-[#DD0303] "
            } 
             rounded-[4px] mt-1 relative flex items-center justify-between px-4`}>
            <p className="text-[#6B7280]">
              {openDateSave ? openDateSave : "YYYY-MM-DD"} -{" "}
              {deadlinSave ? deadlinSave : "YYYY-MM-DD"}
            </p>
            <DateRange
              showCalender={showCalender}
              setshowCalender={setshowCalender}
              openDate={openDate}
              setDeadline={setDeadline}
              deadline={deadline}
              setOpenDate={setOpenDate}
              openDateSave={openDateSave}
              setOpenDateSave={setOpenDateSave}
              deadlinSave={deadlinSave}
              setDeadlinSave={setDeadlinSave}
            />
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer"
              onClick={() => setshowCalender(true)}>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.8333 4.66683H21C22.9297 4.66683 24.5 6.23716 24.5 8.16683V22.1668C24.5 24.0965 22.9297 25.6668 21 25.6668H7C5.07033 25.6668 3.5 24.0965 3.5 22.1668V8.16683C3.5 6.23716 5.07033 4.66683 7 4.66683H8.16667V3.50016C8.16667 2.8585 8.69167 2.3335 9.33333 2.3335C9.975 2.3335 10.5 2.8585 10.5 3.50016V4.66683H17.5V3.50016C17.5 2.8585 18.025 2.3335 18.6667 2.3335C19.3083 2.3335 19.8333 2.8585 19.8333 3.50016V4.66683ZM5.83333 12.8335H22.1667V8.16683C22.1667 7.524 21.6428 7.00016 21 7.00016H19.8333V8.16683C19.8333 8.8085 19.3083 9.3335 18.6667 9.3335C18.025 9.3335 17.5 8.8085 17.5 8.16683V7.00016H10.5V8.16683C10.5 8.8085 9.975 9.3335 9.33333 9.3335C8.69167 9.3335 8.16667 8.8085 8.16667 8.16683V7.00016H7C6.35717 7.00016 5.83333 7.524 5.83333 8.16683V12.8335ZM14 19.8335H18.6667C19.3083 19.8335 19.8333 19.3085 19.8333 18.6668C19.8333 18.0252 19.3083 17.5002 18.6667 17.5002H14C13.3583 17.5002 12.8333 18.0252 12.8333 18.6668C12.8333 19.3085 13.3583 19.8335 14 19.8335ZM9.33333 19.8335C8.69167 19.8335 8.16667 19.3085 8.16667 18.6668C8.16667 18.0252 8.69167 17.5002 9.33333 17.5002C9.975 17.5002 10.5 18.0252 10.5 18.6668C10.5 19.3085 9.975 19.8335 9.33333 19.8335Z"
                fill="#374151"
              />

              <g mask="url(#mask0_1225_18340)"></g>
            </svg>
          </div>
        </>
      ) : (
        <div>
          <h3 className="text-xl font-semibold text-[#374151]">{title}</h3>
          <p className="text-[#6B7280]">{value}</p>
        </div>
      )}

      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title} in funture`}</p>
        </div>
      )}
    </div>
  );
};
