import React, { useEffect, useState } from "react";
import banner from "../assets/banner.webp";
import axios from "../api/axios";
import {
  useNameInput,
  usePriceValidation,
  useSelectValidation,
  useTitleValidation,
} from "../hooks";
import Personal from "../components/myProfile/Personal";
import Experience from "../components/myProfile/Experience";
import About from "../components/myProfile/About";
import Header from "../components/myProfile/Header";
import CardPerson from "../components/myProfile/CardPerson";
import { useStateContext } from "../context/CreateContext";
const MyProfile = () => {
  const [firstName, fristNameValid, setfristNameValid, handleFristNameChange] =
    useNameInput();
  const [lastName, lastNameValid, setlastNameValid, handleLastNameChange] =
    useNameInput();
  const [about, aboutValid, setAboutValid, handleAboutChange] =
    useTitleValidation();
  const [jopTitle, jopTitleValid, setJopTitletValid, handleJopTitleChange] =
    useTitleValidation();
  const [language, languageValid, setLanguageValid, handleLanguageChange] =
    useSelectValidation();
  const [country, countryValid, setCountryValid, handleCountryChange] =
    useSelectValidation();
  const [currenSection, setCurrenSection] = useState("Personal");
  const [id, setidUser] = useState("");
  const [image, setImage] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [joplevel, setJopLevel] = useState("");
  const [experience, experienceValid, setExperienceValid, setExperience] =
    usePriceValidation();
  const { idUser } = useStateContext();
  const [changeData, setChangeData] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(`api/teachers/${idUser}`);
        const {
          firstName,
          lastName,
          id,
          image,
          email,
          about,
          country,
          language,
          phoneNo,
          jobTitle,
          jobLevel,
          expYears,
        } = res.data;
        handleFristNameChange(firstName);
        handleLastNameChange(lastName);
        setidUser(id);
        setImage(image);
        setemail(email);
        handleAboutChange(about);
        handleCountryChange(country);
        handleLanguageChange(language);
        setPhoneNumber(phoneNo);
        handleJopTitleChange(jobTitle);
        setJopLevel(jobLevel);
        setExperience(expYears);
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [changeData]);

  return (
    <>
      <h2 className="text-[57px] font-bold text-[#374151] hidden lg:block">
        Profile
      </h2>

      <Banner />
      <div className="flex gap-5 min-h-[516px] flex-col lg:flex-row">
        <CardPerson
          firstName={firstName}
          lastName={lastName}
          idUser={id}
          image={image}
          setChangeData={setChangeData}
        />
        <div className="lg:w-[70%] mt-6 h-full mb-2">
          <Header
            currenSection={currenSection}
            onChange={setCurrenSection}
          />
          {currenSection === "Personal" && (
            <Personal
              firstName={firstName}
              email={email}
              firstNameValid={fristNameValid}
              setFirstName={handleFristNameChange}
              setfristNameValid={setfristNameValid}
              lastName={lastName}
              setLastName={handleLastNameChange}
              setLastNameValid={setlastNameValid}
              lastNameValid={lastNameValid}
            />
          )}
          {currenSection === "Experience" && (
            <Experience
              JopTitle={jopTitle}
              setJopTitletValid={setJopTitletValid}
              jopTitleValid={jopTitleValid}
              setJopTitle={handleJopTitleChange}
              JopLevel={joplevel}
              setExperience={setExperience}
              experience={experience}
              experienceValid={experienceValid}
              setExperienceValid={setExperienceValid}
            />
          )}
          {currenSection === "About" && (
            <About
              about={about}
              country={country}
              setCountry={handleCountryChange}
              countryValid={countryValid}
              setCountryValid={setCountryValid}
              language={language}
              languageValid={languageValid}
              setLanguage={handleLanguageChange}
              setLanguageValid={setLanguageValid}
              PhoneNumber={phoneNumber}
              setAbout={handleAboutChange}
              aboutValid={aboutValid}
              setAboutValid={setAboutValid}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MyProfile;

const Banner = () => {
  return (
    <div className="w-full h-[290px] rounded-md hidden lg:block">
      <img
        src={banner}
        alt="banner"
        className="w-full h-full overflow-hidden rounded-md"
      />
    </div>
  );
};
