import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "../api/axios";

const Context = createContext();
export const StoreContext = ({ children }) => {
  const [ShowAddCourse, setShowAddCourse] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [balance, setBalance] = useState(0);

  const cookis = new Cookies();
  const [token, setToken] = useState(
    cookis.get("token") || sessionStorage.getItem("token")
  );

  const decode = token && jwtDecode(token);
  const [user, setUser] = useState(decode?.name);
  const [Remember, setRemember] = useState(false);
  const [img, setImg] = useState(decode?.image);
  const [idUser, setId] = useState(decode?.sub);
  const [email, setEmail] = useState(decode?.email);
  const [role, setRole] = useState("");
  const HandleCookis = (jwt_token, exp) => {
    cookis.set("token", jwt_token, {
      expires: new Date(exp * 1000),
    });
  };

  const HandleSesstion = (token) => {
    sessionStorage.setItem("token", token);
  };

  const LogOut = () => {
    setUser(null);
    cookis.remove("token");
    sessionStorage.clear("token");
    setToken(null);
  };

  const Login = async (user, remember) => {
    try {
      const req = await axios.post("auth/login", user);
      const { token } = req.data;
      const decode = jwtDecode(token);
      setToken(token);

      if (remember) {
        HandleCookis(token, decode.exp);
      } else {
        HandleSesstion(token);
      }
      setUser(decode.name);
      setImg(decode.image);
      setId(decode.sub);
      setEmail(decode?.email);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setRole(decode?.role);
      return [true, decode?.role];
    } catch (err) {
      return false;
    }
  };
  const [navActive, setNavActive] = useState(true);

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return (
    <Context.Provider
      value={{
        ShowAddCourse,
        setShowAddCourse,
        showWithdraw,
        setShowWithdraw,
        balance,
        setBalance,
        Login,
        token,
        LogOut,
        img,
        idUser,
        user,
        email,
        role,
        navActive,
        setNavActive,
      }}>
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
