import React, { useCallback, useState } from "react";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";

export default function PieCom({
  pieData,
  innerRadius,
  outerRadius,
  width,
  height,
}) {
  const varbild = pieData;
  const data = Object.entries(varbild).map(([label, value], index) => ({
    label: label,
    value: value,
    color: getColorForIndex(index),
  }));
  function getColorForIndex(index) {
    const colors = ["#0455BA", "#374151", "#1F618D", "#74B0FF"];
    return colors[index % colors.length];
  }

  return (
    <Stack direction="row">
      <PieChart
        series={[
          {
            paddingAngle: 5,
            innerRadius,
            outerRadius,
            data,
          },
        ]}
        margin={{ right: 5 }}
        width={width}
        height={height}
        legend={{ hidden: true }}
      />
    </Stack>
  );
}
