import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { CourseDetails, Login, MyCourses, Overview } from "./pages";
import { StoreContext } from "./context/CreateContext";
import MyProfile from "./pages/MyProfile";
import EventCalendar from "./pages/EventCalendar";
import SignUp from "./pages/SignUp";
import RestPassword from "./pages/ResetPassword";
import Verify from "./pages/Verify";
import Notification from "./pages/Notification";
import FileUpload from "./pages/Uploadfile";
import Transactions from "./pages/Transaction";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Overview />,
      },
      {
        path: "MyCourses",
        element: <MyCourses />,
      },
      {
        path: "MyCourses/:id",
        element: <CourseDetails />,
      },
      {
        path: "MyProfile",
        element: <MyProfile />,
      },
      {
        path: "notification",
        element: <Notification />,
      },
      {
        path: "transaction",
        element: <Transactions />,
      },
      {
        path: "uploadFile",
        element: <FileUpload />,
      },
    ],
  },
  { path: "/login", element: <Login /> },
  { path: "/signUp", element: <SignUp /> },
  { path: "/reset_password/:token", element: <RestPassword /> },
  { path: "/verify/:token", element: <Verify /> },
  {
    path: "*",
    element: (
      <h2 className="text-[300px] font-bold flex items-center justify-center h-screen text-[#333]">
        404 Page Not Found
      </h2>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <StoreContext>
      <RouterProvider router={router} />
    </StoreContext>
  </React.StrictMode>
);
