import CircularUnderLoad from "../CircularUnderLoad";

const Button = ({ onCancle, onSave, title, isLoading }) => {
  return (
    <div className="lg:mt-3 mt-8 flex items-center gap-5 flex-col lg:flex-row text-[24px] lg:text-base">
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] bg-[#0455BA] font-semibold text-white flex items-center justify-center gap-2 hover:brightness-125 transition-all duration-300"
        onClick={onSave}
        id={"save" + title}>
        {title}
        {isLoading && <CircularUnderLoad />}
      </button>
      <button
        className="lg:w-[150px] w-full h-[50px] rounded-[4px] border-[#9CA3AF] border font-semibold text-[#374151] hover:brightness-50 transition-all duration-300"
        onClick={() => onCancle?.(false)}
        id={"Cancel" + title}>
        Cancel
      </button>
    </div>
  );
};

export default Button;
