import { useState } from "react";
import { useEffect } from "react";

const useSelectValidation = () => {
  const [select, setSelect] = useState("");
  const [selectValid, setSelectValid] = useState(true);
  const handleSelectChange = (e) => {
    const newTitle = e;
    setSelect(newTitle);
  };
  useEffect(() => {
    if (select) {
      setSelectValid(true);
    }
  }, [select]);
  return [select, selectValid, setSelectValid, handleSelectChange];
};
export default useSelectValidation;
