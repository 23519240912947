import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const InptAdd = ({ title, placeHolder, valid, add, setAdd }) => {
  const [value, setvalue] = useState("");
  const [onFocus, setOnFocus] = useState(false);

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const handleAdd = () => {
    if (value) {
      setAdd((e) => [...e, value]);
      setvalue("");
      setOnFocus(true);
    }
  };

  const handleFocus = () => {
    setOnFocus(true);
  };

  const handleBlur = () => {
    setOnFocus(false);
  };

  const handleButtonClick = () => {
    // Focus on the input when the button is clicked
    inputRef.current.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !containerRef.current.contains(event.target) &&
        !listRef?.current?.contains(event.target)
      ) {
        setOnFocus(false);
      } else {
        setOnFocus(true);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const handleDeleteItem = (indexToDelete) => {
    // Create a new array without the item at the specified index
    const newArray = add.filter((_, index) => index !== indexToDelete);

    // Update the state with the new array
    setAdd(newArray);
  };
  return (
    <div
      ref={containerRef}
      onFocus={handleFocus}
      className="py-3">
      <div className="mt-5">
        <p className="font-semibold text-[#374151]">{title}</p>
        <div
          className="h-[50px] border border-[#9CA3AF] rounded-[4px] flex items-center pr-4 mt-1 bg-white"
          onClick={handleButtonClick}>
          <input
            type="text"
            className="w-full h-full rounded-[4px] pl-5 placeholder:text-[#6B7280] outline-none"
            placeholder={placeHolder}
            onChange={(e) => setvalue(e.target.value)}
            value={value}
            ref={inputRef}
            id={title}
          />
          <button
            className="w-[70px] h-[29px] rounded-[4px] bg-[#1E69CB] text-[#F3F4F6] font-semibold"
            onClick={handleAdd}
            id={"add" + title}>
            Add
          </button>
        </div>
        {onFocus && add.length > 0 && (
          <div
            className="flex items-start flex-col bg-[#FFFFFF] border border-[#374151] rounded-[4px] mt-2 py-3 px-3 gap-5 h-[118px] overflow-scroll add"
            onFocus={handleFocus}
            ref={listRef}>
            {add.map((data, i) => (
              <div className="flex justify-between w-full ">
                <label class="custom-checkbox flex items-center gap-3 text-[#2F2F2F]">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <div className="w-[10px] h-[10px] bg-[#0455BA] rounded-full"></div>
                  <p className="truncate ">{data}</p>
                </label>{" "}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                  onClick={() => handleDeleteItem(i)}>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 6H21C21.55 6 22 6.45 22 7C22 7.55 21.55 8 21 8H20V19C20 20.654 18.654 22 17 22H7C5.346 22 4 20.654 4 19V8H3C2.45 8 2 7.55 2 7C2 6.45 2.45 6 3 6H4H8V4.328C8 3.044 9.121 2 10.5 2H13.5C14.879 2 16 3.044 16 4.328V6H20ZM10.5 4C10.214 4 10 4.173 10 4.328V6H14V4.328C14 4.173 13.786 4 13.5 4H10.5ZM15 17C15.55 17 16 16.55 16 16V12C16 11.45 15.55 11 15 11C14.45 11 14 11.45 14 12V16C14 16.55 14.45 17 15 17ZM10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16Z"
                    fill="#DD0303"
                  />
                </svg>
              </div>
            ))}
          </div>
        )}
        {!onFocus && add?.length > 0 && (
          <div className="h-[35px] rounded-[4px] border border-[#374151] bg-white mt-2 px-2 flex items-center justify-between">
            {add.map((data) => (
              <div className="flex justify-between w-full">
                <div className="flex items-center gap-1 w-1/2">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.3659 0.234412C11.5159 0.384435 11.6002 0.587881 11.6002 0.800012C11.6002 1.01214 11.5159 1.21559 11.3659 1.36561L4.96593 7.76561C4.8159 7.91559 4.61246 7.99984 4.40033 7.99984C4.18819 7.99984 3.98475 7.91559 3.83473 7.76561L0.634726 4.56561C0.488999 4.41473 0.408364 4.21265 0.410186 4.00289C0.412009 3.79313 0.496144 3.59248 0.644471 3.44416C0.792797 3.29583 0.993447 3.2117 1.2032 3.20987C1.41296 3.20805 1.61504 3.28869 1.76593 3.43441L4.40033 6.06881L10.2347 0.234412C10.3847 0.0844355 10.5882 0.000183105 10.8003 0.000183105C11.0125 0.000183105 11.2159 0.0844355 11.3659 0.234412Z"
                      fill="#40A726"
                    />
                  </svg>
                  <p className="text-[12px] text-[#374151] truncate">{data}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {!valid && (
        <div className="flex items-center mt-1 gap-2">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.83301 11C1.83301 5.93723 5.93692 1.83331 10.9997 1.83331C16.0624 1.83331 20.1663 5.93723 20.1663 11C20.1663 16.0627 16.0624 20.1666 10.9997 20.1666C5.93692 20.1666 1.83301 16.0627 1.83301 11ZM11.9163 11.9166C11.9163 12.4226 11.5057 12.8333 10.9997 12.8333C10.4937 12.8333 10.083 12.4226 10.083 11.9166V7.33331C10.083 6.82731 10.4937 6.41665 10.9997 6.41665C11.5057 6.41665 11.9163 6.82731 11.9163 7.33331V11.9166ZM10.9997 15.5833C10.4937 15.5833 10.083 15.1726 10.083 14.6666C10.083 14.1606 10.4937 13.75 10.9997 13.75C11.5057 13.75 11.9163 14.1606 11.9163 14.6666C11.9163 15.1726 11.5057 15.5833 10.9997 15.5833Z"
              fill="#DD0303"
            />

            <g mask="url(#mask0_972_11061)"></g>
          </svg>
          <p className="text-[12px] text-[#DD0303] font-semibold">{`Please, Enter a valid ${title}`}</p>
        </div>
      )}
    </div>
  );
};
export default InptAdd;
