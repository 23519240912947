import LinearWithValueLabel from "../Progress";

const File = ({ file, index, deleteItem }) => {
  return (
    <div className="flex items-center justify-between gap-2 lg:bg-[#E5E7EB] bg-[#F5F5F5] px-5">
      <div className="flex gap-2 items-center mb-1 w-full">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.12 2.91L24.675 9.16C24.8837 9.39 25 9.68875 25 10V24.375C25 26.0975 23.5675 27.5 21.805 27.5H8.195C6.43375 27.5 5 26.0975 5 24.375V5.625C5 3.9025 6.43375 2.5 8.195 2.5H18.195C18.5475 2.5 18.8837 2.64875 19.12 2.91ZM17.5 8.9375C17.5 9.52375 17.9 10 18.3925 10H22.1775L17.5 4.9725V8.9375ZM11.25 22.5H18.75C19.4413 22.5 20 21.94 20 21.25C20 20.56 19.4413 20 18.75 20H11.25C10.56 20 10 20.56 10 21.25C10 21.94 10.56 22.5 11.25 22.5ZM11.25 15H15C15.69 15 16.25 15.56 16.25 16.25C16.25 16.94 15.69 17.5 15 17.5H11.25C10.56 17.5 10 16.94 10 16.25C10 15.56 10.56 15 11.25 15Z"
            fill="#1F618D"
          />

          <g mask="url(#mask0_368_16402)"></g>
        </svg>
        <div className="mb-1 w-full">
          <div className="flex items-center justify-between ">
            <p className="text-[#4B5563]">{file?.name}</p>
          </div>
          <LinearWithValueLabel />
        </div>
      </div>

      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => deleteItem(index)}
        className="cursor-pointer"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25 7.5H26.25C26.9375 7.5 27.5 8.0625 27.5 8.75C27.5 9.4375 26.9375 10 26.25 10H25V23.75C25 25.8175 23.3175 27.5 21.25 27.5H8.75C6.6825 27.5 5 25.8175 5 23.75V10H3.75C3.0625 10 2.5 9.4375 2.5 8.75C2.5 8.0625 3.0625 7.5 3.75 7.5H5H10V5.41C10 3.805 11.4012 2.5 13.125 2.5H16.875C18.5987 2.5 20 3.805 20 5.41V7.5H25ZM13.125 5C12.7675 5 12.5 5.21625 12.5 5.41V7.5H17.5V5.41C17.5 5.21625 17.2325 5 16.875 5H13.125ZM18.75 21.25C19.4375 21.25 20 20.6875 20 20V15C20 14.3125 19.4375 13.75 18.75 13.75C18.0625 13.75 17.5 14.3125 17.5 15V20C17.5 20.6875 18.0625 21.25 18.75 21.25ZM12.5 20C12.5 20.6875 11.9375 21.25 11.25 21.25C10.5625 21.25 10 20.6875 10 20V15C10 14.3125 10.5625 13.75 11.25 13.75C11.9375 13.75 12.5 14.3125 12.5 15V20Z"
          fill="#DD0303"
        />

        <g mask="url(#mask0_368_16412)"></g>
      </svg>
    </div>
  );
};

export default File;
