import React, { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import { useStateContext } from "../../context/CreateContext";
import { courseUrl } from "../../utils";

const Notifications = () => {
  const { idUser } = useStateContext();
  const [notifys, setNotifys] = useState([]);
  const [read, setRead] = useState(false);
  const [notiActive, setNotiActive] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (notiActive && ref.current && !ref.current.contains(e.target)) {
        setNotiActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });
  useEffect(() => {
    const getNotify = async () => {
      try {
        const res = await axios.get(`api/teachers/${idUser}/notify`);
        setNotifys(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getNotify();
  }, [read]);
  const activeNoti = notifys.filter((notify) => notify.read === false);
  const readNotiy = async () => {
    setNotiActive(true);
    try {
      const res = await axios.get(`api/teachers/${idUser}/read-notify`);
      setRead((e) => !e);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        className="inline-flex items-center justify-center gap-[10px] cursor-pointer"
        onClick={readNotiy}>
        <img
          className="relative w-[35px] h-[38.89px] "
          alt="Union"
          src="https://c.animaapp.com/CK2N3Nhv/img/union.svg"
        />
        <div className="absolute top-[-11px] left-[25px] font-label-siemb-20  text-[#eb3b3b]">
          {activeNoti?.length}
        </div>
      </div>
      <div
        ref={ref}
        onClick={() => setNotiActive(false)}
        className={`absolute top-[50px] rounded-[4px] bg-white shadow-lg z-10 right-0 w-[400px] ${
          notiActive ? "block" : "hidden"
        }`}>
        <div class="arrow-up"></div>
        <div className="h-[73px] w-full flex items-center justify-center bg-[#2D78DB] rounded-tl-[4px] rounded-tr-[4px] relative">
          <h3 className="text-xl font-semibold text-white">Notifications</h3>
        </div>
        <div className="w-full h-[30rem] overflow-y-scroll">
          {notifys.length > 0 &&
            notifys.map((notify) => (
              <div
                key={notify?._id}
                className="flex items-start gap-2 mt-3 px-2 border-b border-[#D1D5DB] pb-1">
                <div className="inline-flex items-center justify-center gap-[10px] relative ">
                  <img
                    src={courseUrl + notify?.senderImg}
                    className="w-[50px]  h-[50px] rounded-full max-w-none"
                    alt={notify?.senderName}
                  />
                </div>

                <div className=" text-[#374151]">
                  <p className="font-semibold"> {notify?.senderName}</p>
                  <p className="text-[#374151] text-[10px]">
                    {notify?.notifyContent}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Notifications;
