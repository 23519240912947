const Header = ({ currenSection, onChange }) => {
  const List = ["Personal", "About", "Experience"];
  return (
    <div className="flex items-center gap-5">
      {List.map((sec) => (
        <div
          className="w-1/3 cursor-pointer"
          onClick={() => onChange(sec)}
          key={sec}
          id={sec}
        >
          <p className="text-[20px] font-semibold text-center text-[#374151]">
            {sec}
          </p>
          <div
            className={`h-[4px] w-full ${
              sec == currenSection ? "bg-[#438EF2]" : "bg-[#D1D5DB]"
            }  rounded-[4px] mt-2`}
          ></div>
        </div>
      ))}
    </div>
  );
};
export default Header;
