import React from "react";
import { useStateContext } from "../../context/CreateContext";
import { useState } from "react";

import UploadImage from "../Lessons/UploadImage";
import File from "../Lessons/File";
import Button from "../Lessons/Button";
import Input from "../Lessons/Input";
import axios from "../../api/axios";
import { useTitleValidation } from "../../hooks";
import { useParams } from "react-router-dom";
import useScoreValidation from "../../hooks/useScoreValidation";
import s3 from "../../utils/aws";

const AddFeedback = ({ onCancle, submitId, setchangeData }) => {
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const { idUser } = useStateContext();
  const [loading, setIsLoading] = useState(false);
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();

  const [score, scoreValid, setScoreValid, handleScoreChange] =
    useScoreValidation();
  const [filesValid, setFilesValid] = useState(true);

  const handleSubmit = () => {
    // Reset validation

    // Check for errors
    if (!title) {
      setTitleValid(false);
    }
    if (!score || score > 100) {
      setScoreValid(false);
    }
    if (files.length <= 0) {
      setFilesValid(false);
    }
    if (!title || !score || files.length <= 0) {
      return;
    }

    if (titleValid && scoreValid && filesValid) {
      createFeedBack();
    }
  };

  const createFeedBack = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("score", score);
    bodyFormData.append("teacherId", idUser);
    bodyFormData.append("submitId", submitId);
    bodyFormData.append("file", files[0].name);
    try {
      setIsLoading(true);
      const s3Params = {
        Bucket: process.env.REACT_APP_S3_BUCKET + "/files/feedback",
        Key: files[0].name,
        Body: files[0],
      };
      await s3.upload(s3Params).promise();
      const res = await axios.post(
        `/api/courses/${id}/feedback`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);

      onCancle(false);
      setchangeData((e) => !e);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const deleteItem = (index) => {
    // Create a new array without the item at the specified index
    const updatedItems = [...files.slice(0, index), ...files.slice(index + 1)];

    // Update the state with the new array
    setFiles(updatedItems);
  };
  return (
    <div className="rounded-[4px]  add__course w-full lg:w-[729px] lg:min-h-[343px] h-full  lg:h-fit flex flex-col max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed ">
      <div className="lg:hidden items-center justify-between mb-10 flex">
        <h2 className="text-[28px] font-semibold text-[#1E69CB]">
          Add Feedback
        </h2>{" "}
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => onCancle(false)}
          className="cursor-pointer">
          <path
            d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
            fill="#374151"
          />

          <g mask="url(#mask0_1240_20873)"></g>
        </svg>
      </div>
      <div>
        <div className="bg-[#E5E7EB] lg:bg-[#F5F5F5] px-4 pt-4 pb-6 lg:px-0 lg:py-0 rounded-[10px] ">
          <Input
            title={"Title"}
            placeHolder={"Enter your title"}
            onChange={handleTitleChange}
            valid={titleValid}
            setValid={setTitleValid}
            value={title}
          />
          <Input
            title={"Score"}
            typeN={"number"}
            placeHolder={"Enter Student Score"}
            onChange={handleScoreChange}
            valid={scoreValid}
            setValid={setScoreValid}
            value={score}
          />
        </div>
        <div>
          <UploadImage
            setFiles={setFiles}
            valid={filesValid}
            setValid={setFilesValid}
            onFileChange={setFiles}
          />
          {files.length > 0 && (
            <div className="flex flex-col gap-8 mt-5 mb-5 ">
              {files.map((file, index) => (
                <File
                  file={file}
                  index={index}
                  deleteItem={deleteItem}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Button
        isLoading={loading}
        setShowAddCourse={onCancle}
        handleSubmit={handleSubmit}
        id={"AddFeedback"}
      />
    </div>
  );
};

export default AddFeedback;
