// export const titleRegex = /^(?!\s+$|\d+|[^\w\s])[\w\s]+$/;
// export const titleRegex = /^[a-zA-Z0-9]["'a-zA-Z0-9@#$%^&*()_+-= ]*$/;
export const titleRegex =
  /^[a-zA-Z0-9\u0600-\u06FF]["'!a-zA-Z0-9\u0600-\u06FF@#$%^&*()_+=:;,. -/]*$/;
// export const titleRegex =
//   /^[a-zA-Z0-9\u0600-\u06FF]["'!a-zA-Z0-9\u0600-\u06FF@#$%^&*()_+-= ]*$/;

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// export const priceReg = /^(?!(?:0|0\.0|0\.00)$)[+]?\d+(\.\d|\.\d[0-9])?$/;
export const priceReg = /^[+]?\d+(\.\d+)?$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
