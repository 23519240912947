import React, { useState } from "react";
import { IoStar, IoStarOutline } from "react-icons/io5";
import image from "../assets/images.jpg";
import { TbWorld } from "react-icons/tb";
import { Link } from "react-router-dom";
import DeleteMessage from "./DeleteMessage/DeleteMessage";
import { useStateContext } from "../context/CreateContext";
import axios from "../api/axios";
import { courseUrl } from "../utils";

const CourseCard = ({ course, setChangeData }) => {
  const [state, setstate] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);

  const HandleDelete = async (id) => {
    try {
      const res = await axios.delete(`api/courses/${id}`);
      setDeleteMessage(false);
      setChangeData((e) => !e);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {deleteMessage && (
        <>
          <div className="w-full h-full z-10 absolute top-0 left-0  overview"></div>
          <DeleteMessage
            title={`Are you sure about to delete this Course (${course.title})`}
            bref={"If you delete this file you can’t receive this file again"}
            nameButton={"Delete"}
            onCancle={setDeleteMessage}
            onDelete={() => HandleDelete(course.id)}
          />
        </>
      )}
      <Link
        to={`${course.id}`}
        className="test"
        id={course.id}>
        <div
          className="w-[200px] h-[250px] rounded-md bg-white shadow-md relative cursor-pointer "
          onMouseEnter={() => setstate(true)}
          onMouseLeave={() => setstate(false)}
          onClick={() => setstate(true)}>
          <div className="w-full h-[120px] overflow-hidden rounded-tl-md rounded-tr-md">
            <img
              src={`${courseUrl}${course.image}?${new Date().getTime()}`}
              alt="Course img"
              className="w-full h-full overflow-hidden"
            />
          </div>
          <div className="mx-auto flex-col flex justify-between h-[130px] py-1 w-[180px]">
            <Text
              title={course.title}
              bref={course.subtitle}
            />
            <Rate
              rate={course.rateNo}
              price={course.price}
            />
          </div>

          <div className="w-[25px] h-[25px] bg-white rounded-full flex items-center justify-center absolute top-2 right-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.4026 7.33671L19.4036 7.33771C20.1686 8.1037 20.1996 9.32171 19.4716 10.0517L10.4746 19.0487C10.1496 19.3737 9.71861 19.5747 9.26061 19.6167L5.09061 19.9957C5.06061 19.9987 5.03061 19.9997 4.99961 19.9997C4.73661 19.9997 4.48161 19.8957 4.29261 19.7067C4.08261 19.4967 3.97761 19.2047 4.00361 18.9097L4.38261 14.7397C4.42461 14.2827 4.62661 13.8507 4.95261 13.5257L13.9486 4.52971C14.6506 3.82471 15.9236 3.85971 16.6646 4.59871L19.4026 7.33671ZM13.3236 7.98371L16.0186 10.6787L17.9656 8.72871L15.2716 6.03471L13.3236 7.98371Z"
                fill="#40A726"
              />
            </svg>
          </div>
          {state && (
            <Details
              setstate={setstate}
              title={course.title}
              bref={course.subtitle}
              rate={course.rateNo}
              price={course.price}
              overAllRate={course.overAllRate}
              totalEnrolls={course.totalEnrolls}
              lan={course.language}
              lastUpdate={course.lastUpdate}
              id={course.id}
              setDeleteMessage={setDeleteMessage}
            />
          )}
        </div>
      </Link>
    </>
  );
};

export default CourseCard;

const Text = ({ title, bref, totalEnrolls }) => {
  return (
    <div className="text-[#374151]">
      <h3 className="font-semibold text-start max-h-[45px] overflow-hidden text-ellipsis break-all">
        {title}
      </h3>
      <p className="text-[8px] mt-2">{bref}</p>
    </div>
  );
};

const Rate = ({ rate, price }) => {
  return (
    <div className="flex items-center justify-between ">
      <div className="text-[#F6C208] flex items-center gap-1">
        <IoStar className="w-[17px] h-[16px]" />
        <p>{rate}</p>
      </div>
      <p className="text-lg text-[#40A726] font-medium">E${price}</p>
    </div>
  );
};

const Details = ({
  setstate,
  title,
  bref,
  lan,
  lastUpdate,
  totalEnrolls,
  id,
  setDeleteMessage,
  overAllRate,
  rate,
}) => {
  return (
    <div
      className="absolute top-[-10%] right-[98%] z-10 bg-white w-[233px] h-[281px] shadow-md px-3 rounded-md py-1 details"
      onMouseEnter={() => setstate(true)}
      onMouseLeave={() => setstate(false)}>
      <div className="absolute top-[30%] right-[-5%] right rotate-180">
        <svg
          width="12"
          height="30"
          viewBox="0 0 12 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 30V0L0.999512 13.7506C0.415162 14.481 0.415164 15.519 0.999513 16.2494L12 30Z"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="absolute top-[30%]  right-[-5%] hidden">
        <svg
          width="12"
          height="30"
          viewBox="0 0 12 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 30V0L11.0005 13.7506C11.5848 14.481 11.5848 15.519 11.0005 16.2494L0 30Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="flex flex-col justify-between h-full py-2">
        <div className="text-[#374151]">
          <h3 className="font-semibold text-start">{title}</h3>
          <p className="text-[10px] mt-1">{bref}</p>
        </div>

        <div>
          <div className="flex items-center mt-1 gap-2">
            <StarRating rate={rate} />
            <p className="text-[10px] text-[#374151] font-semibold">
              ( {overAllRate} rating )
            </p>
          </div>

          <div className="text-[10px] text-[#374151] font-semibold mt-2">
            {/* <p>1450, Students</p> */}
            <p>Income, {totalEnrolls}$</p>
          </div>
          <div className="flex items-center gap-1 mt-2">
            <TbWorld className="w-[10px] h-[10px]" />
            <p className="text-[10px] text-[#374151] font-semibold ">{lan}</p>
          </div>
          <p className="text-[10px] text-[#374151] font-semibold mt-2 ">
            {lastUpdate}
          </p>

          <div className="mt-1 flex items-center justify-between">
            <Link to={`${id}`}>
              <button className="w-[169px] h-[36px] rounded-md bg-[#1E69CB] flex items-center justify-center gap-1 text-[12px] font-semibold text-[#F5F5F5]">
                View Course
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.16667 6L11.1667 8M11.1667 8L9.16667 10M11.1667 8H5.83333M14.5 8C14.5 8.78793 14.3448 9.56815 14.0433 10.2961C13.7417 11.0241 13.2998 11.6855 12.7426 12.2426C12.1855 12.7998 11.5241 13.2417 10.7961 13.5433C10.0681 13.8448 9.28793 14 8.5 14C7.71207 14 6.93185 13.8448 6.2039 13.5433C5.47595 13.2417 4.81451 12.7998 4.25736 12.2426C3.70021 11.6855 3.25825 11.0241 2.95672 10.2961C2.65519 9.56815 2.5 8.78793 2.5 8C2.5 6.4087 3.13214 4.88258 4.25736 3.75736C5.38258 2.63214 6.9087 2 8.5 2C10.0913 2 11.6174 2.63214 12.7426 3.75736C13.8679 4.88258 14.5 6.4087 14.5 8Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Link>

            <button
              className="w-[34px] rounded-md h-[36px] bg-[#DD0303] flex items-center justify-center"
              onClick={() => setDeleteMessage(true)}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.666 3.49999H12.2493C12.5702 3.49999 12.8327 3.76249 12.8327 4.08332C12.8327 4.40416 12.5702 4.66666 12.2493 4.66666H11.666V11.0833C11.666 12.0482 10.8808 12.8333 9.91602 12.8333H4.08268C3.11785 12.8333 2.33268 12.0482 2.33268 11.0833V4.66666H1.74935C1.42852 4.66666 1.16602 4.40416 1.16602 4.08332C1.16602 3.76249 1.42852 3.49999 1.74935 3.49999H2.33268H4.66602V2.52466C4.66602 1.77566 5.31993 1.16666 6.12435 1.16666H7.87435C8.67877 1.16666 9.33268 1.77566 9.33268 2.52466V3.49999H11.666ZM6.12435 2.33332C5.95752 2.33332 5.83268 2.43424 5.83268 2.52466V3.49999H8.16602V2.52466C8.16602 2.43424 8.04118 2.33332 7.87435 2.33332H6.12435ZM8.74935 9.91666C9.07018 9.91666 9.33268 9.65416 9.33268 9.33332V6.99999C9.33268 6.67916 9.07018 6.41666 8.74935 6.41666C8.42852 6.41666 8.16602 6.67916 8.16602 6.99999V9.33332C8.16602 9.65416 8.42852 9.91666 8.74935 9.91666ZM5.83268 9.33332C5.83268 9.65416 5.57018 9.91666 5.24935 9.91666C4.92852 9.91666 4.66602 9.65416 4.66602 9.33332V6.99999C4.66602 6.67916 4.92852 6.41666 5.24935 6.41666C5.57018 6.41666 5.83268 6.67916 5.83268 6.99999V9.33332Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const StarRating = ({ rate }) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <div className="flex items-center text-[#F6C208] gap-1">
      {stars.map((starNumber) =>
        starNumber <= rate ? (
          <IoStar
            key={starNumber}
            className={"w-[14px] h-[14px]"}
          />
        ) : (
          <IoStarOutline
            key={starNumber}
            className={"w-[14px] h-[14px]"}
          />
        )
      )}
    </div>
  );
};
