const LessonNumber = ({ lessonNo, setLessonNo, file }) => {
  return (
    <div className="mt-5">
      <p className="font-semibold text-[#374151]">
        {file ? "File Number" : "Lesson Number"}
      </p>
      <div className="w-full h-[50px] border border-[#9CA3AF] rounded-[4px] mt-1 flex items-center justify-between">
        <div
          className="w-[50px] h-[50px] bg-[#0455BA] flex items-center justify-center rounded-tl-[4px] rounded-bl-[4px] cursor-pointer"
          onClick={() => setLessonNo((e) => (e > 1 ? e - 1 : e))}
          id="LessonNumber dec">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.5 15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5L9 16.5C8.60218 16.5 8.22064 16.342 7.93934 16.0607C7.65804 15.7794 7.5 15.3978 7.5 15C7.5 14.6022 7.65804 14.2206 7.93934 13.9393C8.22065 13.658 8.60218 13.5 9 13.5L21 13.5C21.3978 13.5 21.7794 13.658 22.0607 13.9393C22.342 14.2206 22.5 14.6022 22.5 15Z"
              fill="#F3F4F6"
            />
          </svg>
        </div>
        <div className="bg-white w-full h-full flex items-center justify-center">
          <p className="text-[22px] font-semibold text-[#374151]">{lessonNo}</p>
        </div>

        <div
          className="w-[50px] h-[50px] bg-[#0455BA] flex items-center justify-center rounded-tr-[4px] rounded-br-[4px] cursor-pointer"
          onClick={() => setLessonNo((e) => e + 1)}
          id="LessonNumber inc">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5 15C7.5 14.6022 7.65804 14.2206 7.93934 13.9393C8.22064 13.658 8.60217 13.5 9 13.5L13.5 13.5L13.5 9C13.5 8.60218 13.658 8.22064 13.9393 7.93934C14.2206 7.65803 14.6022 7.5 15 7.5C15.3978 7.5 15.7794 7.65803 16.0607 7.93934C16.342 8.22064 16.5 8.60218 16.5 9L16.5 13.5L21 13.5C21.3978 13.5 21.7794 13.658 22.0607 13.9393C22.342 14.2206 22.5 14.6022 22.5 15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5L16.5 16.5L16.5 21C16.5 21.3978 16.342 21.7794 16.0607 22.0607C15.7794 22.342 15.3978 22.5 15 22.5C14.6022 22.5 14.2206 22.342 13.9393 22.0607C13.658 21.7794 13.5 21.3978 13.5 21L13.5 16.5L9 16.5C8.60217 16.5 8.22064 16.342 7.93934 16.0607C7.65804 15.7794 7.5 15.3978 7.5 15Z"
              fill="#F3F4F6"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LessonNumber;
