import React from "react";
import { listCatogray, listLanguage } from "../../utils";
import Input from "./Input";
import Select from "./Select";
import UploadImage from "./UploadImage";
import Button from "./Button";
import { useStateContext } from "../../context/CreateContext";

const CouresInfo = ({
  title,
  setTitle,
  subtitle,
  setSubtitle,
  language,
  setLanguage,
  category,
  setCategory,
  titleValid,
  subtitleValid,
  languageValid,
  categoryValid,
  uploadFile,
  imageValid,
  setUploadFile,
  handleSubmit,
  setImageValid,
}) => {
  const { setShowAddCourse } = useStateContext();

  return (
    <div className="max-h-[856px] overflow-y-auto">
      <div className=" bg-[#E5E7EB] px-4 py-4 lg:px-0 lg:py-0 rounded-[10px] mt-5 lg:bg-[#F5F5F5]">
        <Input
          title={"Title"}
          placeHolder={"Enter your title"}
          value={title}
          onChange={setTitle}
          valid={titleValid}
        />
        <Input
          title={"Subtitle"}
          placeHolder={"Enter your subtitile"}
          value={subtitle}
          onChange={setSubtitle}
          valid={subtitleValid}
        />
        <Select
          title={"Language"}
          placeHolder={"Choose your language"}
          value={language}
          onChange={setLanguage}
          valid={languageValid}
          listSelect={listLanguage}
        />
        <Select
          title={"Category"}
          placeHolder={"Choose your Categories"}
          value={category}
          onChange={setCategory}
          valid={categoryValid}
          listSelect={listCatogray}
        />
        <UploadImage
          valid={imageValid}
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          setValid={setImageValid}
        />
      </div>
      <Button
        title={"Next"}
        onSave={handleSubmit}
        onCancle={setShowAddCourse}
      />
    </div>
  );
};

export default CouresInfo;
