import React, { useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { handleDate } from "../../utils";
const DateRangePhone = ({
  showFilter,
  setshowFilter,
  showCalender,
  setshowCalender,
  openDate,
  setOpenDate,
  deadline,
  setDeadline,
  openDateSave,
  setOpenDateSave,
  deadlinSave,
  setDeadlinSave,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const [showDate, setshowDate] = useState(false);
  const [showOpenDate, setShowOpenDate] = useState(false);
  const [showDeadline, setShowDeadline] = useState(false);

  const selectStyle =
    "text-[12px] text-[#374151] w-full font-semibold hover:bg-[#E5E7EB] hover:text-[#0455BA] py-2 px-2 cursor-pointer";
  return (
    <>
      {showCalender && (
        <div className="mt-5 z-30 w-[100%] min-h-[200px] bg-white shadow-lg rounded-md flex flex-col justify-between right-0">
          <div className="w-full h-[39px] bg-[#1E69CB] flex items-center justify-center rounded-tl-md rounded-tr-md">
            <p className="text-[#F5F5F5] font-semibold">Schedule </p>
          </div>
          <div className="px-4 ">
            <Date
              showOpenDate={setShowOpenDate}
              showDeadline={setShowDeadline}
              setShowCalender={setshowCalender}
              openDate={openDateSave}
              deadlinSave={deadlinSave}
              setDeadlinSave={setDeadlinSave}
            />
            {showOpenDate && (
              <Calender
                setShowCalender={setShowOpenDate}
                onChange={setOpenDate}
                date={openDate}
                setOpenDateSave={setOpenDateSave}
              />
            )}
            {showDeadline && (
              <Calender
                setShowCalender={setShowDeadline}
                onChange={setDeadline}
                date={deadline}
                setOpenDateSave={setDeadlinSave}
              />
            )}
          </div>

          <Button onCancle={setshowCalender} />
        </div>
      )}
    </>
  );
};

export default DateRangePhone;

const Date = ({
  setShowCalender,
  openDate,
  deadline,
  showDeadline,
  showOpenDate,
  deadlinSave,
}) => {
  return (
    <div className="flex items-center justify-between  gap-5 mt-6 mb-2">
      <div className="w-1/2">
        <div
          className="flex items-center justify-between h-[40px] border border-[#9CA3AF] rounded-[4px] px-2 cursor-pointer"
          onClick={() => {
            showDeadline(false);
            showOpenDate(true);
          }}>
          <p className="text-sm text-[#374151] font-semibold">Open Date</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.20002 2.39999C6.88176 2.39999 6.57654 2.52642 6.3515 2.75147C6.12645 2.97651 6.00002 3.28173 6.00002 3.59999V4.79999H4.80002C4.1635 4.79999 3.55306 5.05285 3.10297 5.50294C2.65288 5.95302 2.40002 6.56347 2.40002 7.19999V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.897C3.55306 21.3471 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3471 20.8971 20.897C21.3472 20.447 21.6 19.8365 21.6 19.2V7.19999C21.6 6.56347 21.3472 5.95302 20.8971 5.50294C20.447 5.05285 19.8365 4.79999 19.2 4.79999H18V3.59999C18 3.28173 17.8736 2.97651 17.6486 2.75147C17.4235 2.52642 17.1183 2.39999 16.8 2.39999C16.4818 2.39999 16.1765 2.52642 15.9515 2.75147C15.7265 2.97651 15.6 3.28173 15.6 3.59999V4.79999H8.40002V3.59999C8.40002 3.28173 8.2736 2.97651 8.04855 2.75147C7.82351 2.52642 7.51828 2.39999 7.20002 2.39999ZM7.20002 8.39999C6.88176 8.39999 6.57654 8.52642 6.3515 8.75147C6.12645 8.97651 6.00002 9.28173 6.00002 9.59999C6.00002 9.91825 6.12645 10.2235 6.3515 10.4485C6.57654 10.6736 6.88176 10.8 7.20002 10.8H16.8C17.1183 10.8 17.4235 10.6736 17.6486 10.4485C17.8736 10.2235 18 9.91825 18 9.59999C18 9.28173 17.8736 8.97651 17.6486 8.75147C17.4235 8.52642 17.1183 8.39999 16.8 8.39999H7.20002Z"
              fill="black"
            />
          </svg>
        </div>
        <p className="text-[10px] font-semibold text-[#374151] mt-2 ml-2">
          {openDate ? openDate : "MM-DD-YYYY"}
        </p>
      </div>
      <div className="w-1/2">
        <div
          className="flex items-center justify-between h-[40px] border border-[#9CA3AF] rounded-[4px] px-2 cursor-pointer"
          onClick={() => {
            showDeadline(true);
            showOpenDate(false);
          }}>
          <p className="text-sm text-[#374151] font-semibold">Dedline</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.20002 2.39999C6.88176 2.39999 6.57654 2.52642 6.3515 2.75147C6.12645 2.97651 6.00002 3.28173 6.00002 3.59999V4.79999H4.80002C4.1635 4.79999 3.55306 5.05285 3.10297 5.50294C2.65288 5.95302 2.40002 6.56347 2.40002 7.19999V19.2C2.40002 19.8365 2.65288 20.447 3.10297 20.897C3.55306 21.3471 4.1635 21.6 4.80002 21.6H19.2C19.8365 21.6 20.447 21.3471 20.8971 20.897C21.3472 20.447 21.6 19.8365 21.6 19.2V7.19999C21.6 6.56347 21.3472 5.95302 20.8971 5.50294C20.447 5.05285 19.8365 4.79999 19.2 4.79999H18V3.59999C18 3.28173 17.8736 2.97651 17.6486 2.75147C17.4235 2.52642 17.1183 2.39999 16.8 2.39999C16.4818 2.39999 16.1765 2.52642 15.9515 2.75147C15.7265 2.97651 15.6 3.28173 15.6 3.59999V4.79999H8.40002V3.59999C8.40002 3.28173 8.2736 2.97651 8.04855 2.75147C7.82351 2.52642 7.51828 2.39999 7.20002 2.39999ZM7.20002 8.39999C6.88176 8.39999 6.57654 8.52642 6.3515 8.75147C6.12645 8.97651 6.00002 9.28173 6.00002 9.59999C6.00002 9.91825 6.12645 10.2235 6.3515 10.4485C6.57654 10.6736 6.88176 10.8 7.20002 10.8H16.8C17.1183 10.8 17.4235 10.6736 17.6486 10.4485C17.8736 10.2235 18 9.91825 18 9.59999C18 9.28173 17.8736 8.97651 17.6486 8.75147C17.4235 8.52642 17.1183 8.39999 16.8 8.39999H7.20002Z"
              fill="black"
            />
          </svg>
        </div>
        <p className="text-[10px] font-semibold text-[#374151] mt-2 mr-2 text-end">
          {deadlinSave ? deadlinSave : "MM-DD-YYYY"}
        </p>
      </div>
    </div>
  );
};
const Calender = ({ setShowCalender, date, onChange, setOpenDateSave }) => {
  const HandleChangeDate = (e) => {
    const formattedDate = handleDate(e);
    onChange(formattedDate);
  };

  const HandleSave = () => {
    setOpenDateSave(date);
    setShowCalender(false);
  };
  const [state, setState] = useState("");
  return (
    <div className="flex items-end justify-center border border-[#9CA3AF] rounded-[4px] mb-2 mt-1 flex-col overflow-hidden  pb-1 pt-1 font-semibold text-sm">
      <div className="flex items-center h-[300px] w-full justify-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar"]}>
            <DemoItem>
              <DateCalendar
                sx={{ width: "100%" }}
                views={["year", "month", "day", "hours", "minutes"]}
                format="YYYY-MM-DD"
                onChange={HandleChangeDate}
                disablePast
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>

      <div className="flex items-center gap-3 mr-6 mb-2 ">
        <button onClick={() => setShowCalender(false)}>Cancel</button>
        <button onClick={HandleSave}>ok</button>
      </div>
    </div>
  );
};

const Button = ({ onSave, onCancle }) => {
  return (
    <div className="flex items-center gap-3 justify-end pr-5 border-t sborder-[#37415175] pt-2 pb-2">
      <button
        id="closeDateMoblie"
        onClick={() => onCancle(false)}
        className="text-[12px] font-semibold">
        Cancel
      </button>
      <button
        id="saveDateMoblie"
        className="w-[70px] h-[30px] bg-[#0455BA] text-white rounded-md text-[12px] font-semibold"
        onClick={() => onCancle(false)}>
        ok
      </button>
    </div>
  );
};
