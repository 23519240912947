import React from "react";
import Input from "./Input";
import { useState } from "react";
import Button from "./Button";
import DatePickerCom from "../Calender/DatePicker";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import LessonNumber from "./LessonNumber";
import { useTitleValidation } from "../../hooks";

const AddLesson = ({ setShowAddLesson, setchangeData }) => {
  const { id } = useParams();

  const [files, setFiles] = useState([]);
  // const [description, setDescription] = useState("");
  const [filesValid, setFilesValid] = useState(true);
  // const [descriptionValid, setDescriptionValid] = useState(true);
  const [openDate, setOpenDate] = useState("");
  const [openDateValid, setOpenDateValid] = useState(true);
  const [done, setDone] = useState(false);
  const [lessonNo, setLessonNo] = useState(1);
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();
  const handleSubmit = () => {
    // Reset validation

    // Check for errors
    if (!title) {
      setTitleValid(false);
    }

    if (openDate) {
      setOpenDateValid(true);
    }
    if (!openDate) {
      setOpenDateValid(false);
    }

    if (title && openDate && titleValid && openDateValid) {
      addLessons();
    }
  };

  const addLessons = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("courseId", id);
    bodyFormData.append("lessonNo", lessonNo);
    bodyFormData.append("openDate", openDate);

    try {
      const res = await axios.post(`api/lessons`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setDone(true);
    } catch (err) {
      const {} = err.response.data;
      console.log(err);
    }
  };
  const handleDone = () => {
    setShowAddLesson(false);
    setchangeData((e) => !e);
  };
  return (
    <>
      {done ? (
        <Done onDone={handleDone} />
      ) : (
        <div className="rounded-[4px]  add__course add__course w-full lg:w-[729px] lg:min-h-[343px] h-full  lg:h-fit flex flex-col max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed ">
          <div className="lg:hidden items-center justify-between mb-10 flex">
            <h2 className="text-[28px] font-semibold text-[#1E69CB]">
              Add Lessons
            </h2>
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowAddLesson(false)}
              className="cursor-pointer">
              <path
                d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
                fill="#374151"
              />

              <g mask="url(#mask0_1240_20873)"></g>
            </svg>
          </div>
          <Form
            files={files}
            setFiles={setFiles}
            title={title}
            titleValid={titleValid}
            filesValid={filesValid}
            setTitle={handleTitleChange}
            setFilesValid={setFilesValid}
            setTitleValid={setTitleValid}
            openDate={openDate}
            setOpenDate={setOpenDate}
            openDateValid={openDateValid}
            lessonNo={lessonNo}
            setLessonNo={setLessonNo}
          />
          <div className="">
            <Button
              setShowAddCourse={setShowAddLesson}
              handleSubmit={handleSubmit}
              id={"addLessons"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddLesson;

const Form = ({
  files,
  setFiles,
  titleValid,
  filesValid,
  title,
  setTitle,
  setFilesValid,
  setTitleValid,
  openDate,
  setOpenDate,
  openDateValid,
  setLessonNo,
  lessonNo,
}) => {
  const deleteItem = (index) => {
    // Create a new array without the item at the specified index
    const updatedItems = [...files.slice(0, index), ...files.slice(index + 1)];
    // Update the state with the new array
    setFiles(updatedItems);
  };

  return (
    <div className="bg-[#E5E7EB] lg:bg-[#F5F5F5] px-4 pt-4 pb-6 lg:px-0 lg:py-0 rounded-[10px] ">
      <Input
        title={"Title"}
        placeHolder={"Enter your title"}
        onChange={setTitle}
        valid={titleValid}
        setValid={setTitleValid}
        value={title}
      />
      {/* <Input
        title={"Descriotion"}
        placeHolder={"Enter your content"}
        onChange={setDescription}
        valid={descriptionValid}
        setValid={setDescriptionValid}
        value={description}
        height={"h-[83px]"}
      /> */}
      <LessonNumber
        setLessonNo={setLessonNo}
        lessonNo={lessonNo}
      />
      <DateRange
        edit={true}
        openDate={openDate}
        setOpenDate={setOpenDate}
        openDateValid={openDateValid}
      />

      {/* <UploadImage
        setFiles={setFiles}
        valid={filesValid}
        setValid={setFilesValid}
      />
      {files.length > 0 && (
        <div className="flex flex-col gap-8 mt-5 mb-5">
          {files.map((file, index) => (
            <File file={file} index={index} deleteItem={deleteItem} />
          ))}
        </div>
      )} */}
    </div>
  );
};

const DateRange = ({ edit, openDate, setOpenDate, openDateValid }) => {
  return (
    // <div className="w-full">
    edit ? (
      <DatePickerCom
        id={"openDate"}
        value={openDate}
        onChange={setOpenDate}
        dateValid={openDateValid}
        disblePast={true}
      />
    ) : (
      <>
        <p className="font-semibold">Open Date</p>
        <p className="text-[#6B7280]">{openDate}</p>
      </>
    )
    // </div>
  );
};

const Done = ({ onDone }) => {
  return (
    <div className="w-[538px] h-[134px] bg-white rounded-[10px] shadow-md add__course flex flex-col items-center justify-between py-3">
      <h2 className="text-xl text-center font-semibold border-b border-[#E5E7EB] w-full pb-4 text-[#40A726] pt-2">
        Your Course Lesson Successfully
      </h2>
      <button
        className="w-[498px] h-[40px] text-white font-semibold bg-[#1E69CB] rounded-[4px] "
        onClick={onDone}>
        Done
      </button>
    </div>
  );
};
