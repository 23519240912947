import React from "react";
import { useState } from "react";
import UploadImage from "./UploadImage";
import Input from "./Input";
import File from "./File";
import Button from "./Button";
import { useTitleValidation } from "../../hooks";
import axios from "../../api/axios";
import LessonNumber from "./LessonNumber";
import s3 from "../../utils/aws";
import { useStateContext } from "../../context/CreateContext";

const AddFile = ({ idLessone, setShowAddFile, changeData }) => {
  const [files, setFiles] = useState([]);
  const [filesValid, setFilesValid] = useState(true);
  const [title, titleValid, setTitleValid, handleTitleChange] =
    useTitleValidation();
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lessonNo, setLessonNo] = useState(1);
  const { idUser } = useStateContext();

  function getFileType(e) {
    setFileType(e);
  }

  const handleSubmit = () => {
    if (!title) {
      setTitleValid(false);
    }
    if (files.length <= 0) {
      setFilesValid(false);

      // If all fields are valid, proceed with form submission logic
    }
    if (titleValid && filesValid && title && files.length > 0) {
      // Your form submission logic here
      addFile();
    }
  };

  // const addFile = async () => {
  //   let bodyFormData = new FormData();

  //   if (fileType === "video") {
  //     bodyFormData.append("fileType", "VIDEO");
  //   }
  //   bodyFormData.append("title", title);
  //   bodyFormData.append("fileNo", 1);
  //   files.length > 0 && bodyFormData.append("file", files[0], files[0]?.name);
  //   setIsLoading(true);
  //   try {
  //     const res = await axios.post(
  //       `api/lessons/${idLessone}/files`,
  //       bodyFormData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     setIsLoading(false);

  //     setShowAddFile(false);
  //     changeData((e) => !e);

  //     // setDone(true);
  //   } catch (err) {
  //     const { file, title } = err.response.data;

  //     console.log(err);
  //   }
  // };

  const addFile = async () => {
    let bucketName = process.env.REACT_APP_S3_BUCKET;
    let fileName;
    let bodyFormData = new FormData();
    if (fileType === "video") {
      bodyFormData.append("fileType", "VIDEO");
      bucketName = process.env.REACT_APP_S3_BUCKET + "/videos/lessons";
      fileName = `video${idLessone + idUser + files[0].name}`;
    } else {
      bodyFormData.append("fileType", "FILE");
      bucketName = process.env.REACT_APP_S3_BUCKET + "/files/lessons";
      fileName = `file${idLessone + idUser + files[0].name}`;
    }
    bodyFormData.append("title", title);

    bodyFormData.append("fileNo", lessonNo);
    bodyFormData.append("file", fileName);

    try {
      setIsLoading(true);

      // Upload file to S3 bucket
      const s3Params = {
        Bucket: bucketName,
        Key: fileName,
        Body: files[0],
      };

      await s3.upload(s3Params).promise();

      // After the file is uploaded to S3, send its details to your API
      const res = await axios.post(
        `api/lessons/${idLessone}/files`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setShowAddFile(false);
      changeData((e) => !e);
      setIsLoading(false);
    } catch (err) {
      // const { file, title } = err.response.data;
      console.log(err);
    }
  };

  return (
    <div className="add__course w-full lg:w-[729px] lg:min-h-[343px] h-full rounded-xl  lg:h-fit flex flex-col max-h-screen lg:rounded-xl bg-[#F5F5F5] px-7 py-7 fixed ">
      <div className="lg:hidden items-center justify-between mb-10 flex">
        <h2 className="text-[28px] font-semibold text-[#1E69CB]">Add File</h2>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setShowAddFile(false)}
          className="cursor-pointer">
          <path
            d="M31.2999 28L41.3169 17.983C42.2293 17.0707 42.2293 15.596 41.3169 14.6837C40.4046 13.7713 38.9299 13.7713 38.0176 14.6837L28.0006 24.7007L17.9836 14.6837C17.0713 13.7713 15.5966 13.7713 14.6843 14.6837C13.7719 15.596 13.7719 17.0707 14.6843 17.983L24.7013 28L14.6843 38.017C13.7719 38.9293 13.7719 40.404 14.6843 41.3163C15.1393 41.7713 15.7366 42 16.3339 42C16.9313 42 17.5286 41.7713 17.9836 41.3163L28.0006 31.2993L38.0176 41.3163C38.4726 41.7713 39.0699 42 39.6673 42C40.2646 42 40.8619 41.7713 41.3169 41.3163C42.2293 40.404 42.2293 38.9293 41.3169 38.017L31.2999 28Z"
            fill="#374151"
          />

          <g mask="url(#mask0_1240_20873)"></g>
        </svg>
      </div>
      <Form
        files={files}
        setFiles={setFiles}
        title={title}
        titleValid={titleValid}
        filesValid={filesValid}
        setTitle={handleTitleChange}
        setFilesValid={setFilesValid}
        setTitleValid={setTitleValid}
        getFileType={getFileType}
        lessonNo={lessonNo}
        setLessonNo={setLessonNo}
      />
      <Button
        isLoading={isLoading}
        setShowAddCourse={setShowAddFile}
        handleSubmit={handleSubmit}
        id={"AddFile"}
      />
    </div>
  );
};

export default AddFile;

const Form = ({
  files,
  setFiles,
  titleValid,
  filesValid,
  title,
  setTitle,
  setFilesValid,
  setTitleValid,
  getFileType,
  lessonNo,
  setLessonNo,
}) => {
  const [fileType, setFileType] = useState("");
  function handleFileChange(e) {
    setFileType(e);
  }
  getFileType(fileType);

  const deleteItem = (index) => {
    // Create a new array without the item at the specified index
    const updatedItems = [...files.slice(0, index), ...files.slice(index + 1)];

    // Update the state with the new array
    setFiles(updatedItems);
  };

  return (
    <>
      <Input
        title={"Title"}
        placeHolder={"Enter your title"}
        onChange={setTitle}
        valid={titleValid}
        setValid={setTitleValid}
        value={title}
        padding="px-4 pt-4 pb-6 lg:px-0 lg:py-0"
      />
      <LessonNumber
        setLessonNo={setLessonNo}
        lessonNo={lessonNo}
        file={true}
      />
      <UploadImage
        setFiles={setFiles}
        valid={filesValid}
        setValid={setFilesValid}
        file={files}
        onFileChange={handleFileChange}
        oneFile={true}
      />
      {files.length > 0 && (
        <div className="flex flex-col gap-8 mt-5 mb-5">
          {files.map((file, index) => (
            <File
              file={file}
              index={index}
              deleteItem={deleteItem}
            />
          ))}
        </div>
      )}
    </>
  );
};
